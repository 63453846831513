import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import JSONEditor from "jsoneditor";

import "jsoneditor/dist/jsoneditor.css";
import { BOOLEAN_OBJECT } from "./sharedData";
import formFieldComponents from "../Patient/ApplicationFormEditor/components/form/utils/formFieldComponents";

export const JsonEditor = forwardRef(({ json, onChange }, ref) => {
  const editorRef = useRef(null);
  const editorInstanceRef = useRef(null);
  const fieldTypes = Object.keys(formFieldComponents);
  const booleanOptions = Object.values(BOOLEAN_OBJECT);
  const requiredFields = ["type"];
  const editorModes = Object.freeze(["tree", "code"]);
  const schemaTypes = Object.freeze({
    string: "string",
    number: "number",
    boolean: "boolean",
    object: "object",
    array: "array",
    null: "null"
  });

  useEffect(() => {
    editorInstanceRef.current = new JSONEditor(editorRef.current, {
      mode: editorModes[0],
      modes: editorModes,
      search: true,
      enableSort: false,
      enableTransform: true,
      onEditable: function (node) {
        if (node.path?.includes("fullLine")) {
          return false;
        }
        return true;
      },
      schema: {
        type: schemaTypes.object,
        title: "Form Sections",
        properties: {
          formSections: {
            type: schemaTypes.array,
            items: {
              type: schemaTypes.object,
              properties: {
                rows: {
                  type: schemaTypes.array,
                  items: {
                    type: schemaTypes.object,
                    properties: {
                      fields: {
                        type: schemaTypes.array,
                        items: {
                          type: schemaTypes.object,
                          properties: {
                            type: {
                              type: schemaTypes.string,
                              enum: fieldTypes
                            },
                            required: {
                              type: schemaTypes.string,
                              enum: booleanOptions
                            },
                            fullLine: {
                              type: schemaTypes.boolean,
                              disable_edit_json: true,
                              readonly: true
                            }
                          },
                          required: requiredFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

    editorInstanceRef.current.set(json);
    editorInstanceRef.current.expandAll();

    return () => {
      editorInstanceRef.current.destroy();
    };
  }, [json]);

  useImperativeHandle(ref, () => ({
    handleSave() {
      const updatedJson = editorInstanceRef.current.get();
      onChange(updatedJson);
    }
  }));

  return <div ref={editorRef} style={{ height: "100vh", overflowX: "auto", whiteSpace: "nowrap" }} />;
});
