import React, { useEffect, useRef } from "react";
import {
  Row as AntRow,
  Col as AntCol,
  Select,
  Checkbox,
  Form,
  Divider as AntDivider,
  Input as AntInput,
  InputNumber as AntInputNumber,
  Switch,
  Radio,
  Typography,
  Space
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { WomanOutlined, ManOutlined } from "@ant-design/icons";

import { States } from "../../constant/states";
import { ssnRule, phoneNumberLengthRegEx, validateEmail } from "../../utils/formValidation";
import { formatPhoneNumberWhileTyping } from "../../utils/formaters";
import { selectPatientDetails } from "../../store/selector";

import { font14pxSB } from "../Typography";
import { optionsSearch } from "../../utils/search";
import { FormFields } from "./FormFields";
import { BLACK, GRAY_200, GRAY_700 } from "../../constant/colors";
import { CustomDatePicker } from "../customComponentNewDesign/customDatePicker";
import { ReactComponent as EmailIcon } from "../../assets/svg/emailIcon.svg";
import { DATE_FORMAT } from "../../utils/date";
import InputMask from "react-input-mask";
import { displayedMRN } from "@tailormed/common-client/util/mrn";
import { DemographicConflictFormItem } from "./DemographicConflictFormItem";
import { AlternateContactOptions } from "../../constant/patientCaregiver";
import { ALL_LANGUAGES } from "../../constant/languages";
import { handleRadioGroupKeyDown } from "../../screen/ManagePatientDetails";
import { FEMALE, MALE } from "../../constant/managePatientDetails";
const { Option } = Select;
const { Text } = Typography;

const Divider = styled(AntDivider)`
  margin: 16px 0;
`;

const CustomDivider = styled(AntDivider)`
  margin: 0px 0px 16px 0px;
  background-color: ${GRAY_200};
`;

const CardWrapper = styled.div`
  margin: 16px 0;
`;

const CardTitle = styled.span`
  ${font14pxSB}
  display: block;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const Col = styled(AntCol)`
  &.ant-col-12:nth-child(1) {
    padding-right: 8px;
  }
  &.ant-col-12:nth-child(2) {
    padding-left: 8px;
  }
`;

const FormItemSwitch = styled(Form.Item)`
  margin-bottom: 0;
  flex-direction: row-reverse !important;
  ${font14pxSB}
  align-items: center;
  justify-content: center;
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      ${font14pxSB}
    }
  }
`;

const StyledRadio = styled(Radio.Group)`
  span.ant-radio + * {
    ${font14pxSB};
    color: ${GRAY_700};
  }
`;

const Row = styled(AntRow)``;

const TitleRow = styled(AntRow)`
  margin-top: 16px;
`;

const CardBody = styled.div`
  padding: 24px 24px 0px 24px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
`;

const Input = styled(AntInput)`
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
`;

const InputNumber = styled(AntInputNumber)`
  width: 100%;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
`;

const InputMaskStyle = {
  width: "100%",
  border: "1px solid #d0d5dd",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "4px",
  height: "30px",
  padding: "11px"
};

const RadioButtonStyled = styled(Radio.Button)`
  display: flex;
  flex: 1;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: black;
  span {
    display: flex;
    align-items: center;
  }
`;

const Card = ({ title, children }) => {
  return (
    <CardWrapper>
      <CardTitle>{title}</CardTitle>
      <CardBody>{children}</CardBody>
    </CardWrapper>
  );
};

const prefixSelector = <Text>US</Text>;

const ManagePatientDetailsPersonal = () => {
  const { t } = useTranslation();
  const patientDetails = useSelector(selectPatientDetails);
  const radioRefs = useRef({});

  const form = Form.useFormInstance();
  const preferredLanguage = Form.useWatch(FormFields.preferredLanguage, form);

  const { deceased } = patientDetails || {};

  const handleKeyDown = (e, label) => {
    if (e.key === "Enter") {
      handleEnterKey(label);
    } else if (label !== MALE && label !== AlternateContactOptions.CONTACT_INSTEAD_OF_PATIENT.value) {
      handleRadioGroupKeyDown(e, label, radioRefs);
    }
  };

  const handleEnterKey = (label) => {
    const fieldMapping = {
      [MALE]: FormFields.gender,
      [FEMALE]: FormFields.gender,
      [AlternateContactOptions.CONTACT_INSTEAD_OF_PATIENT.value]: FormFields.alternateContactOption,
      [AlternateContactOptions.OK_TO_SPEAK_TO.value]: FormFields.alternateContactOption
    };

    if (fieldMapping[label]) {
      form.setFieldsValue({ [fieldMapping[label]]: label });
    }
  };

  useEffect(() => {
    if (!preferredLanguage)
      form.setFieldsValue({
        [FormFields.preferredLanguage]: null
      });
  }, [preferredLanguage]);

  return (
    <div>
      <TitleRow id="manage-patient-details-personal">
        <Col span={12}>
          <Text style={{ fontSize: "24px" }}>{t("managePatientDetails.personal")}</Text>
        </Col>
        <Col span={12} style={{ textAlign: "right", justifyContent: "flex-end" }}>
          <Space>
            <FormItemSwitch valuePropName="checked" name={FormFields.deceased}>
              <Switch size="small" defaultChecked={deceased} />
            </FormItemSwitch>
            {t("managePatientDetails.patient_is_deceased")}
          </Space>
        </Col>
      </TitleRow>
      <Divider />
      {/* > Patient Name */}
      <Card title="Patient Name">
        <Row justify="space-between">
          <Col span={12}>
            <DemographicConflictFormItem label="First Name" name={FormFields.firstName}>
              <Input placeholder={t("placeholders.personal.enter_first_name")} />
            </DemographicConflictFormItem>
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem label="Last Name" name={FormFields.lastName}>
              <Input placeholder={t("placeholders.personal.enter_last_name")} />
            </DemographicConflictFormItem>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={12}>
            <DemographicConflictFormItem label="Middle Name" name={FormFields.middleName}>
              <Input placeholder={t("placeholders.personal.enter_middle_name")} />
            </DemographicConflictFormItem>
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem label="Preferred Name" name={FormFields.prefferedName}>
              <Input placeholder={t("placeholders.personal.enter_preferred_name")} />
            </DemographicConflictFormItem>
          </Col>
        </Row>
      </Card>
      {/* > General */}
      <Card title="General">
        <Row id="manage-patient-details-general">
          <Col span={12}>
            <DemographicConflictFormItem label={t("managePatientDetails.date_of_birth")} name={FormFields.dob}>
              <CustomDatePicker
                /* TODO: Remove this once we make the rest of the controls actually match the design */
                size="default"
                defaultPickerValue={patientDetails?.dob ? moment(patientDetails.dob) : moment().add(-50, "Y")}
                format={[DATE_FORMAT]}
                placeholder={t("managePatientDetails.select_date")}
                style={{
                  paddingRight: "8px",
                  width: "100%"
                }}
              />
            </DemographicConflictFormItem>
          </Col>
          {/* GENDER - Sex Assigned at Birth */}
          <Col span={12}>
            <DemographicConflictFormItem label="Sex Assigned at Birth" name={FormFields.gender}>
              <Radio.Group style={{ display: "flex", flex: 1, color: BLACK }} tabIndex={0}>
                <RadioButtonStyled
                  style={{ borderRadius: "4px 0px 0px 4px", justifyContent: "center" }}
                  value={FEMALE}
                  ref={(el) => (radioRefs.current[FEMALE] = el)}
                  onKeyDown={(e) => handleKeyDown(e, FEMALE)}
                >
                  <WomanOutlined style={{ marginRight: "4px" }} />
                  {t("new_patient.gender_female")}
                </RadioButtonStyled>
                <RadioButtonStyled
                  style={{ borderRadius: "0px 4px 4px 0px", justifyContent: "center" }}
                  value={MALE}
                  ref={(el) => (radioRefs.current[MALE] = el)}
                  onKeyDown={(e) => handleKeyDown(e, MALE)}
                >
                  <ManOutlined style={{ marginRight: "4px" }} />
                  {t("new_patient.gender_male")}
                </RadioButtonStyled>
              </Radio.Group>
            </DemographicConflictFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Text style={{ fontSize: "12px" }}>MRN</Text>
            <Input
              id="ManagePatientDetails_mrn"
              style={{ margin: "8px 0px 24px 0px" }}
              value={displayedMRN(patientDetails?.mrn)}
              disabled
            />
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.social_security_number")}
              name={FormFields.socialSecurityNumber}
              rules={[ssnRule(t)]}
            >
              <InputMask
                style={InputMaskStyle}
                mask={"999-99-9999"}
                maskPlaceholder=""
                placeholder={t("managePatientDetails.social_security_number_placeholder")}
                alwaysShowMask={true}
              />
            </DemographicConflictFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <DemographicConflictFormItem valuePropName="checked" name={FormFields.veteran}>
                <Checkbox>Veteran</Checkbox>
              </DemographicConflictFormItem>
              <DemographicConflictFormItem valuePropName="checked" name={FormFields.legalResident}>
                <Checkbox>Legal resident</Checkbox>
              </DemographicConflictFormItem>
              <DemographicConflictFormItem valuePropName="checked" name={FormFields.disabled}>
                <Checkbox>Disabled</Checkbox>
              </DemographicConflictFormItem>
              <DemographicConflictFormItem valuePropName="checked" name={FormFields.usCitizen}>
                <Checkbox>US Citizen</Checkbox>
              </DemographicConflictFormItem>
            </Row>
          </Col>
        </Row>
      </Card>

      {/* > Contact Details */}
      <Card title="Contact Details">
        <Row id="manage-patient-contact-details">
          <Col span={12}>
            <DemographicConflictFormItem label="Street Address" name={FormFields.address}>
              <Input
                label={t("managePatientDetails.address")}
                placeholder={t("placeholders.personal.enter_street_address")}
              />
            </DemographicConflictFormItem>
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem label="City" name={FormFields.city}>
              <Input label={t("managePatientDetails.city")} placeholder={t("placeholders.personal.enter_city")} />
            </DemographicConflictFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DemographicConflictFormItem label="State" name={FormFields.stateCode}>
              <Select filterOption={optionsSearch} placeholder={t("managePatientDetails.state_placeholder")}>
                {States &&
                  Object.values(States).map((state, index) => (
                    <Option key={index} value={state.code}>
                      {state.name}
                    </Option>
                  ))}
              </Select>
            </DemographicConflictFormItem>
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem label={t("managePatientDetails.zip")} name={FormFields.zipCode}>
              <Input placeholder={t("placeholders.personal.zip")} />
            </DemographicConflictFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DemographicConflictFormItem
              label="Home Phone"
              name={FormFields.homeNumber}
              rules={[
                {
                  pattern: phoneNumberLengthRegEx,
                  message: t("managePatientDetails.please_enter_digits")
                }
              ]}
            >
              <InputNumber
                controls={false}
                placeholder={t("managePatientDetails.usPhonePattern")}
                maxLength={14}
                addonBefore={prefixSelector}
                formatter={(value) => formatPhoneNumberWhileTyping(value)}
                parser={(value) => value.replace(/[^0-9]/g, "")}
              />
            </DemographicConflictFormItem>
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem
              label="Mobile Phone"
              name={FormFields.phoneNumber}
              rules={[
                {
                  pattern: phoneNumberLengthRegEx,
                  message: t("managePatientDetails.please_enter_digits")
                }
              ]}
            >
              <InputNumber
                controls={false}
                placeholder={t("managePatientDetails.usPhonePattern")}
                maxLength={14}
                addonBefore={prefixSelector}
                formatter={(value) => formatPhoneNumberWhileTyping(value)}
                parser={(value) => value.replace(/[^0-9]/g, "")}
              />
            </DemographicConflictFormItem>
          </Col>
        </Row>

        <Row>
          {/* < Email */}
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.email")}
              name={FormFields.email}
              rules={[{ validator: validateEmail, message: t("managePatientDetails.email_invalid") }]}
            >
              <Input placeholder={t("managePatientDetails.email_placeholder")} prefix={<EmailIcon />} />
            </DemographicConflictFormItem>
          </Col>
          {/* > Preferred Language */}
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.preferred_language")}
              name={FormFields.preferredLanguage}
            >
              <Select
                id="preferred_language"
                placeholder={t("managePatientDetails.placeholder.preferred_language")}
                dropdownMatchSelectWidth={true}
                showSearch={true}
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {ALL_LANGUAGES.map((p, index) => (
                  <React.Fragment key={p.value}>
                    <Option value={p.value}>{p.label}</Option>
                    {index === 4 && (
                      <Option disabled key="divider" style={{ cursor: "default" }}>
                        --------------------------------------
                      </Option>
                    )}
                  </React.Fragment>
                ))}
              </Select>
            </DemographicConflictFormItem>
          </Col>
        </Row>

        <CustomDivider />

        <Row justify="space-between">
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.alternate_contact_full_name")}
              name={FormFields.fullNameCaregiver}
            >
              <Input placeholder={t("placeholders.personal.enter_alternate_contact_full_name")} />
            </DemographicConflictFormItem>
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.relation_to_patient")}
              name={FormFields.relationToPatientCaregiver}
            >
              <Input placeholder={t("placeholders.personal.relation_to_patient")} />
            </DemographicConflictFormItem>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.alternate_contact_phone")}
              name={FormFields.phoneCaregiver}
              rules={[
                {
                  pattern: phoneNumberLengthRegEx,
                  message: t("managePatientDetails.please_enter_digits")
                }
              ]}
            >
              <InputNumber
                controls={false}
                placeholder={t("managePatientDetails.usPhonePattern")}
                maxLength={14}
                addonBefore={prefixSelector}
                formatter={(value) => formatPhoneNumberWhileTyping(value)}
                parser={(value) => value.replace(/[^0-9]/g, "")}
              />
            </DemographicConflictFormItem>
          </Col>
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.alternate_contact_email")}
              name={FormFields.emailCaregiver}
              rules={[{ validator: validateEmail, message: t("managePatientDetails.email_invalid") }]}
            >
              <Input placeholder={t("managePatientDetails.email_placeholder")} prefix={<EmailIcon />} />
            </DemographicConflictFormItem>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={24}>
            <DemographicConflictFormItem name={FormFields.alternateContactOption}>
              <StyledRadio>
                <Radio
                  value={AlternateContactOptions.OK_TO_SPEAK_TO.value}
                  ref={(el) => (radioRefs.current[AlternateContactOptions.OK_TO_SPEAK_TO.value] = el)}
                  onKeyDown={(e) => handleKeyDown(e, AlternateContactOptions.OK_TO_SPEAK_TO.value)}
                >
                  {AlternateContactOptions.OK_TO_SPEAK_TO.label(t)}
                </Radio>
                <Radio
                  value={AlternateContactOptions.CONTACT_INSTEAD_OF_PATIENT.value}
                  ref={(el) => (radioRefs.current[AlternateContactOptions.CONTACT_INSTEAD_OF_PATIENT.value] = el)}
                  onKeyDown={(e) => handleKeyDown(e, AlternateContactOptions.CONTACT_INSTEAD_OF_PATIENT.value)}
                >
                  {AlternateContactOptions.CONTACT_INSTEAD_OF_PATIENT.label(t)}
                </Radio>
              </StyledRadio>
            </DemographicConflictFormItem>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ManagePatientDetailsPersonal;
