import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectExpressEnrollmentConfiguration } from "../../../../../../store/selector";
import { capitalizeEachFirstLetter } from "../../../../../../utils/string";
import { relationshipToPatientObjects } from "../genentechPapFieldsConfiguration";
import { OTHER } from "../../../constants";
import { getPreferredLanguage } from "../../../components/form/utils/getPreferredLanguage";
import { cleanUnspecifiedValue } from "../../../components/form/utils/cleanUnspecifiedValue";

export const ZERO_REFILLS_OPTION = 0;

const EVRYSDI_DRUG_NAME = "evrysdi";

export const valuesToExclude = ["String", "Number"];
export const prepareDefaultPrescriptionData = (obj, excludedValues, excludedInputs = []) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (excludedValues.includes(value) || excludedInputs.includes(key)) {
      return acc;
    }
    acc[key] = Array.isArray(value) && value.length > 0 ? value[0] : value;
    return acc;
  }, {});
};

const UNINSURED = "uninsured";
const NoInsurance = "No Insurance";
const MOBILE = "mobile";

const relationshipToPatientOptions = relationshipToPatientObjects.map((relationship) => relationship.displayValue);

const isUnInsured = (insurance) => insurance?.isInsured === false || insurance?.coverages?.[0]?.payerName === UNINSURED;

const getInsuranceDetails = (insurance) => {
  if (isUnInsured(insurance)) {
    return { isInsured: false };
  }
  if (insurance.primary) {
    const insuranceDetails = { primary: insurance.primary };
    if (insurance.secondary) {
      insuranceDetails.secondary = insurance.secondary;
    }
    return insuranceDetails;
  }
  const primary = insurance?.coverages?.length ? insurance.coverages[0] : {};
  const secondary = insurance?.coverages?.length > 1 ? insurance.coverages[1] : {};

  const insuranceDetails = {
    primary: {
      ...primary,
      payerName: cleanUnspecifiedValue(primary?.payerName),
      subscriberId: cleanUnspecifiedValue(primary?.memberId) // In Genentech free drug, subscriberId is equal to our memberId - this is not a bug.
    },
    isInsured: true
  };
  if (secondary && Object.keys(secondary).length > 0) {
    insuranceDetails.secondary = {
      ...secondary,
      payerName: cleanUnspecifiedValue(secondary?.payerName),
      subscriberId: cleanUnspecifiedValue(secondary?.memberId) // In Genentech free drug, subscriberId is equal to our memberId - this is not a bug.
    };
  }
  return insuranceDetails;
};

export const getGenentechFreeDrugFormInitialValues = ({ applicationForm }) => {
  const { patientId } = useParams();
  const expressEnrollmentConfiguration = useSelector(selectExpressEnrollmentConfiguration);
  const formData = applicationForm?.formData;
  const { questionnaireOption, prescription, insurance, practice, patient, eligibilityQuestionnaire = {} } = formData;

  const drugConfig = questionnaireOption
    ? expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire.find(
        (questionnaire) => questionnaireOption === questionnaire.drugName
      )
    : expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire.length > 1
    ? null
    : expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire[0];

  const { drugName = "" } = drugConfig || {};

  // in case the number of refills is 0, include it in the prescription data
  const excludedInputs =
    drugConfig?.prescriptionData?.[0]?.numberOfRefills !== ZERO_REFILLS_OPTION
      ? ["numberOfRefills", "expectedSupplyDurationValue"]
      : ["expectedSupplyDurationValue"];

  // Check if prescription data is available, else prepare it if needed
  const preparedPrescriptionData = drugConfig?.prescriptionData?.[0]
    ? prepareDefaultPrescriptionData(drugConfig.prescriptionData[0], valuesToExclude, excludedInputs)
    : {};

  // for initial EE, take prescription data from questionnaire API
  const prescriptionData = prescription || preparedPrescriptionData;

  let facilityId;
  if (practice?.facilityId) {
    facilityId = practice.facilityId;
  } else {
    facilityId = practice?.name ? OTHER : undefined;
  }

  const relationship = patient?.relatedPerson?.relationship;
  if (relationship && !relationshipToPatientOptions.includes(relationship)) {
    patient.relatedPerson.relationship = null;
  }

  eligibilityQuestionnaire["household-income-amount"] =
    eligibilityQuestionnaire?.["household-income-amount"] || patient?.income;
  eligibilityQuestionnaire["household-size"] =
    typeof eligibilityQuestionnaire?.["household-size"] === "number"
      ? eligibilityQuestionnaire["household-size"].toString()
      : eligibilityQuestionnaire?.["household-size"];

  const cleanNulls = (obj) => {
    const cleanedObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null) {
        cleanedObj[key] = obj[key];
      }
    });
    return cleanedObj;
  };

  const eligibilityReason =
    eligibilityQuestionnaire["eligibility-reason"] || (isUnInsured(insurance) ? NoInsurance : null);

  const initialValues = {
    ...formData,
    patient: {
      ...patient,
      id: patientId,
      primaryLanguage: getPreferredLanguage(patient?.primaryLanguage),
      phoneType: patient?.phoneType || MOBILE,
      additionalPhoneType: patient?.additionalPhoneType || MOBILE,
      additionalPhone: patient?.additionalPhone === patient?.phone ? "" : patient?.additionalPhone
    },
    insurance: {
      ...insurance,
      ...getInsuranceDetails(insurance)
    },
    practice: {
      ...practice,
      facilityId: facilityId
    },
    prescription: {
      ...prescriptionData,
      product: capitalizeEachFirstLetter(drugName)
    },
    eligibilityQuestionnaire: {
      ...cleanNulls({ ...eligibilityQuestionnaire, "eligibility-reason": eligibilityReason })
    }
  };

  if (drugName === EVRYSDI_DRUG_NAME && !formData.insurance) {
    // gene-free-drug vendor's requirement - every enrollment for evrysdi drug there can't be insurance
    delete initialValues.insurance;
  }

  return initialValues;
};
