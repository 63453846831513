import React from "react";
import { Tooltip } from "antd";
import { CustomInput } from "./CustomInput";

const CustomInputWithTooltip = ({ value, tooltipId = undefined, ...props }) => {
  return (
    <Tooltip title={value} id={tooltipId ? `tooltip_${tooltipId}` : undefined}>
      <CustomInput value={value} {...props} />
    </Tooltip>
  );
};

export { CustomInputWithTooltip };
