import React from "react";
import { Form, Radio } from "antd";
import useConditionalValidation from "./utils/useConditionalValidation";

export const RadioItem = ({
  name,
  label,
  required = false,
  rules = [],
  optionItems,
  fieldProps = {},
  formItemProps = {},
  groupStyle = {},
  direction = "horizontal",
  shouldValidateFields = false
}) => {
  useConditionalValidation(name, shouldValidateFields);
  const directionStyle = direction === "vertical" ? { display: "flex", flexDirection: "column" } : {};

  return (
    <Form.Item name={name} rules={[{ required }, ...rules]} label={label} {...formItemProps}>
      <Radio.Group style={{ ...directionStyle, ...groupStyle }} {...fieldProps}>
        {optionItems?.map((radioOption, index) => {
          return (
            <Radio key={radioOption.value + "_" + index} value={radioOption.value}>
              {radioOption.displayValue}
            </Radio>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};
