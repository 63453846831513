import React from "react";
import { useTranslation } from "react-i18next";
import { ChatWidget } from "./ChatWidget";
import { ChatWidgetLayoutStyle, ChatContentStyle, FooterTextStyle, ChatFooterStyle } from "./Styles";

const MemoizedChatWidget = React.memo(ChatWidget);

const ChatWidgetLayout = ({ activeConversationId, collapsed, shouldLoadHistory }) => {
  const { t } = useTranslation();
  return (
    <ChatWidgetLayoutStyle collapsed={collapsed}>
      <ChatContentStyle>
        {activeConversationId && (
          <MemoizedChatWidget conversationId={activeConversationId} shouldLoadHistory={shouldLoadHistory} />
        )}
      </ChatContentStyle>
      <ChatFooterStyle>
        <FooterTextStyle>{t("ai_chat_bot.chat_footer")}</FooterTextStyle>
      </ChatFooterStyle>
    </ChatWidgetLayoutStyle>
  );
};
export { ChatWidgetLayout };
