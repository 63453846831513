import React, { useRef, useEffect, useCallback } from "react";
import { compose } from "redux";
import { DeepChat } from "deep-chat-react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../hooks/fetch";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import { DeepChatStyle, DeepChatMessagesStyle, DeepChatTextInputPlaceholderStyle } from "./Styles";
import { registerMessageActionsWebComponent } from "./MessageActions";
import { sendMessageToAIChatBot, getAIChatBotConversationHistory } from "../../api/api";
import { IntroPanel } from "./IntroPanel";

const ChatWidgetComponent = ({ conversationId, shouldLoadHistory }) => {
  const { t } = useTranslation();
  const chatElementRef = useRef();
  const [getConversationHistory, conversationHistoryResult, , conversationHistoryError] = useFetch(
    getAIChatBotConversationHistory
  );

  const getHtmlContent = (msg) => {
    // Encode message content to prevent HTML injection issues
    const encodedContent = encodeURIComponent(msg.text);
    return `
      <div>
        <message-actions message-id="${msg.id}" message-content="${encodedContent}" conversation-id="${conversationId}" message-feedback=${msg.feedback}></message-actions>
      </div>
    `;
  };

  useEffect(() => {
    registerMessageActionsWebComponent(); // Register Web Component on mount
  }, []);

  useEffect(() => {
    if (chatElementRef.current?.resetMessages) {
      chatElementRef.current.resetMessages();
    } else if (chatElementRef.current && shouldLoadHistory) {
      getConversationHistory(conversationId);
    }
  }, [conversationId, shouldLoadHistory]);

  useEffect(() => {
    if (conversationHistoryError) {
      ErrorMessage(t("ai_chat_bot.errors.failed_fetching_conversation_history"));
    }

    if (conversationHistoryResult) {
      chatElementRef.current.loadHistory = () => {
        const messages = conversationHistoryResult.data.messages || [];
        return messages.map((msg) => {
          if (msg.role === "ai") {
            const html = getHtmlContent(msg);
            return {
              role: msg.role,
              text: msg.text,
              html
            };
          }
          return msg;
        });
      };
    }
  }, [conversationHistoryResult, conversationHistoryError]);

  useEffect(() => {
    if (chatElementRef.current) {
      chatElementRef.current.htmlClassUtilities = {
        ["custom-button"]: {
          events: {
            click: (event) => {
              const text = event.target.innerText;
              chatElementRef.current.submitUserMessage(text);
            }
          }
        }
      };
    }
  }, [chatElementRef.current]);
  const sendMessage = useCallback(
    async (body, signals) => {
      try {
        const lastMessage = body.messages[0];
        const response = await sendMessageToAIChatBot(conversationId, lastMessage);
        const { data } = response;
        const html = getHtmlContent(data);

        const responseWithFeedback = {
          role: data.role,
          text: data.text,
          html: html
        };

        // Send message to Deep Chat
        signals.onResponse(responseWithFeedback);
      } catch (err) {
        signals.onResponse({ error: "Failed to fetch response from server." });
      }
    },
    [conversationId]
  );

  return (
    <>
      <DeepChat
        style={DeepChatStyle}
        ref={chatElementRef}
        key={conversationId}
        initialMessages={[]}
        connect={{ handler: sendMessage }}
        requestBodyLimits={{
          maxMessages: 1
        }}
        messageStyles={DeepChatMessagesStyle}
        textInput={{
          placeholder: {
            text: t("ai_chat_bot.message_placeholder"),
            style: DeepChatTextInputPlaceholderStyle
          },
          characterLimit: 1000
        }}
      >
        <IntroPanel />
      </DeepChat>
    </>
  );
};

const ChatWidget = compose()(ChatWidgetComponent);

export { ChatWidget };
