import {
  CheckSquareOutlined,
  CloseOutlined,
  DeleteFilled,
  EditFilled,
  LinkOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { Badge, Button, Checkbox, Col, DatePicker, List, Row, Select, Space, Typography } from "antd";
import moment from "moment-timezone";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  createPatientsTaskAPI,
  deletePatientsTaskAPI,
  editPatientsTaskAPI,
  getFinancialNavigationPotentialSavingPhis,
  getPatientsTasksAPI
} from "../../api/api";
import { ReactComponent as TasksFilled } from "../../assets/svg/tasks.svg";
import { GRAY_700, PRIMARY_600, WHITE } from "../../constant/colors";
import { ALL, DONE, THIS_MONTH, THIS_WEEK, UNASSIGNED } from "../../constant/filters";
import { useFetch } from "../../hooks/fetch";
import { selectAssignees, selectFormPopups, selectPatientDetails, selectUser } from "../../store/selector";
import { DATE_FORMAT, DATE_FORMAT_1, dateFormatter, setDateRange } from "../../utils/date";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import { CircleIconButton } from "../UI/CircleIconButton";
import { TasksEdit } from "./TasksEdit";

import { Tooltip } from "../UI/Tooltip";

import { formPopupsContentKeys } from "../../constant/formPopupsContentKeys";
import { fontWeights, sizes } from "../../constant/styles";
import { useGetSetting } from "../../hooks/getSetting";
import ACTIONS from "../../store/action";
import { formatAssignee } from "../../utils/formaters";
import { getPopupPlacement } from "../../utils/patient";
import { optionsSearch } from "../../utils/search";
import { Popover } from "../UI/Antd/Popover/Popover";
import ExportTasksData from "./ExportTasksData";
import "./Tasks.css";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const STATUS_NOT_DONE = "";
const taskPopoverSize = {
  Height: {
    patientView: 243,
    patientTaskEditView: 290
  }
};

const PATIENTS_ON_PAGE = 10;
const STATUS_CHECKED = "checked";
const FILTERS_TASKS = "filterTasks";

const MenuButton = styled(CircleIconButton)`
  padding-top: 4px;
`;

const StyledSelect = styled(Select)`
  .anticon > svg {
    color: rgb(82, 80, 85);
  }

  .ant-select-selector {
    padding: ${(props) => !props.withoutPadding && "0px 20px 0px 0px !important"};
  }
`;

export const getPopoverHeight = (isPatientView, isEditMode = false) =>
  isPatientView ? (isEditMode ? taskPopoverSize.Height.patientTaskEditView : taskPopoverSize.Height.patientView) : null;

const PotentialSavingLink = ({ patientId, journeyId, linkedPotentialSaving, potentialSavingName }) => {
  if (!patientId || !journeyId || !linkedPotentialSaving) return null;
  return (
    <Link to={`/patient/${patientId}/journey/${journeyId}/optimizations/${linkedPotentialSaving}`}>
      <LinkOutlined style={{ paddingRight: 8 }} />
      {potentialSavingName}
    </Link>
  );
};

const TaskPotentialSavingLink = ({
  taskJourneyPatientId,
  taskJourneyId,
  potentialSavingName,
  linkedPotentialSaving
}) => {
  const { t } = useTranslation();
  const { patientId, journeyId } = useParams();
  const psName = potentialSavingName || t("patientsTasks.view_program");
  return (
    <Switch>
      <Route
        path={"/patient/:patientId/journey"}
        component={() => (
          <PotentialSavingLink
            patientId={patientId}
            journeyId={journeyId}
            potentialSavingName={psName}
            linkedPotentialSaving={linkedPotentialSaving}
          />
        )}
      />
      <Route
        path={"*"}
        render={() => (
          <PotentialSavingLink
            potentialSavingName={psName}
            patientId={taskJourneyPatientId}
            journeyId={taskJourneyId}
            linkedPotentialSaving={linkedPotentialSaving}
          />
        )}
      />
    </Switch>
  );
};

export const Tasks = ({ isPatientView, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [prefix] = useGetSetting(["id_prefix"]);

  const saveFiltersToLocalStorage = (filters) => {
    localStorage.setItem(FILTERS_TASKS, JSON.stringify(filters));
  };

  const getFiltersFromLocalStorage = () => {
    const filters = localStorage.getItem(FILTERS_TASKS);
    return filters ? JSON.parse(filters) : {};
  };

  const filters = getFiltersFromLocalStorage();
  const { start, end } = filters?.dueDate || {};

  const user = useSelector(selectUser);
  const assignees = useSelector(selectAssignees);
  const patientDetails = useSelector(selectPatientDetails);
  const formPopups = useSelector(selectFormPopups);

  const [page, setPage] = useState(1);
  const [potentialSavingMap, setPotentialSavingMap] = useState({});
  const [tasks, setTasks] = useState([]);
  const [total, setTotal] = useState("");
  const [selectedAssignees, setSelectedAssignees] = useState(filters.assignees);
  const [selectedStatus, setSelectedStatus] = useState(filters.status || STATUS_NOT_DONE);
  const [selectedPeriod, setSelectedPeriod] = useState(filters.period || ALL);
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editTaskData, setEditTaskData] = useState(null);
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
  const [isSelectPeriodOpen, setIsSelectPeriodOpen] = useState(false);
  const [placement, setPlacement] = useState("bottomLeft");

  const [editTask, editPatientsTaskResult, editPatientsTaskIsFetching, editPatientsTaskError] =
    useFetch(editPatientsTaskAPI);
  const [getTasksAPI, getTasksResult, getTasksIsFetching, getTasksError] = useFetch(getPatientsTasksAPI);
  const [deleteTask, deleteTaskResult, deleteTaskIsFetching, deleteTaskError] = useFetch(deletePatientsTaskAPI);
  const [createTask, createTaskResult, createTaskIsFetching, createTaskError] = useFetch(createPatientsTaskAPI);
  const [fetchPotentialSaving, potentialSavingResult, potentialSavingIsFetching, potentialSavingError] = useFetch(
    getFinancialNavigationPotentialSavingPhis
  );

  const { patientId } = useParams();

  const formatDate = (date, format) => (date ? moment(date, DATE_FORMAT).format(format) : "");

  const formattedStart = formatDate(start, DATE_FORMAT_1);
  const formattedEnd = formatDate(end, DATE_FORMAT_1);
  const periodValue = end ? `${formattedStart} - ${formattedEnd}` : selectedPeriod;

  const setFormPopupsAct = (visible, contentKey) => dispatch(ACTIONS.setFormPopups(visible, contentKey));
  const { visible, contentKey } = formPopups;
  const formPopupsContentKey = patientId ? formPopupsContentKeys.PATIENT_TASKS : formPopupsContentKeys.TASKS;
  const isTaskPopoverOpen = visible && contentKey === formPopupsContentKey ? true : false;

  useEffect(() => {
    const updatedFilters = getFiltersFromLocalStorage();
    setSelectedAssignees(updatedFilters.assignees);
    setSelectedStatus(updatedFilters.status || STATUS_NOT_DONE);
    setSelectedPeriod(updatedFilters.period || ALL);
    const { start, end } = updatedFilters?.dueDate || {};
    getTasks(start, end);
  }, [visible]);

  useEffect(() => {
    if (getTasksResult?.data?.todos) {
      setTasks(getTasksResult.data.todos);
      setTotal(getTasksResult.data.total);
    }
  }, [getTasksResult]);

  useEffect(() => {
    if (assignees.length === 0 || !user) return;

    const currentAssigneeUser = assignees[user?.id]?.id;
    const initialAssignee = filters.assignees || currentAssigneeUser || ALL;

    setSelectedAssignees(initialAssignee);
  }, [assignees, user]);

  useEffect(() => {
    const potentialSavings = potentialSavingResult?.data?.["financial-navigation-potential-saving-phi"];
    if (potentialSavings) {
      setPotentialSavingMap({
        ...potentialSavings.reduce((acc, { id, ...result }) => {
          return {
            ...acc,
            [id]: result
          };
        }, {}),
        ...potentialSavingMap
      });
    }
  }, [potentialSavingResult]);

  useEffect(() => {
    saveFiltersToLocalStorage({
      ...filters,
      assignees: selectedAssignees,
      status: selectedStatus,
      period: selectedPeriod
    });
    setIsEditMode(false);

    const { start, end } = filters?.dueDate || {};
    getTasks(start, end);
  }, [editPatientsTaskResult, createTaskResult, selectedAssignees, selectedStatus, selectedPeriod, deleteTaskResult]);

  useEffect(() => {
    if (!visible || patientId || contentKey !== formPopupsContentKeys.TASKS) return;

    const handleClickOutside = (event) => {
      const popoverElement = document.querySelector("#tasks_popover");

      if (popoverElement && !popoverElement.contains(event.target)) {
        setFormPopupsAct(false, formPopupsContentKeys.TASKS);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [visible, contentKey, patientId]);

  useEffect(async () => {
    const fetchLinkedPotentialSaving = tasks.filter((p) => p.linkedPotentialSaving).map((p) => p.linkedPotentialSaving);
    const fetchingList = [];

    if (fetchLinkedPotentialSaving.length) {
      fetchingList.push(fetchPotentialSaving(fetchLinkedPotentialSaving));
    }
    await Promise.all(fetchingList);

    !patientId && dispatch(ACTIONS.setBadgeCounts("tasks", getTasksResult?.data.total));
  }, [page, tasks]);

  const hasError = editPatientsTaskError || getTasksError || deleteTaskError || createTaskError || potentialSavingError;

  if (hasError) {
    ErrorMessage(hasError);
  }

  const isFetching =
    editPatientsTaskIsFetching ||
    getTasksIsFetching ||
    deleteTaskIsFetching ||
    createTaskIsFetching ||
    potentialSavingIsFetching;

  const getTasks = async (start = null, end = null, nextPage) => {
    const filterData = {
      assignee: selectedAssignees === UNASSIGNED ? "" : selectedAssignees,
      status: selectedStatus,
      dueDate: start ? { start, end } : selectedPeriod
    };
    const addPatientData = patientId ? false : true;
    await getTasksAPI(filterData, addPatientData, patientId, PATIENTS_ON_PAGE, nextPage || page);
  };

  const selectPeriodHandler = (value) => {
    if (value === filters.period) getTasks();

    setSelectedPeriod(value);
    setIsSelectPeriodOpen(false);
    saveFiltersToLocalStorage({ ...filters, dueDate: { start: "", end: "" } });
  };

  const setRangePicker = (values) => {
    setIsRangePickerOpen(false);
    const formattedDates = setDateRange(moment(values[0]), moment(values[1]));
    const dateFrom = formattedDates[0];
    const dateTo = formattedDates[1];
    getTasks(dateFrom, dateTo);

    saveFiltersToLocalStorage({ ...filters, dueDate: { start: dateFrom, end: dateTo } });
    setIsSelectPeriodOpen(false);
  };

  const changeStatusHandler = (task) => {
    if (task.status === STATUS_CHECKED) {
      task.status = null;
    } else if (task.status === null) {
      task.status = STATUS_CHECKED;
    }
    editTask(task);
  };

  const editingTaskHandler = useCallback((task) => {
    setIsEditMode(true);
    setEditTaskData(task);
  }, []);

  const onLoadMore = () => {
    setPage(page + 1);
    const nextPage = page + 1;
    const { start, end } = filters?.dueDate || {};
    getTasks(start, end, nextPage);
  };

  const getAssigneeLabel = () => {
    switch (selectedAssignees) {
      case ALL:
        return t("patientsTasks.all_assignees");
      case UNASSIGNED:
        return t("patientsTasks.unassigned");
      default:
        return selectedAssignees;
    }
  };

  const loadMore = !isFetching ? (
    <>
      {page < Math.ceil(getTasksResult?.data.total / PATIENTS_ON_PAGE) && (
        <div style={{ textAlign: "center", marginTop: 12, height: 32, lineHeight: "32px" }}>
          <Button onClick={onLoadMore}>{t("patientsTasks.load_more")}</Button>
        </div>
      )}
    </>
  ) : null;

  const popoverTitle = (
    <div>
      <Text id={`${patientId ? "patient_name_" : ""}tasks_popover_title`}>
        {patientId ? `${t("patientsTasks.tasks_for")} ${patientDetails?.name || ""}` : t("patientsTasks.title")}
      </Text>
      <CloseOutlined id="close_tasks_popover" onClick={() => setFormPopupsAct(false, formPopupsContentKey)} />
    </div>
  );

  const renderItem = (task) => {
    const { text, dueDate, patient, mrn } = task;
    const taskAssignee = Object.values(assignees).find((assignee) => assignee.id === task.assignee);
    const patientFullName = task.patientFullName || "Patient";

    return (
      <List.Item id={`task-${task.id}`}>
        <List.Item.Meta
          avatar={
            <Checkbox
              style={{ paddingTop: task.journey ? 4 : 0 }}
              checked={task.status === STATUS_CHECKED}
              onClick={() => changeStatusHandler(task)}
            />
          }
          title={
            <>
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                {task.journey && (
                  <Link
                    style={{ padding: 0 }}
                    type="link"
                    id="patient_name"
                    to={`/patient/${patient}/journey/${task.journey.id}`}
                  >
                    {patientFullName}
                  </Link>
                )}
                {!isPatientView && (patient || mrn) && (
                  <Text id="patient_id_and_mrn">
                    {"("}
                    {prefix ? prefix.toUpperCase() : "TM"}
                    {`-${patient}`}
                    {mrn && t("patientsTasks.mrn", { mrn })}
                    {")"}
                  </Text>
                )}
              </div>
              <div>
                <Text id="task-description">{text}</Text>
              </div>
              <TaskPotentialSavingLink
                taskJourneyPatientId={task?.journey?.patient}
                taskJourneyId={task?.journey?.id}
                linkedPotentialSaving={task.linkedPotentialSaving}
                potentialSavingName={potentialSavingMap[task.linkedPotentialSaving]?.name}
              />
            </>
          }
          description={
            <Row>
              {dueDate && (
                <Col span={5}>
                  <Text style={{ color: moment().isBefore(moment(dueDate)) ? "black" : "red" }}>
                    {dateFormatter(dueDate)}
                  </Text>
                </Col>
              )}
              <Col span={dueDate ? 13 : 18} id="task-assignee">
                {taskAssignee && `${formatAssignee(taskAssignee)}`}
              </Col>
              <Col span={6}>
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                  <EditFilled onClick={() => editingTaskHandler(task)} />
                  <DeleteFilled onClick={() => deleteTask(task)} />
                </Space>
              </Col>
            </Row>
          }
        />
      </List.Item>
    );
  };

  const tasksList = (
    <Space direction={"vertical"}>
      <Space style={{ display: "flex", justifyContent: "space-between", gap: 0 }}>
        <Space size={0}>
          <div id="tasks_assignee_filter">
            <StyledSelect
              withoutPadding={true}
              value={getAssigneeLabel()}
              dropdownMatchSelectWidth={false}
              onSelect={setSelectedAssignees}
              style={{ fontWeight: 700, marginLeft: 5 }}
              bordered={false}
              showSearch={true}
              open={isAssigneeOpen}
              onDropdownVisibleChange={(visible) => setIsAssigneeOpen(visible)}
              filterOption={optionsSearch}
              dropdownRender={(menu) => (
                <>
                  <div style={{ display: "flex", flexWrap: "nowrap", padding: 8, gap: 6 }}>
                    <Button
                      type={selectedAssignees === ALL ? "primary" : "default"}
                      ghost={selectedAssignees === ALL ? true : false}
                      onClick={() => (setSelectedAssignees(ALL), setIsAssigneeOpen(false))}
                    >
                      {t("patientsTasks.all_assignees")}
                    </Button>
                    <Button
                      type={selectedAssignees === UNASSIGNED ? "primary" : "default"}
                      ghost={selectedAssignees === UNASSIGNED ? true : false}
                      onClick={() => {
                        setSelectedAssignees(UNASSIGNED);
                        setIsAssigneeOpen(false);
                      }}
                    >
                      {t("patientsTasks.unassigned")}
                    </Button>
                  </div>
                  {menu}
                </>
              )}
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {Object.values(assignees).map((assignee) => (
                <Option key={assignee.id} value={assignee.id}>
                  {formatAssignee(assignee)}
                </Option>
              ))}
            </StyledSelect>
          </div>

          <div id="tasks_status_filter">
            <StyledSelect
              value={selectedStatus}
              dropdownMatchSelectWidth={false}
              onSelect={setSelectedStatus}
              style={{ fontWeight: 700 }}
              bordered={false}
            >
              <Option value={ALL}>{t("patientsTasks.show_all")}</Option>
              <Option value={STATUS_NOT_DONE}>{t("patientsTasks.not_done")}</Option>
              <Option value={DONE}>{t("patientsTasks.done")}</Option>
            </StyledSelect>
          </div>

          <div id="tasks_period_filter">
            <StyledSelect
              value={periodValue}
              dropdownMatchSelectWidth={false}
              onSelect={selectPeriodHandler}
              style={{ fontWeight: 700 }}
              bordered={false}
              open={isSelectPeriodOpen}
              onClick={() => {
                if (!isSelectPeriodOpen) {
                  setIsSelectPeriodOpen(true);
                }
              }}
              onBlur={() => {
                if (!isRangePickerOpen) {
                  setIsSelectPeriodOpen(false);
                }
              }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <RangePicker
                    open={isRangePickerOpen}
                    value={[start && moment(start, DATE_FORMAT), end && moment(end, DATE_FORMAT)]}
                    bordered={false}
                    suffixIcon={null}
                    clearIcon={null}
                    separator={null}
                    allowEmpty={[false, false]}
                    onVisibleChange={() => setIsRangePickerOpen(false)}
                    onChange={setRangePicker}
                    onOpenChange={(open) => setIsRangePickerOpen(open)}
                  />
                </div>
              )}
            >
              <Option value={ALL}>{t("all_times")}</Option>
              <Option value={THIS_WEEK}>{t("this_week")}</Option>
              <Option value={THIS_MONTH}>{t("this_month")}</Option>
            </StyledSelect>
          </div>
        </Space>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 16px 4px 0px"
          }}
        >
          {!isPatientView && <ExportTasksData filterData={filters} setFormPopupsAct={setFormPopupsAct} />}

          <Tooltip
            title={
              <Text style={{ color: GRAY_700, fontSize: sizes.small, fontWeight: fontWeights.semibold }}>
                {t("patientsTasks.add_task")}
              </Text>
            }
            color={WHITE}
          >
            <Button
              id="add_task_button"
              style={{ padding: 0 }}
              onClick={() => {
                setIsEditMode(true);
                setEditTaskData(null);
              }}
              type="text"
            >
              <PlusCircleOutlined style={{ color: PRIMARY_600 }} />
            </Button>
          </Tooltip>
        </div>
      </Space>
      <List
        style={{
          padding: "0px 16px",
          width: "100%",
          height: getPopoverHeight(isPatientView),
          overflowY: "auto"
        }}
        loadMore={loadMore}
        dataSource={tasks}
        renderItem={renderItem}
        loading={isFetching}
      />
    </Space>
  );

  const handleVisibleChange = (visible) => {
    !visible && setIsSelectPeriodOpen(false);
    if (!isPatientView) {
      visible && setFormPopupsAct(visible, formPopupsContentKey);
      return;
    }

    visible && getPopupPlacement(setPlacement, "patient_tasks");

    setFormPopupsAct(visible, formPopupsContentKey);
  };

  return (
    <Popover
      trigger="click"
      id={isPatientView ? "patient_tasks_popover" : "tasks_popover"}
      placement={placement}
      style={{ padding: 0 }}
      title={popoverTitle}
      open={isTaskPopoverOpen}
      onVisibleChange={(visible) => handleVisibleChange(visible)}
      content={
        <div style={{ display: "flex", flexDirection: "column" }} onClick={(e) => e.stopPropagation()}>
          {!isEditMode && tasksList}
          {isEditMode && (
            <TasksEdit
              isFetching={isFetching}
              editTaskData={editTaskData}
              setIsEditMode={setIsEditMode}
              editTask={editTask}
              createTask={createTask}
              isPatientView={isPatientView}
            />
          )}
        </div>
      }
      {...props}
    >
      {!patientId ? (
        <Badge offset={[-5, 5]} size="small" overflowCount={999} count={total}>
          <Tooltip title={t("patientsTasks.tasks")} placement="bottom">
            <MenuButton
              id="main_view_tasks_button"
              icon={<TasksFilled />}
              onClick={(e) => {
                e.stopPropagation();
                setFormPopupsAct(!isTaskPopoverOpen, formPopupsContentKey);
              }}
            />
          </Tooltip>
        </Badge>
      ) : (
        <div
          id="patient_tasks"
          style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            setFormPopupsAct(!visible, formPopupsContentKey);
          }}
        >
          <CheckSquareOutlined style={{ fontSize: 14, paddingRight: 3 }} />
          <Text style={{ fontSize: 14 }}>{`${t("patientRSActions.tasks")}${total ? ` (${total})` : ""}`}</Text>
        </div>
      )}
    </Popover>
  );
};

export default memo(Tasks);
