import _ from "lodash";

import { SidebarIcon } from "../components";

// Helper function to create sidebar item
const createSidebarItem = (title, sectionName, validationFieldsBySidebarItem) => ({
  title,
  href: `#enrollment-form-${sectionName}`,
  key: _.camelCase(sectionName),
  icon: SidebarIcon,
  validationFields: validationFieldsBySidebarItem?.[sectionName] || [sectionName]
});

// Generates sidebar config from array of strings (old structure)
const generateSidebarFromItems = (t, confRootPath, itemNamesArr, validationFieldsBySidebarItem) =>
  itemNamesArr.map((itemName) =>
    createSidebarItem(t(`${confRootPath}.${itemName}.sidebar`), itemName, validationFieldsBySidebarItem)
  );

// Generates sidebar config from array of objects (new JSON base structure)
const generateSidebarFromJson = (formSections, validationFieldsBySidebarItem) => {
  return formSections.map(({ sectionSidebar, sectionName }) =>
    createSidebarItem(sectionSidebar, sectionName, validationFieldsBySidebarItem)
  );
};

export const SBItemsHelper = {
  generateSidebarConfig(
    t,
    confRootPath,
    { itemNamesArr = null, formSections = null, hasAdminOption = false, validationFieldsBySidebarItem = {} }
  ) {
    let sidebarItems = [];

    if (Array.isArray(itemNamesArr)) {
      sidebarItems = generateSidebarFromItems(t, confRootPath, itemNamesArr, validationFieldsBySidebarItem);
    } else if (Array.isArray(formSections)) {
      sidebarItems = [...sidebarItems, ...generateSidebarFromJson(formSections, validationFieldsBySidebarItem)];
    }

    // Add admin option if required
    return hasAdminOption
      ? [
          createSidebarItem(
            t(`${confRootPath}.support_options.sidebar`),
            "support-options",
            validationFieldsBySidebarItem
          ),
          ...sidebarItems
        ]
      : sidebarItems;
  }
};

export default SBItemsHelper;
