import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { Divider, Layout, Table, Typography, Card, Tag, Space, Spin } from "antd";
import { createStructuredSelector } from "reselect";
import { withTranslation } from "react-i18next";

import ACTIONS from "../../store/action";
import { TailormedLogoIcon } from "../../assets/svg/TailormedLogoIcon";
import {
  selectPatientJourney,
  selectPotentialSavings,
  selectPatientDetails,
  selectIsFetching
} from "../../store/selector";
import { capitalizeFirstLetter, numberWithCommas } from "../../utils/string";
import { ApiNames } from "../../api/api";
import PatientReportBenefits from "./PatientReportBenefits";
import PatientReportEligibility from "./PatientReportEligibility";
import PatientReportContact from "./PatientReportContact";
import { REQUIRED_COVERAGE_TYPES } from "../../constant/patient";
import { dateFormatter } from "../../utils/date";
import "./Patient.css";
const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;

const columns = (t) => [
  {
    title: t("patientPrint.treatment"),
    dataIndex: "eventTitle",
    key: "eventTitle"
  },
  {
    title: t("patientPrint.oop_estimation"),
    dataIndex: "eventOOP",
    key: "eventOOP",
    render: (text) => {
      return <Text type="success">${text}</Text>;
    }
  },
  {
    title: t("patientPrint.sick_days"),
    dataIndex: "sickDays",
    key: "sickDays"
  },
  {
    title: t("patientPrint.caregiver_days"),
    dataIndex: "careGiverDays",
    key: "careGiverDays"
  }
];

const PatientReportComponent = ({
  getPatientAct,
  patientDetails,
  patientJourney,
  t,
  potentialSavings,
  isFetchingNetworkActions
}) => {
  const [loading, setLoading] = useState(true);
  const { patientId, journeyId } = useParams();

  useEffect(() => {
    if (!patientDetails || !patientDetails.id || patientId != patientDetails.id) {
      getPatientAct(patientId, journeyId);
    }
  }, [patientId, journeyId]);

  useEffect(() => {
    if (patientDetails && patientJourney && potentialSavings.length) {
      setLoading(false);
    }
  }, [patientDetails, patientJourney, potentialSavings]);

  useEffect(() => {
    if (!loading && !isFetchingNetworkActions) {
      window.print();
    }
  }, [loading]);

  const oopData = patientJourney?.events?.map((event, index) => {
    return {
      key: index,
      eventTitle: event.eventTitle,
      eventOOP: event.eventOOP,
      sickDays: event.sickDays,
      careGiverDays: event.careGiverDays
    };
  });

  const assistanceProgramsData = potentialSavings?.map((program) => {
    const requiredCoverageTypes = REQUIRED_COVERAGE_TYPES(program);

    return { requiredCoverageTypes, program };
  });

  return (
    <div>
      {loading && isFetchingNetworkActions && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
            gap: 8
          }}
        >
          <Spin size="large" />
          <Text>Loading Patient Report</Text>
        </div>
      )}
      <Layout style={{ display: loading ? "none" : "block", paddingTop: 32 }}>
        <Header style={{ backgroundColor: "white", height: "auto" }}>
          <TailormedLogoIcon />
          <Title level={4}>{t("patientPrint.financial_navigation_report")}</Title>
          <Title level={5}>{dateFormatter(moment())}</Title>
        </Header>
        <Content style={{ padding: "10px 50px" }}>
          <Divider />
          <Text>
            {t("patientPrint.welcomeMessage", {
              patientName: capitalizeFirstLetter(patientDetails?.name) || ""
            })}
          </Text>
          <Divider />
          <Title level={5}>{t("patientPrint.diagnosis")}</Title>
          <Space direction="vertical">
            {patientDetails?.primaryDiagnosesForDisplay?.map((diagnosis, index) => (
              <Text key={index}>{diagnosis}</Text>
            ))}
          </Space>
          <Divider className="page-break" />
          <Title level={5} style={{ marginTop: 20 }}>
            {t("patientPrint.out_of_pocket_estimation")}
          </Title>
          <Table columns={columns(t)} dataSource={oopData} bordered={true} pagination={false} />
          <Divider className="page-break" />
          {assistanceProgramsData && assistanceProgramsData.length > 0 && (
            <Title level={5} style={{ marginTop: 20 }}>
              {t("patientPrint.recommended_rograms")}
            </Title>
          )}
          {assistanceProgramsData.map(({ program, requiredCoverageTypes }, index) => (
            <>
              <Divider className={index !== 0 ? "page-break" : ""} />
              <Card
                key={index}
                className="assistance-card"
                title={
                  <Meta title={program.name} description={program.APProvider + " | " + program.typeForClassicTier} />
                }
                extra={
                  <>
                    <Tag>{capitalizeFirstLetter(program.status ? program.status : program.displayStatus)}</Tag>
                    <Text type="success">
                      {program && program.approvedAmount
                        ? `$${numberWithCommas(program.approvedAmount)}`
                        : program.grantTotal
                        ? `$${numberWithCommas(program.grantTotal)}`
                        : "----"}
                    </Text>
                  </>
                }
              >
                <Text className="section-title">
                  <b>{t("patientPotentialSavings.benefits")}</b>
                </Text>
                <div className="section-body">
                  <PatientReportBenefits assistanceProgram={program} />
                </div>
                <Text className="section-title">
                  <b>{t("patientPotentialSavings.eligibility")}</b>
                </Text>
                <div className="section-body">
                  <PatientReportEligibility assistanceProgram={program} requiredCoverageTypes={requiredCoverageTypes} />
                </div>
                <Text className="section-title">
                  <b>{t("patientPotentialSavings.contact_details")}</b>
                </Text>
                <div className="section-body">
                  <PatientReportContact assistanceProgram={program} />
                </div>
              </Card>
            </>
          ))}
        </Content>
      </Layout>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPatientAct: (patientId, journeyId) => dispatch(ACTIONS.getPatientAction(patientId, journeyId))
});

const mapStateToProps = createStructuredSelector({
  patientJourney: selectPatientJourney,
  potentialSavings: selectPotentialSavings,
  patientDetails: selectPatientDetails,
  isFetchingNetworkActions: selectIsFetching([ApiNames.PatientDetails, ApiNames.PatientDetailsNonPhi])
});

const PatientReport = compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PatientReportComponent);
export { PatientReport };
