import { Languages } from "../../../../../../constant/languages";

const getPreferredLanguage = (language) => {
  if (!language) return null;

  const foundLanguage = Object.values(Languages).find(
    (langObj) => langObj.name.toLowerCase() === language.toLowerCase()
  );
  return foundLanguage?.code || language;
};

export { getPreferredLanguage };
