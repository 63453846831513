// EE TODO BMS: uncomment the following line when first use this function and remove this comment
// import { lazy } from "react";

/**
 * Custom fields registry
 * add to this object the custom fields you want to use in the application form editor
 * to do so add line like this: FieldWithSubfields: lazy(() => import("./customFields/fieldWithSubfields"))
 *                               ^ the key is the name of the custom field. ^ the value is a lazy loaded import of the custom field
 */
const customFieldsRegistry = Object.freeze({});

export default customFieldsRegistry;
