import React from "react";
import { Select } from "antd";

import { SingleSelect } from "../../../../customComponent/CustomSelect";
import { Form } from "antd";
import useConditionalValidation from "./utils/useConditionalValidation";
const { Option } = Select;

export const SelectItem = ({
  name,
  label,
  required = false,
  rules = [],
  optionItems,
  placeholder,
  style,
  shouldValidateFields = false,
  fieldProps = {},
  ...props
}) => {
  useConditionalValidation(name, shouldValidateFields);

  return (
    <Form.Item name={name} label={label} rules={[{ required }, ...rules]} style={style}>
      <SingleSelect placeholder={placeholder} {...fieldProps} {...props}>
        {optionItems?.map((optionItem, index) => {
          return (
            <Option key={optionItem.value + "_" + index} value={optionItem.value}>
              {optionItem.displayValue}
            </Option>
          );
        })}
      </SingleSelect>
    </Form.Item>
  );
};
