import { Table } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import { ApiNames } from "../../api/api";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TabsMap } from "../../screen/patient/Patient";
import ACTION from "../../store/action";
import { selectHighPrioritiesData, selectIsFetching, selectTableColumns } from "../../store/selector";
import { dateFormatter } from "../../utils/date";
import { fetchTableData } from "../../utils/fetchTableData";
import { ColumnSelector } from "../ColumnSelector";
import { DeceasedLable } from "../DeceasedLable";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import LinkableCell from "../UI/LinkableCell";
import {
  AssigneeColumn,
  EditColumn,
  PatientDOBColumn,
  PatientNameColumn,
  PatientPhoneNumberColumn,
  PatientSSNColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import EmptyTableMessage from "./EmptyTableMessage";
import PatientPageCount from "./PatientPageCount";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
`;

const patientPath = ({ patientId, journeyId }) => TabsMap.optimizations.path(patientId, journeyId);

const renderWithLink = (patientRec, renderFn) => <LinkableCell to={patientPath(patientRec)}>{renderFn()}</LinkableCell>;

const HighPrioritiesTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,

  tableData,
  t,
  updateSavedFilterViewParameters,
  isFilterListView
}) => {
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.highPriority]?.star?.dataIndex,
      key: tableColumns[selectedViews.highPriority]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.name_id, {
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => renderWithLink(record, () => <PatientNameColumn record={record} />),
      minWidth: 300
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => renderWithLink(record, () => (record.isDeceased ? <DeceasedLable /> : ""))
    }),
    {
      ...tableColumns[selectedViews.highPriority]?.phone_number,
      render: (text, record) => renderWithLink(record, () => <PatientPhoneNumberColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.highPriority]?.dob,
      render: (text, record) => renderWithLink(record, () => <PatientDOBColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.highPriority]?.ssn,
      render: (text, record) => renderWithLink(record, () => <PatientSSNColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.highPriority]?.assignee,
      render: (text, record) => renderWithLink(record, () => <AssigneeColumn record={record} />),
      width: 230
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.facility, {
      render: (_, record) => renderWithLink(record, () => record.facility)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.physician, {
      render: (_, record) => renderWithLink(record, () => record.physician)
    }),
    {
      ...tableColumns[selectedViews.highPriority]?.employer,
      render: (_, record) => renderWithLink(record, () => record.employer)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.creationDate, {
      render: (text, record) => renderWithLink(record, () => moment(record.creationDate).format("MMM D, YYYY"))
    }),
    {
      ...tableColumns[selectedViews.highPriority]?.primaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.primary)(text, record))
    },
    {
      ...tableColumns[selectedViews.highPriority]?.secondaryInsurancePlan,
      render: (text, record) => renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.secondary))
    },
    {
      ...tableColumns[selectedViews.highPriority]?.tertiaryInsurancePlan,
      render: (text, record) => renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.tertiary))
    },
    {
      ...tableColumns[selectedViews.highPriority]?.agreementSignatureDate,
      render: (text, record) =>
        renderWithLink(record, () => record.agreement_signature_date && dateFormatter(record.agreement_signature_date))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.patientStatus, {
      render: (_, record) => renderWithLink(record, () => <PatientStatusCell value={record.status} />)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.followupDate, {
      render: (text, record) => renderWithLink(record, () => record.followupDate && dateFormatter(record.followupDate))
    }),
    {
      dataIndex: tableColumns[selectedViews.highPriority]?.edit?.dataIndex,
      key: tableColumns[selectedViews.highPriority]?.edit?.key,
      width: 35,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    }
  ];

  return (
    <>
      {!!tableData?.patients.length && (
        <ColumnSelectorContainer>
          <ColumnSelector />
        </ColumnSelectorContainer>
      )}
      <StyledTable
        size="small"
        isFilterListView={isFilterListView}
        rowClassName="cursor-pointer"
        style={{ width: "100%" }}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.highPriority]?.[col.key]?.display)}
        locale={{
          emptyText: <EmptyTableMessage />
        }}
        onRow={(record, rowIndex) => {
          return {
            onMouseEnter: () => setHoveringLineIndex(rowIndex),
            onMouseLeave: () => setHoveringLineIndex(null)
          };
        }}
        onChange={(pagination, filters, sorter) =>
          fetchTableData(pagination, sorter, tableData, setTableDataAct, updateSavedFilterViewParameters)
        }
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: tableData && tableData.selectedPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PatientPageCount total={total} range={range} isAccurateCount={tableData.isAccurateCount} />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients
                .map((patient, index) => {
                  return {
                    ...patient,
                    key: index
                  };
                })
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  isFetching: selectIsFetching([ApiNames.HighPrioritiesPhiSearch, ApiNames.HighPrioritiesSearch]),
  tableData: selectHighPrioritiesData
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.highPriority, sorter))
});

const HighPrioritiesTable = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(HighPrioritiesTableComponent);

export { HighPrioritiesTable };
