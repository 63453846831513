import React from "react";

import * as pfizerCopayOralFieldNames from "../../../../Pfizer/copay/oral/iqvia/form/fieldNames";
import { getPfizerCopayOralIQVIAFormInitialValues } from "../../../../Pfizer/copay/oral/iqvia/form/initialValues";
import { SaveButton } from "../../../../header";
import useBaseEEConfiguration from "../../../../hooks/useBaseEEConfiguration";
import PfizerCopayOralConsent from "./form/formSections/Consent";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../../../../constants";
import PfizerCopayOralPatient from "./form/formSections/Patient";
import { CompleteFormButton } from "../../../../CompleteFormButton";
import { applyTrimFields } from "../../../../utils/updateFormData";
import { dateFormatter } from "../../../../../../../utils/date";

const onSave = (form) => applyTrimFields(form);

export const usePfizerCopayOralIQVIAConfiguration = () => {
  const vendorName = "pfizerOral";
  const eePlanType = "copay";
  const rootPath = "application_form_editor.pfizer.copay.oral";
  const sidebarItems = ["patient", "consent"];

  const baseConfig = useBaseEEConfiguration({ vendorName, eePlanType, rootPath, sidebarItems });

  const pfizerCopayOralSpecificConfig = {
    activeFormSection: `#enrollment-form-patient`,
    fieldNames: pfizerCopayOralFieldNames,
    getFormInitialValues: getPfizerCopayOralIQVIAFormInitialValues,
    getFormSectionComponents: () => (
      <>
        <PfizerCopayOralPatient />
        <PfizerCopayOralConsent />
      </>
    ),
    applicationFormHeaderSubmission: (props) => (
      <CompleteFormButton id={"EE_pfizer_copay_oral_iqvia_submit"} {...props} />
    ),
    headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.SUBMIT_FORM,
    saveButton: (props) => (
      <SaveButton showPdfOnSave={false} onSave={onSave} id={"EE_pfizer_copay_oral_iqvia_save_form"} {...props} />
    ),
    resubmitButtonId: "EE_pfizer_copay_oral_IQVIA_resubmit_application_form",
    getShowEnrollResult: () => false,
    getEnrollResultData: ({ application }) => ({
      bin: application.bin,
      pcn: application.pcn,
      group: application.group,
      member_id: application.memberId,
      card_activation_date: {
        value: dateFormatter(application.approvedDate),
        style: { marginTop: "44.6px" }
      }
    }),
    afterSubmissionPayload: () => ({}),
    getApprovedResultDescription: () => <></>,
    getAdditionalResultCardInformation: false,
    getErrorText: (t) => t("patientApplications.enroll.enroll_result.pfizer_copay_oral_failure_massage")
  };

  return {
    ...baseConfig,
    ...pfizerCopayOralSpecificConfig
  };
};

export default usePfizerCopayOralIQVIAConfiguration;
