export const ESignLinkStatuses = Object.freeze({
  PENDING: "pending",
  SIGNED: "signed",
  EXPIRED: "expired",
  REVOKED: "revoked",
  TOO_MANY_ATTEMPTS: "too-many-attempts"
});

export const SIGNATURE_OPTIONS = Object.freeze({
  FILL_NOW: "fill_now",
  SIGNATURE_REQUEST: "remote"
});

export const FILL_NOW_METHODS = Object.freeze({
  DRAW: "fill_now_draw",
  UPLOAD: "fill_now_upload"
});

export const SignatureTypes = Object.freeze({
  REMOTE: "remote",
  FILL_NOW_DRAW: "fill_now_draw",
  FILL_NOW_UPLOAD: "fill_now_upload",
  SKIP_AND_UPLOAD_SIGNED_PDF: "skip_and_upload_signed_pdf",
  WET: "wet"
});

export const SignerTypes = Object.freeze({
  PHYSICIAN: "physician",
  PATIENT: "patient",
  HCP: "hcp",
  CAREGIVER: "caregiver"
});

export const RequiredSignerTypes = Object.freeze({
  HCP: SignerTypes.HCP,
  PHYSICIAN: SignerTypes.PHYSICIAN,
  PATIENT: SignerTypes.PATIENT,
  PATIENT_OR_CAREGIVER: "patient_or_caregiver"
});

export const SignerTypesByRequiredSigners = Object.freeze({
  [RequiredSignerTypes.PHYSICIAN]: [SignerTypes.PHYSICIAN],
  [RequiredSignerTypes.PATIENT]: [SignerTypes.PATIENT],
  [RequiredSignerTypes.HCP]: [SignerTypes.HCP],
  [RequiredSignerTypes.PATIENT_OR_CAREGIVER]: [SignerTypes.CAREGIVER, SignerTypes.PATIENT]
});

export const RequiredSignerTypesBySignerType = Object.freeze({
  [SignerTypes.PHYSICIAN]: [RequiredSignerTypes.PHYSICIAN],
  [SignerTypes.PATIENT]: [RequiredSignerTypes.PATIENT, RequiredSignerTypes.PATIENT_OR_CAREGIVER],
  [SignerTypes.HCP]: [RequiredSignerTypes.HCP],
  [SignerTypes.CAREGIVER]: [RequiredSignerTypes.PATIENT_OR_CAREGIVER]
});

export const ApplicationFormSignatureStatuses = Object.freeze({
  NOT_STARTED: "not_started",
  PENDING: "pending",
  EXPIRED: "expired",
  SIGNED: "signed",
  MANUALLY_SIGNED: "manually_signed"
});

export const SignatureRequestStatuses = Object.freeze({
  NOT_SENT: "not_sent",
  SUCCESS: "success",
  FAILURE: "failure"
});

export const ESignPendoEvents = Object.freeze({
  AUTOFILL_CLICK: "autofill_click",
  AUTOFILL_SAVE_DOWNLOAD_CLICK: "autofill_save_download_click",
  UPLOADED_PDF: "uploaded_pdf",
  PDF_CLICK: "pdf_click",
  EDIT_PDF_CLICK: "edit_pdf_click",
  EDIT_PDF_SAVED_CLICK: "edit_pdf_saved_click",
  SKIP_AND_UPLOAD_SIGNED_FORM_CLICK: "skip_&_upload_signed_form_click",
  UPLOAD_SIGNED_FORM: "upload_signed_form",
  TAB_CLICKED: "tab_clicked",
  DRAW_SIGNATURE_CLICK: "draw_signature_click",
  DRAW_SIGNATURE_VIEW: "draw_signature_view",
  DRAW_SIGNATURE_ADD: "draw_signature_add",
  IMAGE_UPLOAD_SIGNATURE_ADD: "image_upload_signature_add",
  UPLOAD_IMAGE_CLICK: "upload_image_click",
  DELETE_PDF_CLICK: "delete_pdf_click",
  DELETE_PDF_POPUP_CLICK: "delete_pdf_popup_click",
  SUBMIT_PDF_CLICK: "submit_pdf_click",
  MARK_AS_APPLIED_CLICK: "mark_as_applied_click",
  REQUEST_CLICK: "request_click",
  RESEND_CLICK: "resend_click",
  SEND_REMINDER: "send_reminder",
  REVOKE_PDF_CLICK: "revoke_pdf_click",
  REVOKE_PDF_POPUP_CLICK: "revoke_pdf_popup_click",
  REMOVE_SIGNATURE: "remove_signature",
  REMOVE_SIGNATURE_POP_UP: "remove_signature_pop_up",
  UPLOAD_PDF_ERROR_MESSAGE_VIEW: "upload_pdf_error_message_view",
  UPLOAD_IMAGE_ERROR_MESSAGE_VIEW: "upload_image_error_message_view",
  SIGNATURE_ADDED_ERROR_MESSAGE_VIEW: "signature_added_error_message_view",
  SEND_REQUEST_ERROR_MESSAGE_VIEW: "send_request_error_message_view",
  RESEND_REQUEST_ERROR_MESSAGE_VIEW: "resend_request_error_message_view",
  DOB_LOCKED_LINK: "dob_locked_link",
  APPLICATION_STATUS_CHANGED: "status_changed",
  PRINT_CLICKED: "print_clicked",
  DOWNLOAD_CLICKED: "download_clicked",
  EDIT_CONFIRMATION_POPUP_YES_BUTTON_CLICK: "edit_confirmation_popup_yes_button_click",
  EDIT_CONFIRMATION_POPUP_CLOSE_BUTTON_CLICK: "edit_confirmation_popup_close_button_click",
  UPLOAD_WET_SIGNED_FORM: "upload_wet_signed_form",
  PRINT_WET_PDF: "print_wet_pdf"
});

export const TEMPLATE_NAMES = Object.freeze({
  NOVARTIS_COPAY: "novartis-copay",
  REGENERON_COPAY: "regeneron-copay",
  REGENERON_PAP: "regeneron-pap",
  AUTO_FILL: "auto-fill",
  BMS_COPAY: "bms-copay"
});

export const ESignLinkTypes = Object.freeze({
  [TEMPLATE_NAMES.AUTO_FILL]: "esign",
  [TEMPLATE_NAMES.NOVARTIS_COPAY]: "novartis",
  [TEMPLATE_NAMES.BMS_COPAY]: "bms"
});

export const TEMPLATES_WITH_ATTACHMENT_IN_STEP_3 = Object.freeze([
  TEMPLATE_NAMES.NOVARTIS_COPAY,
  TEMPLATE_NAMES.BMS_COPAY
]);

export const ENABLED_TEMPLATE_AFTER_SIGNATURE = Object.freeze([TEMPLATE_NAMES.NOVARTIS_COPAY]);

export const ACTIVE_SIGNATURE_METHOD_BY_REQUIRED_SIGNER_TYPE = Object.freeze({
  [RequiredSignerTypes.PHYSICIAN]: SIGNATURE_OPTIONS.FILL_NOW,
  [RequiredSignerTypes.HCP]: SIGNATURE_OPTIONS.FILL_NOW,
  [RequiredSignerTypes.PATIENT]: SIGNATURE_OPTIONS.SIGNATURE_REQUEST,
  [RequiredSignerTypes.PATIENT_OR_CAREGIVER]: SIGNATURE_OPTIONS.SIGNATURE_REQUEST
});

export const DefaultWidgetSettings = Object.freeze({
  editable: true,
  shouldShowCaregiverIndication: true,
  shouldUseRemoteRequestCheckboxes: true,
  shouldHaveSignatureAsTextLabel: false,
  shouldHideMissingInfoMessage: false,
  shouldHideLinkToMPD: false
});

export const EsignRemoteRequestMChannels = Object.freeze({
  EMAIL: "email",
  SMS: "sms"
});
