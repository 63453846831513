import { Table } from "antd";
import moment from "moment-timezone";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import { ApiNames } from "../../api/api";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TabsMap } from "../../screen/patient/Patient";
import ACTION from "../../store/action";
import { selectIsFetching, selectPendingRenewalData, selectTableColumns } from "../../store/selector";
import { dateFormatter } from "../../utils/date";
import { fetchTableData } from "../../utils/fetchTableData";
import { capitalizeFirstLetter } from "../../utils/string";
import { ColumnSelector } from "../ColumnSelector";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import LinkableCell from "../UI/LinkableCell";
import {
  AssigneeColumn,
  AssociatedDrugColumnComponent,
  PatientDOBColumn,
  PatientNameColumn,
  PatientPhoneNumberColumn,
  PatientSSNColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import EmptyTableMessage from "./EmptyTableMessage";
import PatientPageCount from "./PatientPageCount";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
`;

const patientPath = ({ patientId, journeyId }) => TabsMap.applications.path(patientId, journeyId);

const renderWithLink = (patientRec, renderFn) => <LinkableCell to={patientPath(patientRec)}>{renderFn()}</LinkableCell>;

const PendingRenewalTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,
  tableData,
  t,
  updateSavedFilterViewParameters,
  isFilterListView
}) => {
  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.pendingRenewal]?.star?.dataIndex,
      key: tableColumns[selectedViews.pendingRenewal]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.name_id, {
      sorterTooltipText: t("columns.sortById"),
      minWidth: 300,
      render: (text, record) => renderWithLink(record, () => <PatientNameColumn record={record} />)
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.phone_number,
      render: (text, record) => renderWithLink(record, () => <PatientPhoneNumberColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.dob,
      render: (text, record) => renderWithLink(record, () => <PatientDOBColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.ssn,
      render: (text, record) => renderWithLink(record, () => <PatientSSNColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.assistanceProgram, {
      render: (text, record) =>
        renderWithLink(record, () => (record.assistanceProgramName ? record.assistanceProgramName : null))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.apType, {
      render: (text, record) => renderWithLink(record, () => (record.apType ? record.apType : null))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.providedBy, {
      render: (_, record) => renderWithLink(record, () => record.providedBy)
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.associatedDrug,
      render: (text, record) => renderWithLink(record, () => <AssociatedDrugColumnComponent record={record} />)
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.assignee,
      render: (text, record) => renderWithLink(record, () => <AssigneeColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.facility, {
      render: (_, record) => renderWithLink(record, () => record.facility)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.physician, {
      render: (_, record) => renderWithLink(record, () => record.physician)
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.employer,
      render: (_, record) => renderWithLink(record, () => record.employer)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.creationDate, {
      render: (text, record) => renderWithLink(record, () => moment(record.creationDate).format("MMM D, YYYY"))
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.patientStatus, {
      render: (_, record) => renderWithLink(record, () => <PatientStatusCell value={record.status} />)
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.primaryInsurancePlan,
      render: (_, record) => renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.primary)(_, record))
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.secondaryInsurancePlan,
      render: (_, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.secondary)(_, record))
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.tertiaryInsurancePlan,
      render: (_, record) => renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.tertiary)(_, record))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.applicationStatus, {
      width: 150,
      render: (text, record) =>
        renderWithLink(record, () =>
          record.applicationStatus ? capitalizeFirstLetter(record.applicationStatus) : null
        )
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.fundBalance, {
      render: (text, record) =>
        renderWithLink(record, () =>
          !isNaN(parseFloat(record.fundBalance)) && record.apType.trim().toLowerCase() !== "free drug"
            ? `$${record.fundBalance}`
            : null
        )
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.validThrough, {
      width: 130,
      render: (text, record) =>
        renderWithLink(record, () => {
          if (record.effectiveIndefinitely) {
            return t("patientApplications.indefinitely");
          } else {
            return moment(record.approvalValidThrough).isValid() && dateFormatter(record.approvalValidThrough);
          }
        })
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.approvedDate, {
      render: (text, record) => renderWithLink(record, () => record.approvedDate && dateFormatter(record.approvedDate))
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.agreementSignatureDate,
      render: (text, record) =>
        renderWithLink(record, () => record.agreement_signature_date && dateFormatter(record.agreement_signature_date))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.followupDate, {
      render: (text, record) => renderWithLink(record, () => record.followupDate && dateFormatter(record.followupDate))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.latestClaimDate, {
      render: (text, record) =>
        renderWithLink(record, () => record.latestClaimDate && dateFormatter(record.latestClaimDate))
    })
  ];
  return (
    <>
      {!!tableData?.patients.length && (
        <ColumnSelectorContainer>
          <ColumnSelector />
        </ColumnSelectorContainer>
      )}
      <StyledTable
        size="small"
        rowClassName="cursor-pointer"
        style={{ width: "100%" }}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.pendingRenewal]?.[col.key]?.display)}
        locale={{
          emptyText: <EmptyTableMessage type="enrollments" />
        }}
        onChange={(pagination, filters, sorter) =>
          fetchTableData(pagination, sorter, tableData, setTableDataAct, updateSavedFilterViewParameters)
        }
        isFilterListView={isFilterListView}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: tableData && tableData.selectedPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PatientPageCount
                total={total}
                range={range}
                isAccurateCount={tableData.isAccurateCount}
                isEnrollment={true}
              />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients
                .map((patient, index) => {
                  return {
                    ...patient,
                    key: index
                  };
                })
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectPendingRenewalData,
  isFetching: selectIsFetching([ApiNames.PendingRenewalPhiSearch, ApiNames.PendingRenewalSearch])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.pendingRenewal, sorter))
});

const PendingRenewalTable = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PendingRenewalTableComponent);

export { PendingRenewalTable };
