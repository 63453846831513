import { Table } from "antd";
import moment from "moment-timezone";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import { isNull } from "lodash";
import { ApiNames } from "../../api/api";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { Routes } from "../../constant/routes";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import ACTION from "../../store/action";
import { selectAllApplicationsData, selectIsFetching, selectTableColumns } from "../../store/selector";
import { dateFormatter } from "../../utils/date";
import { fetchTableData } from "../../utils/fetchTableData";
import { formatSSN, formatToDecimal } from "../../utils/formaters";
import { capitalizeFirstLetter } from "../../utils/string";
import { ColumnSelector } from "../ColumnSelector";
import { DeceasedLable } from "../DeceasedLable";
import { ESignatureStatusBadge } from "../Patient/PatientApplications/enrollTab/utils/components";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import LinkableCell from "../UI/LinkableCell";
import {
  AssigneeColumn,
  AssociatedDrugColumnComponent as AssociatedDrugColumn,
  PatientDOBColumn,
  PatientNameColumn,
  PatientPhoneNumberColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import EmptyTableMessage from "./EmptyTableMessage";
import PatientPageCount from "./PatientPageCount";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
`;

const renderWithLink = (patientRec, renderFn) => (
  <LinkableCell to={Routes.PATIENT_APPLICATION_PATH(patientRec)}>{renderFn()}</LinkableCell>
);

const AllApplicationsTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,
  tableData,
  t,
  updateSavedFilterViewParameters,
  isFilterListView
}) => {
  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.allApplications]?.star?.dataIndex,
      key: tableColumns[selectedViews.allApplications]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.name_id, {
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => renderWithLink(record, () => <PatientNameColumn record={record} />),
      minWidth: 300
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => renderWithLink(record, () => (record.isDeceased ? <DeceasedLable /> : ""))
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.phone_number,
      render: (text, record) => renderWithLink(record, () => <PatientPhoneNumberColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.allApplications]?.dob,
      render: (text, record) => renderWithLink(record, () => <PatientDOBColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.allApplications]?.ssn,
      render: (text, record) => renderWithLink(record, () => formatSSN(record.social_security_number))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.assistanceProgram, {
      render: (text, record) =>
        renderWithLink(record, () => (record.assistanceProgramName ? record.assistanceProgramName : null))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.providedBy, {
      render: (_, record) => renderWithLink(record, () => record.providedBy)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.apType, {
      render: (text, record) => renderWithLink(record, () => (record.apType ? record.apType : null))
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.associatedDrug,
      render: (text, record) => renderWithLink(record, () => <AssociatedDrugColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.applicationStatus, {
      width: 150,
      render: (text, record) =>
        renderWithLink(record, () =>
          record.applicationStatus ? capitalizeFirstLetter(record.applicationStatus) : null
        )
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.fundBalance, {
      render: (text, record) =>
        renderWithLink(record, () =>
          !isNull(record.fundBalance) ? `$${formatToDecimal(record.fundBalance) || 0} ` : null
        )
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.validThrough, {
      width: 130,
      render: (text, record) =>
        renderWithLink(record, () => {
          if (record.effectiveIndefinitely) {
            return t("patientApplications.indefinitely");
          } else {
            return moment(record.approvalValidThrough).isValid() && dateFormatter(record.approvalValidThrough);
          }
        })
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.approvedAmount, {
      render: (text, record) =>
        renderWithLink(record, () => (record.approvedAmount ? `$${formatToDecimal(record.approvedAmount)}` : ""))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.approvedDate, {
      render: (text, record) => renderWithLink(record, () => record.approvedDate && dateFormatter(record.approvedDate))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.member_id, {
      render: (text, record) => renderWithLink(record, () => (record.memberId ? record.memberId : null))
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.assignee,
      render: (text, record) => renderWithLink(record, () => <AssigneeColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.facility, {
      render: (_, record) => renderWithLink(record, () => record.facility)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.physician, {
      render: (_, record) => renderWithLink(record, () => record.physician)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.patientStatus, {
      render: (_, record) => renderWithLink(record, () => <PatientStatusCell value={record.status} />)
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.agreementSignatureDate,
      render: (text, record) =>
        renderWithLink(
          record,
          () => moment(record.agreement_signature_date).isValid() && dateFormatter(record.agreement_signature_date)
        )
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.followupDate, {
      render: (text, record) => renderWithLink(record, () => record.followupDate && dateFormatter(record.followupDate))
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.primaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.primary)(text, record))
    },
    {
      ...tableColumns[selectedViews.allApplications]?.secondaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.secondary)(text, record))
    },
    {
      ...tableColumns[selectedViews.allApplications]?.tertiaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.tertiary)(text, record))
    },
    {
      ...tableColumns[selectedViews.allApplications]?.applicationSignatureStatus,
      render: (text, record) =>
        renderWithLink(
          record,
          () =>
            record.applicationSignatureStatus && <ESignatureStatusBadge status={record.applicationSignatureStatus} />
        )
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.latestClaimDate, {
      render: (text, record) =>
        renderWithLink(record, () => record.latestClaimDate && dateFormatter(record.latestClaimDate))
    })
  ];
  return (
    <>
      {!!tableData?.patients.length && (
        <ColumnSelectorContainer>
          <ColumnSelector />
        </ColumnSelectorContainer>
      )}
      <StyledTable
        size="small"
        rowClassName="cursor-pointer"
        isFilterListView={isFilterListView}
        style={{ width: "100%" }}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.allApplications]?.[col.key]?.display)}
        locale={{
          emptyText: <EmptyTableMessage type="enrollments" />
        }}
        onChange={(pagination, filters, sorter) =>
          fetchTableData(pagination, sorter, tableData, setTableDataAct, updateSavedFilterViewParameters)
        }
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: tableData && tableData.selectedPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PatientPageCount
                total={total}
                range={range}
                isAccurateCount={tableData.isAccurateCount}
                isEnrollment={true}
              />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients
                .map((patient, index) => {
                  return {
                    ...patient,
                    key: index
                  };
                })
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectAllApplicationsData,
  isFetching: selectIsFetching([ApiNames.AllApplicationsPhiSearch, ApiNames.AllApplicationsSearch])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.allApplications, sorter))
});

const AllApplicationsTable = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AllApplicationsTableComponent);

export { AllApplicationsTable };
