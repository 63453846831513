import _ from "lodash";

export const updateJson = (sections, setJson) => {
  const sectionKeys = Object.keys(sections);
  const updatedRows = {};
  sectionKeys.forEach((sectionKey) => {
    const section = sections[sectionKey];
    const rows = section.rows;

    const newRows = [];
    let currentRow = { rowIndex: 0, fields: [] };
    let remainingSpace = 24;

    rows
      .flatMap((row) => row.fields)
      .forEach((field) => {
        if (field.fullLine) {
          // If the current row has fields, push it first
          if (currentRow.fields.length) {
            newRows.push(currentRow);
          }

          // Create a new row just for this field
          newRows.push({
            rowIndex: newRows.length,
            fields: [field]
          });

          // Start a new row after adding fullLine field
          currentRow = { rowIndex: newRows.length, fields: [] };
          remainingSpace = 24;
        } else {
          if (field.colSize > remainingSpace) {
            // Start a new row
            newRows.push(currentRow);
            currentRow = { rowIndex: newRows.length, fields: [] };
            remainingSpace = 24;
          }
          currentRow.fields.push(field);
          remainingSpace -= field.colSize;
        }
      });

    // add last row if exists
    if (currentRow.fields.length) {
      newRows.push(currentRow);
    }

    updatedRows[section.sectionName] = newRows;
  });

  // Update the JSON
  setJson((prevJson) => {
    const updatedJson = { ...prevJson };

    if (_.isEmpty(updatedJson)) {
      //for first upload of the file
      updatedJson.formSections = [];
      sectionKeys.forEach((sectionKey) => {
        const newSection = sections[sectionKey];
        newSection.rows = updatedRows[sections[sectionKey].sectionName];
        updatedJson.formSections.push(newSection);
      });
    } else {
      updatedJson.formSections.forEach((formSection) => {
        formSection.rows = updatedRows[formSection.sectionName];
      });
    }

    return updatedJson;
  });
};
