import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import _ from "lodash";

import formFieldComponents from "../components/form/utils/formFieldComponents";
import CustomFieldEngine from "../../../customField/customFieldEngine";
import SectionPart from "../components/SectionPart";
import * as validationRules from "./index";

export const getFieldName = (sectionName, field) => {
  if (field.subSectionName) {
    return [sectionName, field.subSectionName, field.name];
  }
  return [sectionName, field.name];
};

const AND = "AND";
const operators = Object.freeze({
  Equal: "=",
  NotEqual: "!=",
  GreaterThan: ">",
  LessThan: "<",
  GreaterThanOrEqual: ">=",
  LessThanOrEqual: "<="
});

const evaluateCondition = (logic, rules, form) => {
  const results = rules.map((rule) => {
    const { operator, relatedFieldName, value } = rule;
    const relatedFieldNameSplit = relatedFieldName.split(".");
    const relatedFieldValue = form.getFieldValue(relatedFieldNameSplit);

    let result = false;
    switch (operator) {
      case operators.Equal:
        result = relatedFieldValue === value;
        break;
      case operators.NotEqual:
        result = relatedFieldValue !== value;
        break;
      case operators.GreaterThan:
        result = relatedFieldValue > value;
        break;
      case operators.LessThan:
        result = relatedFieldValue < value;
        break;
      case operators.GreaterThanOrEqual:
        result = relatedFieldValue >= value;
        break;
      case operators.LessThanOrEqual:
        result = relatedFieldValue <= value;
        break;
      default:
        throw new Error(`Unsupported operator. ${operator}`);
    }
    return result;
  });

  return logic === AND ? results.every(Boolean) : results.some(Boolean);
};

export const getValidationRules = (field) => {
  const { rules: ruleNames = [], rulesParams = {} } = field;
  if (!ruleNames.length) {
    return [];
  }
  const rulesArr = ruleNames.map((ruleName) => {
    const rule = validationRules[ruleName];
    if (!rule) {
      return null;
    }
    if (typeof rule === "function" && rulesParams[ruleName]) {
      return rule(rulesParams[ruleName] || {});
    }
    return rule;
  });

  return _.compact(_.flatten(rulesArr));
};

const renderReactFromJson = (form, json) => {
  return json.formSections.map((section) => (
    <SectionPart key={section.sectionName} sectionId={section.sectionName} headerTranslationKey={section.sectionHeader}>
      {renderReactRowsJson({ section, rows: section.rows, form })}
    </SectionPart>
  ));
};

export const renderReactRowsJson = ({ section, rows, form }) => {
  const { t } = useTranslation();

  return rows.map((row, rowIndex) => (
    <Row key={`${section?.sectionName}_row_${rowIndex}`} gutter={[16, 16]}>
      {row.fields.map((field) => {
        const FieldComponent = formFieldComponents[field.type] || CustomFieldEngine;
        const span = field.colSize || 8;

        let isVisible = true;
        let isDisabled = field.fieldProps?.disabled || false;
        let isRequired = field.required || false;

        if (field.conditions) {
          const { visibility, disabled, required } = field.conditions;
          if (visibility) {
            isVisible = evaluateCondition(visibility.logic, visibility.rules, form);
          }
          if (!isVisible) {
            return null;
          }
          if (required) {
            isRequired = evaluateCondition(required.logic, required.rules, form);
          }
          if (disabled) {
            isDisabled = evaluateCondition(disabled.logic, disabled.rules, form);
          }
        }

        return (
          <Col span={span} key={field.id || field.label}>
            <FieldComponent
              type={field.type}
              name={getFieldName(section.sectionName, field)}
              label={t(field.label)}
              required={isRequired}
              rules={getValidationRules(field)}
              placeholder={field.placeholder}
              fieldProps={{ ...field.fieldProps, ...(isDisabled && { disabled: true }) }}
              formItemProps={field.formItemProps}
              form={form}
              {...field.dedicatedFieldProps}
            />
          </Col>
        );
      })}
    </Row>
  ));
};

export default renderReactFromJson;
