import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  padding: 0;
  /* Ensure a minimum height if the cell is empty */
  min-height: calc(2.5em + 5px);

  &:hover {
    color: inherit;
  }
`;
