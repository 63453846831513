import styled from "styled-components";
import { Badge, Button, Layout, Row, Space, Typography } from "antd";

import {
  BLACK,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_25,
  PRIMARY_400,
  PRIMARY_50,
  SUCCESS_100,
  SUCCESS_700,
  WHITE
} from "../../constant/colors";
import { fontWeights, sizes } from "../../constant/styles";

const { Sider } = Layout;
const { Text } = Typography;

const LogoButton = styled(Button)`
  padding: 0px;
  background: transparent !important;
  margin-top: ${({ collapsed }) => (collapsed ? "10px" : "0px")};
  position: relative;
  right: ${({ collapsed, hasModulesToSwitch }) => (collapsed ? (hasModulesToSwitch ? "12px" : "8px") : "0px")};

  :hover {
    background: transparent;
  }
`;

const LogoSpace = styled(Space)`
  padding: 5px;
  background: transparent;

  &:hover {
    background: ${PRIMARY_25};
    border-radius: 8px;
  }

  &:active {
    background: ${PRIMARY_50};
    border-radius: 8px;
  }
`;

const LogoContainer = styled(Space)`
  padding: 25px 0px 0px 30px;
  gap: 0px !important;
  width: 100%;
`;

const FinancialNavigationText = styled(Text)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
  color: ${GRAY_900};
`;

const CollapsibleButton = styled(Button)`
  font-size: ${sizes.medium_large};
  width: 30px;
  height: 30px;
  position: absolute;
  background: ${PRIMARY_50};
  border-radius: 50%;
  ${(props) => {
    if (props.collapsed) {
      return `left: 65px; `;
    }
  }}

  ${(props) => {
    if (!props.collapsed) {
      return `left: 285px; `;
    }
  }}

   ${(props) => {
    if (props.isDevEnvironment) {
      return ` top: 45px; `;
    }
  }}

  ${(props) => {
    if (!props.isDevEnvironment) {
      return ` top: 20px; `;
    }
  }}
  
  &:hover {
    background: ${PRIMARY_100} !important;
  }

  &:disabled {
    opacity: 0.3;
  }

  :focus {
    background: ${PRIMARY_50};
  }

  :active {
    border: 3px solid ${PRIMARY_400} !important;
    background: ${PRIMARY_50} !important;
  }
  z-index: 100;
`;

const MenuTitle = styled(Row)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
  background: ${PRIMARY_50};
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 34px;
  ${(props) => {
    if (!props.collapsed) {
      return `justify-content: start; 
              padding: 0px 0px 0px 20px; `;
    }
  }}
  ${(props) => {
    if (props.collapsed) {
      return `justify-content: center; 
              padding: 8px 0px;`;
    }
  }}
  margin: 0px 15px;
  border-radius: 8px;
  .ant-typography {
    color: ${GRAY_900};
    margin-left: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

const CustomSider = styled(Sider)`
  background: ${WHITE};
  border: 0px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .ant-menu-inline {
    border: 0px;
  }

  .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    border: 0px;
  }

  ul > li {
    margin-top: 11px;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: 0px;
  }

  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover {
    background-color: transparent;
    border-radius: 8px;
    width: auto;
    margin-left: 0;

    span:not(.ant-badge-not-a-wrapper):not(.ant-scroll-number-only):not(.ant-scroll-number-only-unit) {
      display: flex;
      background-color: ${PRIMARY_25};
      margin-left: 8px;
      margin-right: 16px;
      border-radius: 8px;
      align-items: center;
      height: 28px;
    }

    span > span:not(.ant-badge-not-a-wrapper):not(.ant-scroll-number-only):not(.ant-scroll-number-only-unit) {
      color: ${BLACK};
      margin-left: 45px !important;
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    span:not(.ant-badge-not-a-wrapper):not(.ant-scroll-number-only):not(.ant-scroll-number-only-unit) {
      color: ${GRAY_900};
      font-size: ${sizes.medium};
      font-weight: ${fontWeights.regular};
      margin-left: 53px;
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item {
    margin: 0px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active {
    background-color: transparent;
    border-radius: 8px;
    width: auto;
    margin-left: 0;

    span:not(.ant-badge-not-a-wrapper):not(.ant-scroll-number-only):not(.ant-scroll-number-only-unit) {
      display: flex;
      background-color: ${PRIMARY_25};
      margin-left: 8px;
      margin-right: 16px;
      border-radius: 8px;
      align-items: center;
      height: 28px;
    }

    span span:not(.ant-badge-not-a-wrapper):not(.ant-scroll-number-only):not(.ant-scroll-number-only-unit) {
      color: ${BLACK};
      margin-left: 45px !important;
    }
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;
  }

  .ant-menu ul,
  .ant-menu ol {
    ${(props) => {
      if (props.collapsed) {
        return `display: none;`;
      }
    }}
  }

  .ant-menu-item-group {
    ${(props) => {
      if (props.collapsed) {
        return `width: 80px;
        `;
      }
    }}
  }

  .ant-menu-item-group-title {
    ${(props) => {
      if (!props.collapsed) {
        return ` padding: 0px 16px;
        `;
      }
    }}
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item {
    margin: 1px 0px 0px 0px;
    height: 22px;
  }

  ul > li > ul > li:first-child {
    margin-top: 4px !important;
  }

  #side_menu {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    flex: 1;

    ${(props) => {
      if (props.collapsed) {
        return `align-items: center;`;
      }
    }}

    #help_center {
      margin-top: auto;
    }

    #footer {
      font-weight: ${fontWeights.regular};
      font-size: 11px;
      color: ${BLACK};
      display: flex;
      margin: 50px 0px 0px 35px;
    }
  }
`;

const CustomBadge = styled(Badge)`
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-scroll-number-only-unit {
    font-size: ${sizes.small};
  }

  .ant-badge-count {
    font-size: ${sizes.small};
    background-color: ${SUCCESS_100};
    color: ${SUCCESS_700};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
  }
`;

const DisabledMenuItemText = styled(Text)`
  opacity: 0.3;
`;

const CreatePatientButton = styled(Button)`
  position: relative;
  left: 100px;
  height: 26px;
  width: 26px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  background: transparent;

  &:hover {
    background: ${PRIMARY_100};
  }
  &:disabled {
    opacity: 0.3;
    :hover {
      opacity: 0.3;
    }
    svg > path {
      fill: ${GRAY_900} !important;
    }
  }

  :focus {
    background: transparent;
  }

  :active {
    border: 3px solid ${PRIMARY_400} !important;
    background: ${PRIMARY_100} !important;
  }
`;

export {
  CustomSider,
  LogoButton,
  LogoContainer,
  FinancialNavigationText,
  CollapsibleButton,
  MenuTitle,
  CustomBadge,
  DisabledMenuItemText,
  CreatePatientButton,
  LogoSpace
};
