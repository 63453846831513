import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "antd";

import UnhandledType from "./fields/UnhandledType";
import { BOOLEAN_OBJECT } from "./sharedData";
import formFieldComponents from "../Patient/ApplicationFormEditor/components/form/utils/formFieldComponents";
import { getFieldName, getValidationRules } from "../Patient/ApplicationFormEditor/utils/renderReactFromJson";

export const PreviewField = ({ field, span, value, handleFieldChange, sectionName }) => {
  const { t } = useTranslation();

  if (!field || !field.type) {
    console.error("Invalid field:", field);
    return null;
  }
  const type = field.type?.replace(/ /g, "_");

  const FieldComponent = formFieldComponents[type] || UnhandledType;
  const isRequired = field.required === BOOLEAN_OBJECT.true;

  return (
    <Col span={span}>
      <FieldComponent
        type={field.type}
        name={getFieldName(sectionName, field)}
        required={isRequired}
        placeholder={field.placeholder}
        rules={getValidationRules(field || {})}
        value={value}
        field={field}
        label={t(field.label)}
        fieldProps={{ ...field.fieldProps, ...(type === "phone" && { typeName: "phoneType", addon: true }) }}
        formItemProps={field.formItemProps}
        {...field.dedicatedFieldProps}
        onChange={(value) => handleFieldChange(field.label, value)}
      />
    </Col>
  );
};
