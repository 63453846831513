import { Space, Typography } from "antd";
import moment from "moment-timezone";
import React from "react";
import { displayedMRN } from "@tailormed/common-client/util/mrn";

import { ReactComponent as IconUnknownGender } from "../../assets/svg/unknown-gender.svg";
import { GRAY_100, GRAY_300, GRAY_600, GRAY_900 } from "../../constant/colors";
import { useGetSetting } from "../../hooks/getSetting";

const { Text } = Typography;

const PatientDetailsSummary = ({ patient }) => {
  const [prefix] = useGetSetting(["id_prefix"]);

  return (
    <div
      style={{
        backgroundColor: GRAY_100,
        padding: 16,
        borderRadius: 8,
        border: `1px solid ${GRAY_300}`
      }}
    >
      <div style={{ display: "inline-block", position: "relative" }}>
        <IconUnknownGender style={{ position: "absolute", top: "50%", height: "10em", marginTop: "-5em" }} />
        <Space direction="vertical" style={{ marginLeft: 50 }} size={0}>
          <Space>
            <Space size={4}>
              <Text
                ellipsis={true}
                style={{ color: GRAY_900, fontWeight: 600, fontSize: 18, maxWidth: "180px" }}
                id="created_patient_first_name"
              >
                {patient?.firstName}
              </Text>
              {patient?.middleName && (
                <Text
                  ellipsis={true}
                  style={{ color: GRAY_900, fontWeight: 600, fontSize: 18, maxWidth: "180px" }}
                  id="created_patient_middle_name"
                >
                  {patient.middleName}
                </Text>
              )}
              <Text
                ellipsis={true}
                style={{ color: GRAY_900, fontWeight: 600, fontSize: 18, maxWidth: "180px" }}
                id="created_patient_last_name"
              >
                {patient?.lastName}
              </Text>
            </Space>
            <Text style={{ color: GRAY_600 }} id="created_patient_id">{`(${prefix?.toUpperCase() || "TM"}-${
              patient?.id
            })`}</Text>
          </Space>
          <Space>
            <Space size={3}>
              <Text style={{ color: GRAY_600, fontWeight: 600 }}>MRN:</Text>
              <Text style={{ color: GRAY_600 }} id="created_patient_mrn">
                {displayedMRN(patient?.mrn)}
              </Text>
            </Space>
            <Space size={3}>
              <Text style={{ color: GRAY_600, fontWeight: 600 }}>DOB:</Text>
              <Text style={{ color: GRAY_600 }} id="created_patient_dob">{`${patient?.dob} (${moment().diff(
                patient?.dob,
                "years"
              )})`}</Text>
            </Space>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default PatientDetailsSummary;
