import { getPurePhoneNumber, getValueOrNull } from "../../../../../../utils/misc";
import { FORM_FIELD_TYPES, OTHER, PhoneNumberType } from "../../../constants";
import { cleanUnspecifiedValue } from "./cleanUnspecifiedValue";
import { getPreferredLanguage } from "./getPreferredLanguage";

const INSURANCE = "insurance";

const getIsSelectOther = (section, field) => {
  if (field.alternativeFields) {
    const hasData = field.alternativeFields.some((alternativeField) => {
      return !!section?.[[alternativeField]];
    });
    if (hasData) {
      return OTHER;
    }
  }
  return null;
};

const fieldManipulations = {
  [FORM_FIELD_TYPES.PHONE]: ({ value, sectionObj, section }) => {
    sectionObj.phoneType = section?.phoneType || PhoneNumberType.MOBILE;
    if (!value) return null;

    return getPurePhoneNumber(value);
  },

  [FORM_FIELD_TYPES.LANGUAGE]: ({ value }) => {
    if (!value) return null;

    return getPreferredLanguage(value);
  },

  [FORM_FIELD_TYPES.STATE]: ({ value }) => {
    if (!value) return null;

    return getValueOrNull(value);
  },

  [FORM_FIELD_TYPES.INPUT]: ({ value, sectionName }) => {
    if (!value) return null;

    if (sectionName.includes(INSURANCE)) {
      return cleanUnspecifiedValue(value);
    }
    return value;
  },

  [FORM_FIELD_TYPES.SINGLE_SELECT]: ({ value, section, field }) => {
    if (value) return value;

    return getIsSelectOther(section, field);
  }
};

const getSectionOrSubSectionIfExists = (field, sourceValues) =>
  field.subSectionName ? sourceValues?.[field.subSectionName] : sourceValues;

const manipulateField = (field, sectionName, acc, sectionValues) => {
  let sectionObj = getSectionOrSubSectionIfExists(field, acc[sectionName]);
  if (!sectionObj) {
    acc[sectionName][field.subSectionName] = {};
    sectionObj = acc[sectionName][field.subSectionName];
  }

  const section = getSectionOrSubSectionIfExists(field, sectionValues);
  const value = section?.[field.name];

  let manipulatedValue = value;
  const fieldManipulation = fieldManipulations[field.type];
  if (fieldManipulation) {
    manipulatedValue = fieldManipulation({ value, sectionObj, section, sectionName, field });
  }
  if (!value) {
    manipulatedValue = field.defaultValue;
  }

  sectionObj[field.name] = manipulatedValue;
};

export const getGenericFormInitialValues = (applicationForm, formJson) => {
  const formData = applicationForm?.formData;
  const initialValues = formJson.formSections.reduce((acc, section) => {
    const sectionName = section.sectionName;
    acc[sectionName] = {};
    section.rows.forEach((row) => {
      return row.fields.forEach((field) => {
        manipulateField(field, sectionName, acc, formData?.[sectionName]);
      });
    });
    return acc;
  }, {});

  return initialValues;
};
