import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Tooltip, Typography } from "antd";
import { saveAs } from "file-saver";

import { dateTimeFormatter, DATE_TIME_FILTERS_FORMAT } from "../../utils/date";
import { PRIMARY_600, GRAY_700 } from "../../constant/colors";
import { ExportOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { exportTasksReportAPI } from "../../api/api";
import { UNASSIGNED } from "../../constant/filters";
import { fontWeights, sizes } from "../../constant/styles";
const { Text } = Typography;

const StyledButton = styled(Button)`
  background: unset;

  :active,
  :hover {
    background: unset;
  }

  :disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;

const downloadFile = (blob, fileName) => {
  const fileBlob = new Blob([blob.data], { type: blob.headers["content-type"] });
  saveAs(fileBlob, fileName);
};

const ExportTasksData = ({ filterData }) => {
  const { t } = useTranslation();
  const getReportData = async () => {
    const filters = {
      assignee: filterData.assignees === UNASSIGNED ? "" : filterData.assignees,
      status: filterData.status,
      dueDate: filterData?.dueDate?.start
        ? { start: filterData.dueDate.start, end: filterData.dueDate.end }
        : filterData.period
    };

    const blob = await exportTasksReportAPI(filters);
    const fileName = `${t("patientsTasks.export.fileNamePrefix")}_[${dateTimeFormatter(
      Date(),
      DATE_TIME_FILTERS_FORMAT
    )}]`;
    downloadFile(blob, fileName);
  };

  return (
    <Tooltip
      title={
        <Text style={{ color: GRAY_700, fontSize: sizes.small, fontWeight: fontWeights.semibold }}>
          {t("patientsTasks.export.download_tasks")}
        </Text>
      }
      color={"white"}
      placement="right"
    >
      <StyledButton
        id="export_tasks_button"
        type="text"
        onClick={() => {
          getReportData();
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <ExportOutlined style={{ color: PRIMARY_600 }} />
        </div>
      </StyledButton>
    </Tooltip>
  );
};

export default ExportTasksData;
