import { Popover, Table, Typography } from "antd";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { ApiNames } from "../../api/api";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TabsMap } from "../../screen/patient/Patient";
import ACTION from "../../store/action";
import { selectIsFetching, selectTableColumns, selectVisitScheduleData } from "../../store/selector";
import { DATE_TIME_FORMAT, dateFormatter, dateTimeFormatter, utcDateFormatter } from "../../utils/date";
import { fetchTableData } from "../../utils/fetchTableData";
import { ColumnSelector } from "../ColumnSelector";
import { DeceasedLable } from "../DeceasedLable";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import LinkableCell from "../UI/LinkableCell";
import {
  AssigneeColumn,
  EditColumn,
  PatientDOBColumn,
  PatientNameColumn,
  PatientPhoneNumberColumn,
  PatientSSNColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import EmptyTableMessage from "./EmptyTableMessage";
import PatientPageCount from "./PatientPageCount";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
`;
const { Text } = Typography;

const patientPath = ({ patientId, journeyId }) => TabsMap.overview.path(patientId, journeyId);

const renderWithLink = (patientRec, renderFn) => <LinkableCell to={patientPath(patientRec)}>{renderFn()}</LinkableCell>;

const VisitScheduleTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,
  tableData,
  t,
  updateSavedFilterViewParameters,
  isFilterListView
}) => {
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.visitSchedule]?.star?.dataIndex,
      key: tableColumns[selectedViews.visitSchedule]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.name_id, {
      minWidth: 300,
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => renderWithLink(record, () => <PatientNameColumn record={record} />)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => renderWithLink(record, () => (record.isDeceased ? <DeceasedLable /> : ""))
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.phone_number,
      render: (text, record) => renderWithLink(record, () => <PatientPhoneNumberColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.dob,
      render: (text, record) => renderWithLink(record, () => <PatientDOBColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.ssn,
      render: (text, record) => renderWithLink(record, () => <PatientSSNColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.primaryDiagnosis,
      render: (text, record) => renderWithLink(record, () => (record.diagnosises[0] ? record.diagnosises[0] : null))
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.assignee,
      width: 230,
      render: (text, record) => renderWithLink(record, () => <AssigneeColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.visitFacility, {
      render: (_, record) => renderWithLink(record, () => record.visitFacility)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.facility, {
      render: (_, record) => renderWithLink(record, () => record.facility)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.physician, {
      render: (_, record) => renderWithLink(record, () => record.physician)
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.employer,
      render: (_, record) => renderWithLink(record, () => record.employer)
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.adminDate,
      render: (text, record) =>
        renderWithLink(record, () =>
          record.adminDates ? (
            <Popover
              overlayInnerStyle={{ padding: "20px" }}
              content={
                <div>
                  <Text>{t("popover_visit_schedule")}</Text>
                  <ul style={{ marginTop: "20px" }}>
                    {record.adminDates.map((itemDates, keyDates) => (
                      <li key={keyDates}>
                        <Text style={{ fontWeight: "bold" }}>{dateTimeFormatter(itemDates)}</Text>
                        {record.adminTypes &&
                          record.adminTypes.map(
                            (itemTypes, keyTypes) => keyDates === keyTypes && <Text> - {itemTypes}</Text>
                          )}
                      </li>
                    ))}
                  </ul>
                </div>
              }
            >
              <p
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "170px",
                  overflow: "hidden"
                }}
              >
                {record.adminDates.map((item, key) =>
                  key + 1 === record.adminDates.length ? dateTimeFormatter(item) : dateTimeFormatter(item) + " & "
                )}
              </p>
            </Popover>
          ) : (
            utcDateFormatter(record.adminDate, DATE_TIME_FORMAT)
          )
        )
    },

    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.adminType, {}),

    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.creationDate, {
      render: (text, record) => renderWithLink(record, () => dateTimeFormatter(record.creationDate, "MMM D, YYYY"))
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.agreementSignatureDate,
      render: (text, record) =>
        renderWithLink(record, () => record.agreement_signature_date && dateFormatter(record.agreement_signature_date))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.patientStatus, {
      render: (_, record) => renderWithLink(record, () => <PatientStatusCell value={record.status} />)
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.primaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.primary)(text, record))
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.secondaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.secondary)(text, record))
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.tertiaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.tertiary)(text, record))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.followupDate, {
      render: (text, record) => renderWithLink(record, () => record.followupDate && dateFormatter(record.followupDate))
    }),
    {
      dataIndex: tableColumns[selectedViews.visitSchedule]?.edit?.dataIndex,
      key: tableColumns[selectedViews.visitSchedule]?.edit?.key,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    }
  ];

  return (
    <>
      {!!tableData?.patients.length && (
        <ColumnSelectorContainer>
          <ColumnSelector />
        </ColumnSelectorContainer>
      )}
      <StyledTable
        size="small"
        rowClassName="cursor-pointer"
        style={{ width: "100%" }}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.visitSchedule]?.[col.key]?.display)}
        locale={{
          emptyText: <EmptyTableMessage />
        }}
        onRow={(_, rowIndex) => {
          return {
            onMouseEnter: () => setHoveringLineIndex(rowIndex),
            onMouseLeave: () => setHoveringLineIndex(null)
          };
        }}
        onChange={(pagination, filters, sorter) =>
          fetchTableData(pagination, sorter, tableData, setTableDataAct, updateSavedFilterViewParameters)
        }
        isFilterListView={isFilterListView}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: tableData && tableData.selectedPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PatientPageCount total={total} range={range} isAccurateCount={tableData.isAccurateCount} />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients
                .map((patient, index) => ({ ...patient, key: index }))
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  isFetching: selectIsFetching([ApiNames.VisitSchedulePhiSearch, ApiNames.VisitScheduleSearch]),
  tableData: selectVisitScheduleData
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.visitSchedule, sorter))
});

const VisitScheduleTable = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(VisitScheduleTableComponent);

export { VisitScheduleTable };
