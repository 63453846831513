import { useSelectedApplication, useSelectedExpressEnrollTemplateName } from "../hooks";
import eaglePemfexyConfiguration from "./Eagle/configurations";
import pfizerCopayIvConfigurations from "./Pfizer/copay/iv/configurations";
import { useGenentechFreeDrugConfiguration } from "./Genentech/freeDrug/useGenentechFreeDrugConfiguration";
import pfizerFreeDrugConfigurations from "./Pfizer/configurations";
import genentechCopayConfigurations from "./Genentech/copay/configurations";
import pfizerCopayOralConfigurations from "./Pfizer/copay/oral/configurations";
import { useNovartisCopayConfiguration } from "./Novartis/copay/useNovartisCopayConfiguration";
import { usePfizerCopayOralIQVIAConfiguration } from "./Pfizer/copay/oral/iqvia/usePfizerCopayOralConfiguration";
import { useBmsCopayConfiguration } from "./Bms/copay/useBmsCopayConfiguration";
import { useRegeneronCopayConfiguration } from "./Regeneron/copay/useRegeneronCopayConfiguration";

export const TEMPLATE_NAMES = Object.freeze({
  PFIZER_FREE_DRUG: "pfizer-free-drug",
  GENENTECH_COPAY: "genentech-copay",
  GENENTECH_FREE_DRUG: "genentech-free-drug",
  PFIZER_COPAY_ORAL: "pfizer-copay-oral",
  PFIZER_COPAY_ORAL_IQVIA: "pfizer-copay-oral-iqvia",
  PFIZER_COPAY_IV: "pfizer-copay-iv",
  EAGLE_PEMFEXY: "eagle-pemfexy",
  NOVARTIS_COPAY: "novartis-copay",
  BMS_COPAY: "bms-copay",
  REGENERON_COPAY: "regeneron-copay"
});

export const ExpressEnrollMapper = Object.freeze({
  [TEMPLATE_NAMES.PFIZER_FREE_DRUG]: pfizerFreeDrugConfigurations,
  [TEMPLATE_NAMES.GENENTECH_COPAY]: genentechCopayConfigurations,
  [TEMPLATE_NAMES.PFIZER_COPAY_ORAL]: pfizerCopayOralConfigurations,
  [TEMPLATE_NAMES.PFIZER_COPAY_IV]: pfizerCopayIvConfigurations,
  [TEMPLATE_NAMES.EAGLE_PEMFEXY]: eaglePemfexyConfiguration,
  [TEMPLATE_NAMES.GENENTECH_FREE_DRUG]: () => useGenentechFreeDrugConfiguration(),
  [TEMPLATE_NAMES.NOVARTIS_COPAY]: () => useNovartisCopayConfiguration(),
  [TEMPLATE_NAMES.PFIZER_COPAY_ORAL_IQVIA]: () => usePfizerCopayOralIQVIAConfiguration(),
  [TEMPLATE_NAMES.BMS_COPAY]: () => useBmsCopayConfiguration(),
  [TEMPLATE_NAMES.REGENERON_COPAY]: () => useRegeneronCopayConfiguration()
});

export const getExpressEnrollmentMapping = () => {
  const expressEnrollTemplateName = useSelectedExpressEnrollTemplateName();
  const application = useSelectedApplication();

  const expressEnrollMapping = application?.potentialSaving?.hasExpressEnroll
    ? ExpressEnrollMapper[expressEnrollTemplateName]
    : null;

  const configuration = expressEnrollMapping instanceof Function ? expressEnrollMapping() : expressEnrollMapping;
  return configuration || {};
};
