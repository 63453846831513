import React, { useEffect, useState } from "react";
import { Menu, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selector";
import { useTranslation } from "react-i18next";
import { getRandomSuggestedQuestionsAIChatBot as getRandomSuggestedQuestionsAPI } from "../../api/api";
import { useFetch } from "../../hooks/fetch";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import { ReactComponent as AIChatBotIcon } from "../../assets/svg/icon-ai-chat-bot.svg";
import {
  SuggestedQuestionsMenuStyle,
  SuggestedQuestionsMenuItemStyle,
  SuggestedQuestionsMenuIteIconStyle,
  IntroPanelHelloTextStyle,
  IntroPanelHowCanIHelpTextStyle,
  IntroPanelSuggestedQuestionTextStyle
} from "./Styles";
const { Text } = Typography;
const LIMIT = 3;

const IntroPanel = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [suggestedQuestion, setSuggestedQuestion] = useState(null);

  const [getRandomSuggestedQuestions, getRandomSuggestedQuestionsResult, , getRandomSuggestedQuestionsError] =
    useFetch(getRandomSuggestedQuestionsAPI);

  useEffect(() => {
    if (user) {
      getRandomSuggestedQuestions(LIMIT);
    }
  }, [user]);

  useEffect(() => {
    if (getRandomSuggestedQuestionsError) {
      ErrorMessage(t("ai_chat_bot.errors.failed_fetching_suggested_questions"));
    }
  }, [getRandomSuggestedQuestionsError]);

  useEffect(() => {
    if (getRandomSuggestedQuestionsResult) {
      const randomSuggestedQuestions = getRandomSuggestedQuestionsResult?.data;
      if (randomSuggestedQuestions?.length) {
        const items = randomSuggestedQuestions.map((question) => ({
          key: question.id,
          label: question.text
        }));

        setSuggestedQuestion(items);
      }
    }
  }, [getRandomSuggestedQuestionsResult]);

  return (
    <div style={{ padding: "16px", width: "75%" }}>
      <Row>
        <Text style={IntroPanelHelloTextStyle}>
          {t("ai_chat_bot.intro_panel.hello_message", {
            firstName: user?.firstName
          })}
        </Text>
      </Row>
      <Row style={{ marginTop: "5px" }}>
        <Text style={IntroPanelHowCanIHelpTextStyle}>{t("ai_chat_bot.intro_panel.how_can_i_help")}</Text>
      </Row>
      <Row style={{ paddingTop: "30px" }}>
        <Text style={IntroPanelSuggestedQuestionTextStyle}>{t("ai_chat_bot.intro_panel.suggested")}</Text>
        <Menu
          mode="horizontal"
          style={SuggestedQuestionsMenuStyle}
          items={suggestedQuestion?.map((item) => ({
            ...item,
            style: SuggestedQuestionsMenuItemStyle,
            className: "custom-button",
            icon: <AIChatBotIcon style={SuggestedQuestionsMenuIteIconStyle} />
          }))}
        ></Menu>
      </Row>
    </div>
  );
};

export { IntroPanel };
