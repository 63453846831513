import React from "react";
import { useSelector } from "react-redux";
import { Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { GRAY_200, GRAY_50, GRAY_900 } from "../../../../../constant/colors";
import { useSelectedApplication } from "../../../hooks";
import {
  selectApplicationForm,
  selectPatientDetails,
  selectSelectedAssistanceProgramById
} from "../../../../../store/selector";
import { getExpressEnrollmentMapping } from "../../../ApplicationFormEditor/configurations";
import moment from "moment-timezone";
import { DATE_FORMAT } from "../../../../../utils/date";

const { Text } = Typography;

const CardStyled = styled(Card)`
  .ant-card-body {
    padding: 10px 10px 10px 16px;
  }
  border-radius: 8px;
  background: ${GRAY_50};
  width: 353px;
`;

const FieldNameStyledDiv = styled(Text)`
  font-weight: 300;
  color: #1b1d44;
  font-style: normal;
  line-height: 130%;
`;

const FieldValueStyledText = styled(Text)`
  color: #1b1d44;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

const CardStyledDark = styled(Card)`
  .ant-card-body {
    padding: 14px 16px 28px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .ant-card-body > *:not(:first-child) {
    margin-top: 14px;
  }
  .ant-card-head {
    padding: 0 16px;
    border-bottom: 1px solid #32336136;
  }
  .ant-card-head-title {
    font-size: 18px;
    font-weight: 600;
    color: ${GRAY_900};
  }
  border-radius: 8px;
  background: ${GRAY_200};
  width: fit-content;
`;

const getDetailsToDisplayOnCard = (enrollResultData, t) =>
  Object.entries(enrollResultData).map(([field, data]) => {
    const value = typeof data === "object" ? data?.value : data;
    const style = { ...data?.style, ...(field === "activationDate" && { marginTop: 40 }) };

    return (
      <div key={field} style={style}>
        <FieldNameStyledDiv>{t(`patientApplications.enroll.enroll_result.${field}`)}</FieldNameStyledDiv>
        <FieldValueStyledText id={`enroll_result_${field}`}>
          {moment(value, moment.ISO_8601, true).isValid() ? moment(value).format(DATE_FORMAT) : value}
        </FieldValueStyledText>
      </div>
    );
  });

export const EnrollResultCard = ({ enrollData, applicationName }) => {
  const { t } = useTranslation();
  const application = useSelectedApplication();
  const patientDetails = useSelector(selectPatientDetails);
  const applicationForm = useSelector(selectApplicationForm);
  const selectedAssistanceProgramById = useSelector(selectSelectedAssistanceProgramById);

  const expressEnrollMapping = getExpressEnrollmentMapping();
  const { getEnrollResultData = () => ({}), getAdditionalResultCardInformation } = expressEnrollMapping || {};
  const enrollResultData = enrollData || getEnrollResultData({ application, patientDetails, applicationForm, t });
  const detailsToDisplay = getDetailsToDisplayOnCard(enrollResultData, t) || null;

  const additionalResultCardInformation = !enrollData && getAdditionalResultCardInformation;
  const phone = selectedAssistanceProgramById?.phone1;
  const StyledCard = applicationName ? CardStyledDark : CardStyled;
  return (
    <StyledCard title={applicationName}>
      {detailsToDisplay}
      {additionalResultCardInformation && (
        <FieldValueStyledText style={{ display: "flex", marginTop: "10px" }}>
          {t(additionalResultCardInformation, {
            phone: phone
          })}
        </FieldValueStyledText>
      )}
    </StyledCard>
  );
};

export default EnrollResultCard;
