import React, { useCallback, useState, useEffect, memo } from "react";
import ReactDOM from "react-dom";

import { Space, Button } from "antd";
import { LikeOutlined, DislikeOutlined, CopyOutlined } from "@ant-design/icons";
import { postFeedbackToMessage as postFeedbackToMessageAPI } from "../../api/api";
import { useFetch } from "../../hooks/fetch";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import { useTranslation } from "react-i18next";
import { NEW_BLUE, PHILIPPINE_SILVER } from "../../constant/colors";

const MESSAGE_FEEDBACK = {
  LIKE: "like",
  DISLIKE: "dislike",
  EMPTY: null
};

// Memoized button components to prevent re-renders
const FeedbackButton = memo(({ icon, isActive, onClick, disabled }) => (
  <Button
    type="text"
    style={{ backgroundColor: "transparent", border: "none", fontSize: "16px" }}
    icon={React.cloneElement(icon, { style: { color: isActive ? NEW_BLUE : PHILIPPINE_SILVER } })}
    onClick={onClick}
    disabled={disabled}
  />
));

// Main component, memoized to prevent re-renders when props don't change
const MessageActions = memo(({ conversationId, messageId, messageContent, messageFeedback }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [localFeedback, setLocalFeedback] = useState(messageFeedback || null);
  const [postFeedbackToMessage, postFeedbackToMessageResult, postFeedbackToIsLoading, postFeedbackToMessageError] =
    useFetch(postFeedbackToMessageAPI);

  useEffect(() => {
    if (postFeedbackToMessageError) {
      ErrorMessage(t("ai_chat_bot.errors.failed_message_feedback_submission"));
      setLocalFeedback(null);
    }
  }, [postFeedbackToMessageError]);

  useEffect(() => {
    if (postFeedbackToMessageResult?.data) {
      setLocalFeedback(postFeedbackToMessageResult.data);
    }
  }, [postFeedbackToMessageResult]);

  const handleLike = useCallback(() => {
    const newFeedback = localFeedback === MESSAGE_FEEDBACK.LIKE ? null : MESSAGE_FEEDBACK.LIKE;
    setLocalFeedback(newFeedback);
    postFeedbackToMessage(conversationId, messageId, newFeedback);
  }, [conversationId, messageId, localFeedback, postFeedbackToMessage]);

  const handleDislike = useCallback(() => {
    const newFeedback = localFeedback === MESSAGE_FEEDBACK.DISLIKE ? null : MESSAGE_FEEDBACK.DISLIKE;
    setLocalFeedback(newFeedback);
    postFeedbackToMessage(conversationId, messageId, newFeedback);
  }, [conversationId, messageId, localFeedback, postFeedbackToMessage]);

  const handleCopy = useCallback(async () => {
    try {
      const decodedContent = messageContent ? decodeURIComponent(messageContent) : "";
      await navigator.clipboard.writeText(decodedContent);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    } catch (err) {
      ErrorMessage(t("ai_chat_bot.errors.failed_copying_message"));
    }
  }, [messageContent]);

  const isLikeActive = localFeedback === MESSAGE_FEEDBACK.LIKE;
  const isDislikeActive = localFeedback === MESSAGE_FEEDBACK.DISLIKE;

  return (
    <div style={{ display: "flex", justifyContent: "start" }}>
      <Space size={1} style={{ display: "flex", justifyContent: "start" }}>
        <FeedbackButton icon={<CopyOutlined />} isActive={copied} onClick={handleCopy} />
        <FeedbackButton
          icon={<LikeOutlined />}
          isActive={isLikeActive}
          onClick={handleLike}
          disabled={postFeedbackToIsLoading}
        />
        <FeedbackButton
          icon={<DislikeOutlined />}
          isActive={isDislikeActive}
          onClick={handleDislike}
          disabled={postFeedbackToIsLoading}
        />
      </Space>
    </div>
  );
});

const MessageActionsElement = memo(({ conversationId, messageId, messageContent, messageFeedback }) => {
  return (
    <MessageActions
      conversationId={conversationId}
      messageId={messageId}
      messageContent={messageContent}
      messageFeedback={messageFeedback}
    />
  );
});

// Register web component
const registerMessageActionsWebComponent = () => {
  class MessageActionsWebComponent extends HTMLElement {
    constructor() {
      super();
      this.root = document.createElement("div");
      this.appendChild(this.root);
    }

    connectedCallback() {
      const conversationId = this.getAttribute("conversation-id");
      const messageId = this.getAttribute("message-id");
      const messageContent = this.getAttribute("message-content");
      const messageFeedback = this.getAttribute("message-feedback");

      ReactDOM.render(
        <MessageActionsElement
          conversationId={conversationId}
          messageId={messageId}
          messageContent={messageContent}
          messageFeedback={messageFeedback}
        />,
        this.root
      );
    }

    disconnectedCallback() {
      ReactDOM.unmountComponentAtNode(this.root);
    }
  }

  if (!customElements.get("message-actions")) {
    customElements.define("message-actions", MessageActionsWebComponent);
  }
};

export { registerMessageActionsWebComponent };
