export const placeholderForFieldOptions = Object.freeze(["First Options", "Second Options", "Third Options"]);
export const BOOLEAN_OBJECT = Object.freeze({ true: "true", false: "false" });
export const FIELD_DATA_STRUCTURE = Object.freeze({
  SECTION_NAME: "sectionName",
  SECTION_HEADER: "sectionHeader",
  SECTION_SIDEBAR: "sectionSidebar",
  SUB_SECTION_NAME: "subSectionName",
  FE_NAME: "name",
  BE_NAME: "BEName",
  FHIR_NAME: "FHIRName",
  LABEL: "label",
  TYPE: "type",
  REQUIRED: "required",
  PLACEHOLDER: "placeholder",
  DEFAULT_VALUE: "defaultValue",
  ALTERNATIVE_FIELDS: "alternativeFields",
  INITIAL_VALUE: "initialValue",
  OPTIONS: "options",
  CONDITION: "condition"
});
