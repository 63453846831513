import { Table } from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import "./cursor.less";

import { ApiNames } from "../../api/api";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TabsMap } from "../../screen/patient/Patient";
import ACTION from "../../store/action";
import {
  selectAllPatientsData,
  selectIsFetching,
  selectSearchedPatients,
  selectTableColumns
} from "../../store/selector";
import { dateFormatter } from "../../utils/date";
import { fetchTableData } from "../../utils/fetchTableData";
import { ColumnSelector } from "../ColumnSelector";
import { DeceasedLable } from "../DeceasedLable";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import LinkableCell from "../UI/LinkableCell";
import {
  AssigneeColumn,
  EditColumn,
  PatientDOBColumn,
  PatientNameColumn,
  PatientPhoneNumberColumn,
  PatientSSNColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import EmptyTableMessage from "./EmptyTableMessage";
import PatientPageCount from "./PatientPageCount";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
  .ant-table-thead > tr > th:nth-last-of-type(-n + 2)::before {
    display: none;
  }

  .ant-table {
    max-height: ${({ isSearch }) => {
      if (isSearch) return "calc(100vh - 262px)";
    }};
  }

  .ant-table-empty {
    margin: ${({ isSearch }) => {
      if (isSearch) return "20px 0px 40px 0px;";
    }};

    .ant-table-tbody {
      height: ${({ isSearch }) => {
        if (isSearch) return "calc(100vh - 300px)";
      }};
    }
  }

  margin-top: ${({ isSearch }) => {
    if (isSearch) return "0px";
  }};
`;

const patientPath = ({ patientId, journeyId }) => TabsMap.overview.path(patientId, journeyId);

const renderWithLink = (patientRec, renderFn) => <LinkableCell to={patientPath(patientRec)}>{renderFn()}</LinkableCell>;

const AllPatientsTableComponent = ({
  fetchOrders,
  isSearch,
  tableColumns,
  isFetching,
  setTableDataAct,
  tableData,
  tableDataWithSearch,
  isFetchingWithSearch,
  t,
  updateSavedFilterViewParameters = undefined,
  isFilterListView
}) => {
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const fetchData = (pagination, filters, sorter) => {
    const targetData = isSearch ? tableDataWithSearch : tableData;
    fetchTableData(
      pagination,
      sorter,
      targetData,
      setTableDataAct,
      updateSavedFilterViewParameters,
      isSearch,
      fetchOrders
    );
  };

  const {
    patients: searchedPatients = [],
    totalPatients,
    selectedPage: searchResultCurrentPage
  } = tableDataWithSearch || {};

  const currentPage = isSearch ? searchResultCurrentPage : tableData && tableData.selectedPage;
  const totalRecordsCount = isSearch ? totalPatients : tableData?.totalPatients;
  const sortingEnabled = isSearch ? tableDataWithSearch?.sortingEnabled : tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.allPatients]?.star?.dataIndex,
      key: tableColumns[selectedViews.allPatients]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.name_id, {
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => renderWithLink(record, () => <PatientNameColumn record={record} />)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => renderWithLink(record, () => (record.isDeceased ? <DeceasedLable /> : ""))
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.phone_number,
      render: (text, record) => renderWithLink(record, () => <PatientPhoneNumberColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.allPatients]?.dob,
      render: (text, record) => renderWithLink(record, () => <PatientDOBColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.allPatients]?.ssn,
      render: (text, record) => renderWithLink(record, () => <PatientSSNColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.allPatients]?.primaryDiagnosis,
      render: (text, record) => renderWithLink(record, () => (record.diagnosises[0] ? record.diagnosises[0] : null))
    },
    {
      ...tableColumns[selectedViews.allPatients]?.assignee,
      width: 230,
      render: (text, record) => renderWithLink(record, () => <AssigneeColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.facility, {
      render: (_, record) => renderWithLink(record, () => record?.facility)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.physician, {
      render: (_, record) => renderWithLink(record, () => record?.physician)
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.employer,

      render: (_, record) => renderWithLink(record, () => record?.employer)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.creationDate, {
      render: (text, record) => renderWithLink(record, () => moment(record.creationDate).format("MMM D, YYYY"))
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.journeyEvent,
      render: (text, record) =>
        renderWithLink(record, () => (
          <div style={{ display: "flex" }}>
            <div>
              <img src={`/images/events/${record.journeyUpcomingEventIcon}`} alt="" />
            </div>
            <div>
              <div style={{ textTransform: "capitalize" }}>{record.journeyUpcomingEventChapter}</div>
              <div style={{ color: "#9b9b9b" }}>{record.journeyUpcomingEvent}</div>
            </div>
          </div>
        ))
    },
    {
      ...tableColumns[selectedViews.allPatients]?.primaryInsurancePlan,
      render: (_, record) => renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.primary)(_, record))
    },
    {
      ...tableColumns[selectedViews.allPatients]?.secondaryInsurancePlan,
      render: (_, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.secondary)(_, record))
    },
    {
      ...tableColumns[selectedViews.allPatients]?.tertiaryInsurancePlan,
      render: (_, record) => renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.tertiary)(_, record))
    },
    {
      ...tableColumns[selectedViews.allPatients]?.agreementSignatureDate,
      render: (text, record) =>
        renderWithLink(record, () => record.agreement_signature_date && dateFormatter(record.agreement_signature_date))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.patientStatus, {
      render: (_, record) => renderWithLink(record, () => <PatientStatusCell value={record.status} />)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.followupDate, {
      render: (text, record) => renderWithLink(record, () => record.followupDate && dateFormatter(record.followupDate))
    }),
    {
      dataIndex: tableColumns[selectedViews.allPatients]?.edit?.dataIndex,
      key: tableColumns[selectedViews.allPatients]?.edit?.key,
      width: 35,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    }
  ];

  return (
    <>
      {((!isSearch && !!tableData?.patients.length) || (isSearch && !!searchedPatients.length)) && (
        <ColumnSelectorContainer isSearchResultsView={isSearch}>
          <ColumnSelector isSearchResultsView={false} />
        </ColumnSelectorContainer>
      )}

      <StyledTable
        size="small"
        isSearch={isSearch}
        rowClassName="cursor-pointer"
        style={{ width: "100%" }}
        isFilterListView={isFilterListView}
        loading={{ indicator: <TailormedSpinner />, spinning: (isSearch && isFetchingWithSearch) || isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.allPatients]?.[col.key]?.display)}
        locale={{
          emptyText: <EmptyTableMessage />
        }}
        onRow={(_, rowIndex) => {
          return {
            onMouseEnter: () => setHoveringLineIndex(rowIndex),
            onMouseLeave: () => setHoveringLineIndex(null)
          };
        }}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <PatientPageCount
              total={total}
              range={range}
              isAccurateCount={isSearch ? totalPatients : tableData.isAccurateCount}
            />
          )
        }}
        onChange={fetchData}
        dataSource={
          !isSearch
            ? tableData && tableData.patients
              ? tableData.patients
                  .map((patient, index) => ({ ...patient, key: index }))
                  .sort((a, b) => b.isActive - a.isActive)
              : null
            : searchedPatients.length
            ? searchedPatients
                .map((patient, index) => ({ ...patient, key: index }))
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectAllPatientsData,
  tableDataWithSearch: selectSearchedPatients,
  isFetching: selectIsFetching([ApiNames.AllPatientsPhiSearch, ApiNames.AllPatientsSearch]),
  isFetchingWithSearch: selectIsFetching([ApiNames.PhiSearchFreeText, ApiNames.SearchFreeText])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.allPatients, sorter))
});

const AllPatientsTable = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AllPatientsTableComponent);

export { AllPatientsTable };
