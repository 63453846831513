import React, { useRef } from "react";
import _ from "lodash";
import { Button, Tooltip, Upload } from "antd";
import { ExportOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";

import { JsonEditor } from "./JsonEditor";
import { BOOLEAN_OBJECT, FIELD_DATA_STRUCTURE } from "./sharedData";
import { updateJson } from "./updateJson";

const defaultColSize = 12;
const datepickerDefaultColSize = 8;

const expectedHeaders = Object.values(FIELD_DATA_STRUCTURE);

export const CsvToJson = ({ setJson, json }) => {
  const jsonEditorRef = useRef(null);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const structuredJson = parseCSV(text);
      updateJson(structuredJson.formSections, setJson);
    };
    reader.readAsText(file);
    return false;
  };

  const buildField = (row) => ({
    type: row[FIELD_DATA_STRUCTURE.TYPE],
    name: row[FIELD_DATA_STRUCTURE.FE_NAME],
    BEName: row[FIELD_DATA_STRUCTURE.BE_NAME],
    FHIRName: row[FIELD_DATA_STRUCTURE.FHIR_NAME],
    subSectionName: row[FIELD_DATA_STRUCTURE.SUB_SECTION_NAME],
    label: row[FIELD_DATA_STRUCTURE.LABEL],
    rules: [],
    required: row[FIELD_DATA_STRUCTURE.REQUIRED] || BOOLEAN_OBJECT.false,
    placeholder: row[FIELD_DATA_STRUCTURE.PLACEHOLDER],
    colSize: row[FIELD_DATA_STRUCTURE.TYPE] !== "datepicker" ? defaultColSize : datepickerDefaultColSize,
    defaultValue: row[FIELD_DATA_STRUCTURE.DEFAULT_VALUE],
    alternativeFields: row[FIELD_DATA_STRUCTURE.ALTERNATIVE_FIELDS]?.split(",").map((item) => item.trim()),
    fieldProps: {},
    formItemProps: {},
    dedicatedFieldProps: {}
  });

  const parseSections = (rows) => {
    let rowIndex = 0;

    const sectionsObj = rows.reduce((acc, row) => {
      const sectionName = row[FIELD_DATA_STRUCTURE.SECTION_NAME];
      if (!acc[sectionName]) {
        acc[sectionName] = {
          sectionName: sectionName,
          sectionHeader: row[FIELD_DATA_STRUCTURE.SECTION_HEADER],
          sectionSidebar: row[FIELD_DATA_STRUCTURE.SECTION_SIDEBAR],
          rows: [
            {
              rowIndex: rowIndex++,
              fields: []
            }
          ]
        };
      }
      acc[sectionName].rows[0].fields.push(buildField(row));
      return acc;
    }, {});

    const sectionsArr = Object.values(sectionsObj);

    return sectionsArr;
  };

  const buildStructuredJson = (rows) => {
    const structuredJson = { formSections: [] };
    const formSections = parseSections(rows);
    structuredJson.formSections = formSections;

    return structuredJson;
  };

  const parseCSV = (text) => {
    const lines = text.split("\n");
    const headers = lines[0].split(",").map((header) => header.trim());

    if (!_.isEqual(headers, expectedHeaders)) {
      alert("CSV headers do not match the expected format.");
      return null;
    }

    const rows = lines.slice(1).map((line) => {
      const values = line.split(",").map((value) => value.trim());
      const processedRow = {};

      expectedHeaders.forEach((header, index) => {
        let value = values[index];
        if (header === "section" && !value) {
          return;
        }
        if (!value) {
          value = header === "required" ? BOOLEAN_OBJECT.false : null;
        } else {
          switch (header) {
            case "options":
              // For "options" header, split the string by ";" and trim each part to create an array
              value = value.split(";").map((item) => item.trim());
              break;
            case "required":
              value = value.toLowerCase() === BOOLEAN_OBJECT.true ? BOOLEAN_OBJECT.true : BOOLEAN_OBJECT.false;
              break;
          }
        }
        processedRow[header] = value;
      });

      processedRow["colSize"] = processedRow["type"] !== "datepicker" ? defaultColSize : datepickerDefaultColSize;
      return processedRow;
    });

    const structuredJson = buildStructuredJson(rows);
    return structuredJson;
  };

  const handleSvgExport = () => {
    // TODO: Implement this
  };

  const handleJSONExport = () => {
    const jsonStr = JSON.stringify(json, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.json";
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  };

  const handleSave = () => {
    if (jsonEditorRef.current) {
      jsonEditorRef.current.handleSave();
    }
  };

  return (
    <div>
      {/* TODO: add option to export SVG file */}
      <Tooltip placement="top" title={"Will implements soon"}>
        <Button type="primary" onClick={handleSvgExport} icon={<ExportOutlined />} style={{ margin: 5 }} disabled>
          SVG
        </Button>
      </Tooltip>
      <Button type="primary" onClick={handleJSONExport} icon={<ExportOutlined />} style={{ margin: 5 }}>
        JSON
      </Button>
      <Button type="primary" onClick={handleSave} icon={<SaveOutlined />} style={{ margin: 5 }}>
        Update UI
      </Button>
      <Upload accept=".csv" beforeUpload={handleFileUpload} showUploadList={false}>
        <Button icon={<UploadOutlined />} style={{ margin: 5 }}>
          Upload CSV
        </Button>
      </Upload>
      <JsonEditor ref={jsonEditorRef} json={json} onChange={setJson} />
    </div>
  );
};
