import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Divider, Popover, Select, Space, Typography } from "antd";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
const { Option } = Select;

import { selectPatientDetails } from "../../../store/selector";
import { fontWeights, sizes } from "../../../constant/styles";
import { GRAY_400, GRAY_600, GRAY_900, GRAY_300, GRAY7 } from "../../../constant/colors";
import { numberWithCommas } from "../../../utils/string";
import { PatientCoverageDetails } from "../PatientTopSection/PatientCoverageDetails";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { formatPhoneNumber } from "../../../utils/formaters";
import { ShorterTextWithTooltip } from "../../ShorterText";
import { makeStyleWrapper } from "../../../utils/styles";
import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";
import ACTIONS from "../../../store/action";
import { updatePreferredLanguageAPI } from "../../../api/api";
import { inputStyle } from "../PatientTopSection";
import { MoreDetailsTextStyle } from "./styles";
import { AlternateContactOptions } from "../../../constant/patientCaregiver";
import { ALL_LANGUAGES } from "../../../constant/languages";

const { Text } = Typography;
const primaryTextStyle = { fontSize: sizes.medium, color: GRAY_900, fontWeight: fontWeights.regular };
const secondaryTextStyle = { fontSize: sizes.small, color: GRAY_900, fontWeight: fontWeights.semibold };

const wrapDropdown = makeStyleWrapper(`
  .ant-select-item {
   font-size:${sizes.small};
   color:${GRAY_900}
  }
`);

const CustomSelect = styled(Select)`
  div.ant-select-selector {
    padding-left: 0 !important;
    padding-right: 12 !important;
    background-color: ${(props) =>
      props.style.hoverPreferredlanguage || props.style.isFetching ? `${GRAY7} !important` : `transparent !important`};
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    margin-top: 1px;
  }
  .ant-select-arrow {
    margin-top: -4px;
  }
  ${(props) => ({ ...props.inputStyle })};
`;

const StyledCustomSelect = styled(CustomSelect)`
  .ant-select-selection-search {
    left: 0px !important;
  }
`;
const PatientPersonalDetailsComponent = ({ t, patientDetails }) => {
  const dispatch = useDispatch();
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [hoverPreferredlanguage, setHoverPreferredlanguage] = useState(false);

  const {
    alternateContactOption,
    emailCaregiver,
    fullNameCaregiver,
    phoneCaregiver,
    relationToPatientCaregiver,
    phoneNumber,
    homeNumber
  } = patientDetails || {};

  const hasAlternateContact = !!(emailCaregiver || fullNameCaregiver || phoneCaregiver || relationToPatientCaregiver);

  const getDisplayedPhoneNumber = () => {
    if (patientDetails) {
      const displayedPhoneNumber = phoneNumber || homeNumber;
      if (displayedPhoneNumber) {
        return formatPhoneNumber(displayedPhoneNumber);
      }
    }
    return "--";
  };
  useEffect(() => {
    setPreferredLanguage(patientDetails?.preferredLanguage);
  }, [patientDetails]);

  const handleUpdatePreferredLanguage = async (preferredLanguage) => {
    setPreferredLanguage(preferredLanguage);
    try {
      setIsFetching(true);
      const result = await updatePreferredLanguageAPI(patientDetails.id, preferredLanguage);
      if (result && result.data && result.data.success) {
        SuccessMessage(t("patientTopSection.success_message_update_preferred_language"));
        dispatch(ACTIONS.updatePatientDetailsAction({ ...patientDetails, preferredLanguage }));
      } else {
        ErrorMessage(t("patientTopSection.failed_message_update_preferred_language"));
      }
    } finally {
      setIsFetching(false);
    }
  };

  const totalHouseholdSize = patientDetails
    ? patientDetails.householdSize ||
      Number(patientDetails.householdSizeAdults ?? 0) + Number(patientDetails.householdSizeChildren ?? 0)
    : null;

  return (
    <Space direction="vertical" size={0}>
      <Text style={{ ...secondaryTextStyle, color: GRAY_600 }}>{t("patientTopSection.contact_details")}</Text>
      <div
        style={{
          padding: "0px 0px 0px 10px",
          marginLeft: "-10px"
        }}
      >
        <Space>
          <Text style={secondaryTextStyle}>{t("patientTopSection.phone_number")}:</Text>
          <Text style={primaryTextStyle} id="contact_details_phone_number">
            {getDisplayedPhoneNumber()}
          </Text>
        </Space>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gap: "2px",
            width: "100%"
          }}
        >
          <Text style={{ ...secondaryTextStyle, whiteSpace: "nowrap" }}>
            {t("patientTopSection.preferred_language")}:
          </Text>
          <StyledCustomSelect
            id="preferred_language"
            loading={isFetching}
            bordered={false}
            style={{
              ...inputStyle,
              color: GRAY_900,
              fontSize: sizes.medium,
              fontWeights: fontWeights.regular,
              marginLeft: "5px",
              marginTop: "-3px",
              isFetching,
              hoverPreferredlanguage,
              maxWidth: "none",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              minWidth: "0px"
            }}
            onMouseEnter={() => setHoverPreferredlanguage(true)}
            onMouseLeave={() => setHoverPreferredlanguage(false)}
            value={preferredLanguage}
            showSearch={true}
            dropdownMatchSelectWidth={false}
            showArrow={hoverPreferredlanguage || isFetching}
            allowClear={true}
            disabled={isFetching}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={<Text style={{ color: GRAY_400, fontWeight: 400 }}>{t("select")}</Text>}
            dropdownRender={wrapDropdown}
            onChange={(value) => handleUpdatePreferredLanguage(value)}
          >
            {ALL_LANGUAGES.map((p, index) => (
              <React.Fragment key={p.value}>
                <Option value={p.value}>{p.label}</Option>
                {index === 4 && (
                  <Option disabled key="divider" style={{ cursor: "default" }}>
                    --------------------
                  </Option>
                )}
              </React.Fragment>
            ))}
          </StyledCustomSelect>
        </div>

        {hasAlternateContact && (
          <Space>
            <Popover
              trigger="click"
              getPopupContainer={(trigger) => trigger.parentNode}
              placement="rightTop"
              overlayInnerStyle={{
                minWidth: "220px",
                padding: "0px 12px",
                borderRadius: "8px",
                boxShadow: " 0px 4px 4px 0px #00000040"
              }}
              content={
                <Space size={0} direction="vertical">
                  <Space size={2} direction="vertical">
                    {alternateContactOption && (
                      <Text style={{ ...secondaryTextStyle, color: GRAY_600 }}>
                        {Object.values(AlternateContactOptions)
                          .find((alternateContact) => alternateContact.value == alternateContactOption)
                          .labelPatientTopSection(t) + ":"}
                      </Text>
                    )}
                  </Space>

                  <Space>
                    <Text style={secondaryTextStyle}>{t("patientTopSection.name")}:</Text>
                    <Text
                      style={{ ...primaryTextStyle, display: "flex", maxWidth: "400px", whiteSpace: "pre-wrap" }}
                      id="alternate_contact_name"
                    >
                      {fullNameCaregiver || ""}
                    </Text>
                  </Space>

                  <Space>
                    <Text style={secondaryTextStyle}>{t("patientTopSection.relation_to_patient")}:</Text>
                    <Text style={primaryTextStyle} id="alternate_contact_relation_to_patient">
                      {relationToPatientCaregiver || ""}
                    </Text>
                  </Space>

                  <Space>
                    <Text style={secondaryTextStyle}>{t("patientTopSection.phone_number")}:</Text>
                    <Text style={{ ...primaryTextStyle, color: GRAY_900 }} id="alternate_contact_phone_number">
                      {phoneCaregiver ? formatPhoneNumber(phoneCaregiver) : ""}
                    </Text>
                  </Space>

                  <Space>
                    <Text style={secondaryTextStyle}>{t("patientTopSection.email")}:</Text>
                    <Text style={primaryTextStyle} id="alternate_contact_email">
                      {emailCaregiver || ""}
                    </Text>
                  </Space>
                </Space>
              }
            >
              <MoreDetailsTextStyle id="alternate_contact_details">
                + {t("patientTopSection.alternate_contact_details")}
              </MoreDetailsTextStyle>
            </Popover>
          </Space>
        )}

        <Divider style={{ background: GRAY_300, marginTop: "12px", marginBottom: "5px" }} />
      </div>
      <Space>
        <Text style={secondaryTextStyle}>{t("patientTopSection.financial")}:</Text>
        <Text style={primaryTextStyle}>
          {t("patientTopSection.fpl")}
          {patientDetails && patientDetails.fpl !== null && patientDetails.fpl !== undefined && totalHouseholdSize > 0
            ? ` ${parseInt(patientDetails.fpl)}%`
            : " --"}
        </Text>
        <Popover
          content={
            <Space size={16} style={{ width: "100%", padding: "0 12px" }}>
              <Space direction="vertical">
                <Text style={{ fontSize: sizes.medium }}>{t("total_income_yearly")}</Text>
                <Text style={{ fontSize: sizes.medium }}>{t("total_household_size")}</Text>
              </Space>
              <Space direction="vertical">
                <Text>
                  {patientDetails && patientDetails.income !== 0 ? `$${numberWithCommas(patientDetails.income)}` : "--"}
                </Text>
                <Text>{(patientDetails && totalHouseholdSize) || "--"}</Text>
              </Space>
            </Space>
          }
          trigger="hover"
          placement="bottom"
        >
          <QuestionCircleOutlined
            id="fpl_question_mark"
            style={{ display: "flex", alignItems: "center", cursor: "pointer", color: GRAY_400 }}
          />
        </Popover>
      </Space>
      {patientDetails && patientDetails.employer && (
        <Space>
          <Text style={secondaryTextStyle}>{t("patientTopSection.employer")}:</Text>
          <ShorterTextWithTooltip text={patientDetails.employer} maxLength={15} textStyle={primaryTextStyle} />
        </Space>
      )}
      <PatientCoverageDetails />
    </Space>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

const PatientPersonalDetails = compose(withTranslation(), connect(mapStateToProps))(PatientPersonalDetailsComponent);

export { PatientPersonalDetails };
