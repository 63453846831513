import React, { useState, useEffect, useCallback } from "react";
import { CustomSider, SIDEBAR_WIDTH, ChatSiderMenuStyle } from "./Styles";
import { getUserRecentAIChatBotConversation as getUserRecentAIChatBotConversationAPI } from "../../api/api";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../hooks/fetch";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";

const MAX_RECENT_CONVERSATIONS = 6;

const ChatSider = ({
  collapsed,
  onCollapse,
  userId,
  activeConversationId,
  handleConversationChange,
  shouldRefreshTrigger
}) => {
  const [userRecentConversations, setUserRecentConversations] = useState(null);
  const { t } = useTranslation();

  const [getUserRecentConversations, userRecentConversationResult, , userRecentConversationError] = useFetch(
    getUserRecentAIChatBotConversationAPI
  );

  useEffect(() => {
    if ((!userRecentConversations && userId) || shouldRefreshTrigger) {
      getUserRecentConversations(userId, MAX_RECENT_CONVERSATIONS);
    }
  }, [userId, activeConversationId, shouldRefreshTrigger]);

  useEffect(() => {
    if (userRecentConversationError) {
      ErrorMessage(t("ai_chat_bot.errors.failed_fetching_recent_conversations"));
    }
    const recentConversations = userRecentConversationResult?.data;
    if (recentConversations) {
      const items = [
        {
          key: "recent",
          label: t("ai_chat_bot.recent_conversations"),
          type: "group",
          children: recentConversations.map((conversation) => ({
            key: conversation.id,
            label: conversation.topic || t("ai_chat_bot.new_chat_topic")
          }))
        }
      ];
      setUserRecentConversations(items);
    }
  }, [userRecentConversationResult, userRecentConversationError]);

  const handleMenuClick = useCallback(({ key }) => {
    handleConversationChange(key);
  });

  return (
    <CustomSider {...{ collapsed, onCollapse }} collapsedWidth={0} width={SIDEBAR_WIDTH}>
      <ChatSiderMenuStyle
        items={userRecentConversations}
        onClick={handleMenuClick}
        selectedKeys={[activeConversationId]}
      ></ChatSiderMenuStyle>
    </CustomSider>
  );
};

export { ChatSider };
