import { Empty, Space, Table, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { isNull } from "lodash";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { ApiNames } from "../../api/api";
import { ReactComponent as ClaimsIcon } from "../../assets/svg/claims-table-icon.svg";
import { BLACK } from "../../constant/colors";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { Routes } from "../../constant/routes";
import { fontWeights, sizes } from "../../constant/styles";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import ACTION from "../../store/action";
import { selectAllClaimsData, selectFiltersModified, selectIsFetching, selectTableColumns } from "../../store/selector";
import { dateFormatter } from "../../utils/date";
import { fetchTableData } from "../../utils/fetchTableData";
import { formatToDecimal } from "../../utils/formaters";
import { ColumnSelector } from "../ColumnSelector";
import { DeceasedLable } from "../DeceasedLable";
import { StyledGreenValue, StyledRedValue } from "../Patient/PatientApplications/PatientApplicationClaim/styles";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import LinkableCell from "../UI/LinkableCell";
import AllClaimsPageCount from "./ClaimsPageCount";
import {
  AssigneeColumn,
  ClaimMedicationsColumnComponent,
  ClaimStatusColumn,
  DateOfServiceColumn,
  PatientDOBColumn,
  PatientNameColumn,
  PatientPhoneNumberColumn,
  PatientSSNColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";

const { Text } = Typography;
const StyledEmptyText = styled(Text)`
  color: ${BLACK};
  opacity: 0.5;
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.medium_large};
`;

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
  .ant-empty-image {
    height: 47px;
    margin-bottom: 0px;
    svg {
      height: 47px;
      width: 73px;
    }
  }
`;

const patientPath = ({ patientId, journeyId, papId, applicationId, claimId }) =>
  `/patient/${patientId}/journey/${journeyId}/applications/${papId}/${applicationId}/${Routes.CLAIMS}/${claimId}/${Routes.EDIT_CLAIM}`;

const renderWithLink = (patientRec, renderFn) => <LinkableCell to={patientPath(patientRec)}>{renderFn()}</LinkableCell>;

const ClaimsTable = ({ updateSavedFilterViewParameters, isFilterListView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tableColumns = useSelector(selectTableColumns);
  const isFetching = useSelector(selectIsFetching([ApiNames.AllClaimsPhiSearch, ApiNames.AllClaimsSearch]));
  const tableData = useSelector(selectAllClaimsData);
  const filtersModified = useSelector(selectFiltersModified);
  const setTableDataAct = (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.allClaims, sorter));
  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;
  const formatAmount = (amount) => (!isNull(amount) ? `$${formatToDecimal(amount) || 0}` : "");

  const columns = [
    {
      ...tableColumns[selectedViews.allClaims].star,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].name_id, {
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => renderWithLink(record, () => <PatientNameColumn record={record} />)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => renderWithLink(record, () => (record.isDeceased ? <DeceasedLable /> : ""))
    }),
    {
      ...tableColumns[selectedViews.allClaims]?.phone_number,
      render: (text, record) => renderWithLink(record, () => <PatientPhoneNumberColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.allClaims]?.dob,
      render: (text, record) => renderWithLink(record, () => <PatientDOBColumn record={record} />)
    },

    {
      ...tableColumns[selectedViews.allClaims]?.ssn,
      render: (text, record) => renderWithLink(record, () => <PatientSSNColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimDate, {
      render: (text, record) =>
        renderWithLink(record, () => record.claimDate && <div>{dateFormatter(record.claimDate)}</div>)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimStatus, {
      render: (text, record) =>
        renderWithLink(record, () => record.claimStatus && <ClaimStatusColumn status={record.claimStatus} />)
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimFollowupDate, {
      render: (text, record) =>
        renderWithLink(record, () => record.claimFollowupDate && dateFormatter(record.claimFollowupDate))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimSubmittedDate, {
      render: (text, record) =>
        renderWithLink(record, () => record.claimSubmittedDate && dateFormatter(record.claimSubmittedDate))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].serviceDate, {
      render: (text, record) => renderWithLink(record, () => <DateOfServiceColumn record={record} />)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimFacility, {
      render: (text, record) => renderWithLink(record, () => record.claimProvider || "")
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].providedBy, {
      render: (_, record) => renderWithLink(record, () => record.providedBy)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].assistanceProgramName, {
      render: (_, record) => renderWithLink(record, () => record.assistanceProgramName)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].apType, {
      render: (_, record) => renderWithLink(record, () => record.apType)
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimTotalAmount, {
      render: (text, record) => renderWithLink(record, () => formatAmount(record.claimTotalAmount))
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimPaidAmount, {
      render: (text, record) =>
        renderWithLink(record, () => (
          <StyledGreenValue disableStyle={!record.claimPaidAmount}>
            {formatAmount(record.claimPaidAmount)}
          </StyledGreenValue>
        ))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].dateReceived, {
      render: (text, record) =>
        renderWithLink(record, () => record.claimReceivedDate && dateFormatter(record.claimReceivedDate))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].approvedDate, {
      render: (text, record) => renderWithLink(record, () => record.approvedDate && dateFormatter(record.approvedDate))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].claimDeniedAmount, {
      render: (text, record) =>
        renderWithLink(record, () => (
          <StyledRedValue disableStyle={!record.claimDeniedAmount}>
            {formatAmount(record.claimDeniedAmount)}
          </StyledRedValue>
        ))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].ClaimPatientResponsibilityAmount, {
      render: (text, record) =>
        renderWithLink(record, () => <div>{formatAmount(record.ClaimPatientResponsibilityAmount)}</div>)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims]?.balance, {
      render: (test, record) =>
        renderWithLink(record, () => <div>{!isNull(record.claimTotalAmount) && formatAmount(record.balance)}</div>)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].fundBalance, {
      render: (text, record) =>
        renderWithLink(record, () => <div>{`$${formatToDecimal(record.fundBalance) || 0}`}</div>)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].checkNumber_ACH, {
      render: (test, record) => renderWithLink(record, () => record.checkNumber || "")
    }),

    {
      ...tableColumns[selectedViews.allClaims].medications,
      render: (text, record) => renderWithLink(record, () => <ClaimMedicationsColumnComponent record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].facility, {
      render: (text, record) => renderWithLink(record, () => record.facility || "")
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].patientStatus, {
      render: (text, record) => renderWithLink(record, () => <PatientStatusCell value={record.status} />)
    }),
    {
      ...tableColumns[selectedViews.allClaims].assignee,
      render: (text, record) => renderWithLink(record, () => <AssigneeColumn record={record} />)
    },
    { ...tableColumns[selectedViews.allClaims].drug },
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].physician, {
      render: (text, record) => renderWithLink(record, () => record.physician || "")
    }),

    {
      ...tableColumns[selectedViews.allClaims].agreementSignatureDate,
      render: (text, record) =>
        renderWithLink(
          record,
          () => moment(record.agreement_signature_date).isValid() && dateFormatter(record.agreement_signature_date)
        )
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.followupDate, {
      render: (text, record) => renderWithLink(record, () => record.followupDate && dateFormatter(record.followupDate))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].primaryInsurancePlan, {
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.primary)(text, record))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].secondaryInsurancePlan, {
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.secondary)(text, record))
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allClaims].tertiaryInsurancePlan, {
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.tertiary)(text, record))
    })
  ];

  return (
    <>
      {!!tableData?.patients.length && (
        <ColumnSelectorContainer>
          <ColumnSelector />
        </ColumnSelectorContainer>
      )}
      <StyledTable
        size="small"
        rowClassName="cursor-pointer"
        isFilterListView={isFilterListView}
        style={{ width: "100%" }}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.allClaims]?.[col.key]?.display)}
        locale={{
          emptyText: (
            <Empty
              image={<ClaimsIcon />}
              description={
                <Space direction="vertical" size={0}>
                  <StyledEmptyText>
                    {!isFetching
                      ? filtersModified
                        ? t("patientApplications.claims_tab.no_claims_found")
                        : t("patientApplications.claims_tab.no_claims_yet")
                      : " "}
                  </StyledEmptyText>
                </Space>
              }
            ></Empty>
          )
        }}
        onChange={(pagination, filters, sorter) =>
          fetchTableData(pagination, sorter, tableData, setTableDataAct, updateSavedFilterViewParameters)
        }
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: tableData && tableData.selectedPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <AllClaimsPageCount total={total} range={range} />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients.map((claim, index) => {
                return {
                  ...claim,
                  key: index
                };
              })
            : null
        }
      />
    </>
  );
};

export const AllClaimsTable = ClaimsTable;
