import React, { Suspense, useEffect, useState } from "react";
import customFieldsRegistry from "./customFieldsRegistry";
import UnhandledType from "../OnlineWizard/fields/UnhandledType";

const CustomFieldEngine = ({ type, label, name, rules, placeholder, required, ...props }) => {
  const [selectedCustomField, setSelectedCustomField] = useState(null);

  useEffect(() => {
    if (customFieldsRegistry[type]) {
      setSelectedCustomField(customFieldsRegistry[type]);
    }
  }, [type]);

  return (
    <div>
      {selectedCustomField ? (
        <Suspense fallback={<div>Loading...</div>}>
          {React.createElement(selectedCustomField, { label, name, rules, placeholder, required, ...props })}
        </Suspense>
      ) : (
        <UnhandledType />
      )}
    </div>
  );
};

export default CustomFieldEngine;
