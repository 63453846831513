import styled from "styled-components";
import { Button, Layout, Typography, Menu } from "antd";

import {
  PRIMARY_100,
  PRIMARY_50,
  WHITE,
  BLUE_2,
  GRAY_90,
  GRAY_600,
  SEC_FONT_GREY,
  GRAY_100,
  DARK_BLACK,
  ERROR_100,
  BLACK
} from "../../constant/colors";
import { sizes } from "../../constant/styles";

const { Sider, Header, Content, Footer } = Layout;
const { Text } = Typography;
const HEADER_BUTTON_SIZE = "30px";
const SIDEBAR_WIDTH = "300px";
const HEADER_HIGHT = "70px";

const CollapsibleButton = styled(Button)`
  font-size: ${sizes.medium_large};
  size: ${HEADER_BUTTON_SIZE};
  background: ${PRIMARY_50};
  border-radius: 50%;

  &:hover {
    background: ${PRIMARY_100} !important;
  }

  &:disabled {
    opacity: 0.3;
  }

  :focus {
    background: ${PRIMARY_50};
  }

  :active {
    border: 3px solid ${BLUE_2} !important;
    background: ${PRIMARY_50} !important;
  }
  z-index: 100;
`;

const StartNewConversationButton = styled(Button)`
  font-size: ${sizes.medium_large};
  size: ${HEADER_BUTTON_SIZE};

  background: ${PRIMARY_50};
  border-radius: 100%;

  &:hover {
    background: ${PRIMARY_100} !important;
  }

  &:disabled {
    opacity: 0.3;
  }

  :focus {
    background: ${PRIMARY_50};
  }

  :active {
    border: 3px solid ${BLUE_2} !important;
    background: ${PRIMARY_50} !important;
  }
  z-index: 100;
`;

const CustomSider = styled(Sider)`
  background: ${WHITE};
  border: 1px;
  flex-direction: column;
  transition: width 0.3s ease;
  height: 100%;
  display: flex;
  flex-shrink: 0;

  ${({ collapsed }) => `
    border-right: ${collapsed ? "none" : `1px solid ${GRAY_90}`};
  `}
`;

const CustomLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ChatWidgetLayoutStyle = styled(Layout)`
  position: relative;

  ${({ collapsed }) => `
    top: ${collapsed ? "0px" : `-${HEADER_HIGHT}`};
    height: ${collapsed ? "100%" : `calc(100% + ${HEADER_HIGHT})`};
  `}
`;

const ChatContentStyle = styled(Content)`
  overflow: hidden;
  transition: width 0.3s ease, height 0.3s ease;
  width: 100%;
  position: relative;
  background: ${WHITE};
`;

const ChatHeaderStyle = styled(Header)`
  top: 0;
  z-index: 1;
  align-items: center;
  padding: 0 16px;
  background: ${WHITE};
  height: ${HEADER_HIGHT};
  flex-shrink: 0;
  ${({ collapsed }) => `
    border-right: ${collapsed ? "none" : `1px solid ${GRAY_90}`};
    border-bottom: ${collapsed ? "none" : `1px solid ${GRAY_90}`};
    width: ${collapsed ? "100%" : SIDEBAR_WIDTH};
  `}
`;

const ChatFooterStyle = styled(Footer)`
  padding: 0;
  padding-bottom: 2px;
  text-align: center;
  background: ${WHITE};
`;
const FooterTextStyle = styled(Text)`
  font-size: ${sizes.small};
  font-weight: 400;
  color: ${SEC_FONT_GREY};
  font-family: "Open Sans";
  text-align: center;
`;

const HeaderTextStyle = styled(Text)`
  font-size: ${sizes.large};
  font-weight: 700;
  color: ${GRAY_600};
  font-family: "Open Sans";
  line-height: 21px;
`;
const ChatSiderMenuStyle = styled(Menu)`
  .ant-menu-item-group-title {
    font-size: ${sizes.large};
    font-weight: 700;
    border-bottom: 1px solid ${GRAY_90};
    height: 50px;
    font-family: "Open Sans";
  }
  .ant-menu-item {
    font-size: ${sizes.medium};
    font-weight: 400;
    font-family: "Open Sans";
    color: ${DARK_BLACK};
    transition: background 0.3s, color 0.3s;
    border-radius: 12px;
    margin: 5px;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu-item-ligth,
  .ant-menu-item-selected {
    background: ${GRAY_100} !important;
    color: ${DARK_BLACK};
    border-radius: 12px;
  }
  .ant-menu-item:focus {
    background: ${GRAY_100};
    color: ${DARK_BLACK};
  }
`;

const DeepChatStyle = {
  border: "none",
  width: "100%",
  display: "grid",
  height: "100%"
};

const margin = "2%";
const DeepChatMessagesStyle = {
  default: {
    shared: {
      bubble: {
        maxWidth: "92%",
        marginTop: "10px",
        marginBottom: "10px",
        color: DARK_BLACK
      }
    },
    ai: { bubble: { backgroundColor: "unset", marginLeft: margin, padding: "0" } },
    user: { bubble: { backgroundColor: GRAY_100, marginRight: margin } }
  },
  error: {
    bubble: {
      backgroundColor: ERROR_100,
      color: DARK_BLACK,
      fontSize: "15px"
    }
  }
};

const DeepChatTextInputPlaceholderStyle = {
  color: SEC_FONT_GREY
};

const SuggestedQuestionsMenuStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "16px",
  borderBottom: "none",
  listStyle: "none",
  paddingLeft: "0px"
};
const SuggestedQuestionsMenuItemStyle = {
  width: "100%",
  height: "100px",
  borderRadius: "12px",
  border: "1px solid #5416BF0D",
  boxShadow: "0px 10px 40px 0px #5416BF0F",
  padding: "16px",
  fontWeight: "520",
  fontSize: "14px",
  fontFamily: "Open Sans",
  background: "#FFFFFF",
  cursor: "pointer",
  color: "#101828",
  letterSpacing: "0"
};
const SuggestedQuestionsMenuIteIconStyle = {
  size: "20px",
  display: "flex",
  justifyContent: "flex-start",
  paddingBottom: "10px"
};

const IntroPanelHelloTextStyle = {
  fontSize: "36px",
  fontWeight: "700",
  fontFamily: "Open Sans",
  lineHeight: "44px",
  letterSpacing: "-2%",
  color: BLACK
};
const IntroPanelHowCanIHelpTextStyle = {
  fontSize: "20px",
  fontWeight: "300",
  fontFamily: "Open Sans",
  color: BLACK,
  lineHeight: "30px",
  letterSpacing: "0"
};

const IntroPanelSuggestedQuestionTextStyle = {
  fontSize: "16x",
  fontWeight: "700",
  height: "20px",
  display: "flex",
  paddingLeft: "0",
  fontFamily: "Open Sans",
  color: "#5416BF"
};

export {
  DeepChatStyle,
  DeepChatMessagesStyle,
  DeepChatTextInputPlaceholderStyle,
  ChatSiderMenuStyle,
  SuggestedQuestionsMenuStyle,
  SuggestedQuestionsMenuIteIconStyle,
  SuggestedQuestionsMenuItemStyle,
  IntroPanelSuggestedQuestionTextStyle,
  IntroPanelHelloTextStyle,
  IntroPanelHowCanIHelpTextStyle,
  CustomSider,
  CollapsibleButton,
  StartNewConversationButton,
  CustomLayout,
  ChatHeaderStyle,
  SIDEBAR_WIDTH,
  ChatWidgetLayoutStyle,
  ChatContentStyle,
  ChatFooterStyle,
  FooterTextStyle,
  HeaderTextStyle
};
