import React from "react";
import moment from "moment-timezone";

import { getBmsCopayFormInitialValues } from "./form/initialValues";
import { SaveButton } from "../../header";
import useBaseEEConfiguration from "../../hooks/useBaseEEConfiguration";
import { applyTrimFields } from "../../utils/updateFormData";
import renderReactFromJson from "../../utils/renderReactFromJson";
import formJson from "./form/BmsCopayFormJson.json";
import { CompleteFormButton } from "../../CompleteFormButton";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../../constants";
import { SignerTypes } from "../../../../../constant/ESign";
import { DATE_FORMAT } from "../../../../../utils/date";

export const useBmsCopayConfiguration = () => {
  const vendorName = "bms";
  const eePlanType = "copay";
  const rootPath = "application_form_editor.bms.copay"; //EE TODO: add data to src/locales/translations/en/ApplicationFormEditor.js

  const baseConfig = useBaseEEConfiguration({ vendorName, eePlanType, rootPath, formJson });

  const bmsSpecificConfig = {
    activeFormSection: "",
    getFormInitialValues: ({ applicationForm }) => getBmsCopayFormInitialValues({ applicationForm, formJson }),
    getFormFromJson: (form) => renderReactFromJson(form, formJson),
    saveButton: (props) => (
      <SaveButton
        showPdfOnSave={false}
        onSave={(form) => applyTrimFields(form)}
        id={"EE_bms_copay_save_form"}
        {...props}
      />
    ),
    afterSubmissionPayload: () => ({}),
    successMessage: (t, submittedDate) =>
      t("patientApplications.enroll.application_form_submission.submit_completed_novartis", {
        submitDate: moment(submittedDate).format(DATE_FORMAT)
      }),
    applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_complete_button"} {...props} />,
    headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.COMPLETE_FORM,
    shouldShowPdfInSecondStep: true,
    getSignatureWidgetSettingsBySignerType: (signerType) => {
      const signatureSettings = {
        [SignerTypes.PATIENT]: {
          editable: true,
          titleTranslationKey: "patientApplications.enroll.electronic_signatures.signature_step.signers.patient",
          shouldShowCaregiverIndication: false,
          shouldUseRemoteRequestCheckboxes: true,
          shouldHaveSignatureAsTextLabel: true,
          shouldHideMissingInfoMessage: true,
          shouldHideLinkToMPD: true
        }
      };
      return signatureSettings[signerType];
    },
    submissionStep: {
      description: (t) => t("patientApplications.enroll.application_form_submission.eSign.description"),
      getSubmitButtonText: (t) => t("patientApplications.enroll.application_form_submission.submit_button"),
      submitModal: {
        showNotice: true,
        attachments: {
          showAllowedFileTypesNotice: true
        }
      },
      getSubmitSuccessMessage: (t) => t("patientApplications.enroll.application_form_submission.submit_success_message")
    },
    isModalSubmissionEnabled: false,
    formJson: formJson
  };

  return {
    ...baseConfig,
    ...bmsSpecificConfig
  };
};

export default useBmsCopayConfiguration;
