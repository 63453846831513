import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { starPatient } from "../../api/api";
import StarIconActiveYellow from "../../assets/svg/StarIconActiveYellow";
import StarIconInactive from "../../assets/svg/StarIconInactive";
import { selectPatientDetails } from "../../store/selector";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import ACTIONS from "../../store/action";

const PatientStarComponant = ({
  t,
  setPatientTableRecordDataAct,
  patientId,
  patientDetails,
  height = 12,
  width = 12
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isStarred, setIsStarred] = useState(false);

  useEffect(() => {
    setIsStarred(patientDetails?.starred);
  }, [patientDetails?.starred]);

  const handleStarPatient = async () => {
    setIsFetching(true);
    try {
      const response = await starPatient(patientId, !isStarred);
      if (response && response.data && response.data.success) {
        setPatientTableRecordDataAct(patientId, { starred: !isStarred });
        setIsStarred(!isStarred);
      } else {
        ErrorMessage(t("errorMessages.patient_starred"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.patient_starred"));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Button type="text" disabled={isFetching} onClick={handleStarPatient} style={{ padding: 0, height, width }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 6
        }}
        id="patient_star_icon_header"
      >
        {isStarred ? (
          <StarIconActiveYellow height={height} width={width} />
        ) : (
          <StarIconInactive height={height} width={width} />
        )}
      </div>
    </Button>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updatePatientDetailsAct: (patientDetails) => dispatch(ACTIONS.updatePatientDetailsAction(patientDetails)),
  setPatientTableRecordDataAct: (patientId, sharedFields) =>
    dispatch(ACTIONS.setPatientTableRecordData(patientId, sharedFields))
});

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

const PatientStar = compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PatientStarComponant);

export { PatientStar };
