import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Row as AntRow,
  Col as AntCol,
  Select as AntSelect,
  Form,
  Input as AntInput,
  Typography,
  Popover,
  Radio,
  DatePicker as AntdDatePicker,
  Divider as AntDivider,
  Space,
  Switch,
  Collapse,
  InputNumber
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { font14pxSB, font18pxSB } from "../Typography";
import styled from "styled-components";
import _ from "lodash";
import { createStructuredSelector } from "reselect";

import { ManagePatientDetailsContext } from "./context";
import { FormFields } from "./FormFields";
import { SINGLE, MARRIED, SEPARATED, DIVORCED, WIDOWED } from "../../constant/patient";
import { PopupStyledModal } from "../customComponentNewDesign/CustomPopupModal";
import { ReactComponent as IncomeBreakdownWarningIcon } from "../../assets/svg/IncomeBreakdownWarningIcon.svg";
import { OutlinedButton, PrimaryButton } from "../customComponent/Button";
import { BLACK, GRAY_400 } from "../../constant/colors";
import { SingleSelect } from "../customComponentNewDesign/CustomSelect";
import { DATE_FORMAT } from "../../utils/date";
import { clearObjectValues, parseToFloatNumberWithTwoDigits, workSheetRows } from "./financialUtils";
import { numberWithCommas } from "../../utils/string";
import { selectPatientDetails } from "../../store/selector";
import { addCommasToNumer, cleanNumberFromCommas, stripStringToNumber } from "./financialUtils";
import { DemographicConflictFormItem } from "./DemographicConflictFormItem";
import { handleRadioGroupKeyDown } from "../../screen/ManagePatientDetails";
import { NO, UNKNOWN, YES } from "../../constant/managePatientDetails";

const { Text } = Typography;
const { Option } = AntSelect;
const { Panel } = Collapse;
const FormItem = styled(Form.Item)``;

const Row = styled(AntRow)``;

const StyledSwitch = styled(Switch)`
  margin-right: 8px;
  &.ant-switch {
    background: #f2f4f7 !important;
  }

  &.ant-switch-checked {
    background: #1570ef !important;
  }
`;

const RadioButtonStyled = styled(Radio.Button)`
  display: flex;
  flex: 1;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: black;
`;

const Col = styled(AntCol)`
  &.ant-col-12:nth-child(1) {
    padding-right: 8px;
  }
  &.ant-col-12:nth-child(2) {
    padding-left: 8px;
  }
`;

const Divider = styled(AntDivider)`
  margin: 16px 0;
`;

const CardWrapper = styled.div`
  margin: 16px 0;
`;

const CardTitle = styled.span`
  ${font14pxSB}
  display: block;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const CardBody = styled.div`
  padding: 24px 24px 0px 24px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
`;

const Input = styled(AntInput)`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px !important;
`;

const DatePicker = styled(AntdDatePicker)`
  padding-right: 8px;
  width: 100%;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
`;

const CustomInput = styled(Input)`
  .ant-input {
    height: 40px;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
  }
  .ant-input-group-addon {
    color: #98a2b3;
    background: #ffffff;
    border-radius: 4px 0px 0px 4px;
  }
`;

const CustomInputNumber = styled(InputNumber)`
  height: 40px;
  width: 100%;
  border-radius: 4px;

  .ant-input-number-group-addon {
    color: #98a2b3;
    background: #ffffff;
    border-radius: 4px 0px 0px 4px;
  }
`;

const RowSubTitle = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`;

const SytledCollapse = styled(Collapse)`
  .ant-collapse-extra {
    background: "red";
  }
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-extra {
    margin-left: 0px !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
`;

export const DialogTitle = styled.p`
  ${font18pxSB}
`;

const CustomIncomeText = ({ label, data }) => {
  return (
    <div
      style={{
        border: "1px solid #d0d5dd",
        borderRadius: "4px",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        margin: "5px"
      }}
    >
      <Text style={{ padding: "10px 10px 0px 10px" }}>{label}</Text>
      <Text style={{ padding: "10px 10px 10px 10px", fontSize: "24px", fontWeight: "600" }}>
        $ {numberWithCommas(data)}
      </Text>
    </div>
  );
};

const CustomFplText = ({ label, popoverLabel, data }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        border: "1px solid #d0d5dd",
        borderRadius: "4px",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        margin: "5px"
      }}
    >
      <AntRow
        style={{
          display: "flex",
          flexDirection: "row"
        }}
      >
        <Text style={{ padding: "10px 0px 0px 10px" }}>% {label}</Text>
        <Popover
          trigger="hover"
          content={
            <div style={{ padding: "8px 12px 8px 12px", borderRadius: "8px", width: "262px", fontSize: "12px" }}>
              <Text strong>{t("patientTopSection.fpl")}</Text>
              <Text>{popoverLabel}</Text>
            </div>
          }
        >
          <QuestionCircleOutlined
            style={{ padding: "14px 0px 0px 5px", fontSize: "13px", color: GRAY_400, cursor: "pointer" }}
          />
        </Popover>
      </AntRow>
      <Text style={{ padding: "10px 10px 10px 10px", fontSize: "24px", fontWeight: "600" }}>{data}</Text>
    </div>
  );
};

const Card = ({ title, children }) => {
  return (
    <CardWrapper>
      <CardTitle>{title}</CardTitle>
      <CardBody>{children}</CardBody>
    </CardWrapper>
  );
};

const sumAllWorkSheetValues = (allFields) => {
  const allMonthlyFields = _.pickBy(allFields, (value, key) => _.some(["Monthly"], (str) => _.includes(key, str)));
  const allAnnualFields = _.pickBy(allFields, (value, key) => _.some(["Annual"], (str) => _.includes(key, str)));

  const cleanedMonthlyValues = clearObjectValues(allMonthlyFields);
  const cleanedAnnualValues = clearObjectValues(allAnnualFields);

  if (cleanedMonthlyValues.length == 0 || cleanedAnnualValues.length == 0) return 0;
  const sumMonthly = parseToFloatNumberWithTwoDigits(cleanedMonthlyValues.reduce((partialSum, a) => partialSum + a, 0));
  const sumAnnual = parseToFloatNumberWithTwoDigits(cleanedAnnualValues.reduce((partialSum, a) => partialSum + a, 0));

  return { monthly: sumMonthly, annual: sumAnnual };
};

const updateWorkSheetRowInputValue = (
  { sheetForm, fieldNameMonthly, fieldNameAnnual, setTotalIncome, newValue },
  isAnnualUpdate
) => {
  let monthlyNewValue = parseToFloatNumberWithTwoDigits(newValue);
  let annualNewValue = parseToFloatNumberWithTwoDigits(newValue);
  if (isAnnualUpdate) {
    monthlyNewValue = parseToFloatNumberWithTwoDigits(monthlyNewValue / 12);
  } else {
    annualNewValue = parseToFloatNumberWithTwoDigits(annualNewValue * 12);
  }
  sheetForm.setFieldsValue({
    [fieldNameMonthly]: `${monthlyNewValue || "0"}`,
    [fieldNameAnnual]: `${annualNewValue || "0"}`
  });
  setTotalIncome(
    sumAllWorkSheetValues(
      _.pick(
        sheetForm.getFieldsValue(),
        _.union(
          workSheetRows.map((field) => field.fieldName + "Monthly"),
          workSheetRows.map((field) => field.fieldName + "Annual")
        )
      )
    )
  );
};

const getTotalInputValue = (newValue, isAnnualChange) => {
  const dirtyNewValue = newValue ?? "";
  let monthlyNewValue = stripStringToNumber(dirtyNewValue);
  let annualNewValue = stripStringToNumber(dirtyNewValue);
  if (isAnnualChange) {
    monthlyNewValue = parseToFloatNumberWithTwoDigits(monthlyNewValue / 12);
  } else {
    annualNewValue = parseToFloatNumberWithTwoDigits(annualNewValue * 12);
  }

  return {
    monthly: monthlyNewValue,
    annual: annualNewValue
  };
};

const WorkSheetRow = ({ rowLabel, fieldNameMonthly, fieldNameAnnual, setTotalIncome }) => {
  const { t } = useTranslation();
  const sheetForm = Form.useFormInstance();
  const updateFromAnnualInput = (newValue) =>
    updateWorkSheetRowInputValue({ sheetForm, fieldNameMonthly, fieldNameAnnual, setTotalIncome, newValue }, true);
  const updateFromMonthlyInput = (newValue) =>
    updateWorkSheetRowInputValue({ sheetForm, fieldNameMonthly, fieldNameAnnual, setTotalIncome, newValue }, false);
  return (
    <Col>
      <Row style={{ marginBottom: "8px" }}>
        <RowSubTitle>{t(rowLabel.toString())}</RowSubTitle>
      </Row>
      <Row>
        <Col span={12}>
          <FormItem name={fieldNameMonthly.toString()}>
            <CustomInputNumber
              addonBefore="Monthly"
              formatter={(value) => addCommasToNumer(value)}
              parser={(value) => cleanNumberFromCommas(value)}
              onChange={(value) => updateFromMonthlyInput(value)}
              min={0}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={fieldNameAnnual.toString()}>
            <CustomInputNumber
              addonBefore="Annual"
              formatter={(value) => addCommasToNumer(value)}
              parser={(value) => cleanNumberFromCommas(value)}
              onChange={(value) => updateFromAnnualInput(value)}
              min={0}
            />
          </FormItem>
        </Col>
      </Row>
    </Col>
  );
};

const ManagePatientDetailsFinancialComponent = ({ patientDetails }) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const { calculateFPL } = useContext(ManagePatientDetailsContext);
  const [localFpl, setLocalFpl] = useState();
  const [totalIncome, setTotalIncome] = useState({ monthly: 0, annual: 0 });
  const [worksheetEditingStatus, setWorksheetEditingStatus] = useState(false);
  const [showBreakdownModal, setShowBreakdownModal] = useState(false);

  const radioRefs = useRef({});

  Form.useWatch([], form);

  useEffect(() => {
    if (patientDetails) {
      setLocalFpl(patientDetails.fpl);
    }
  }, [patientDetails]);

  const calculateLocalFPL = () => {
    const calculatedFPL = calculateFPL(
      getAnnuallIncome(),
      calculateTotalHouseholdSize(
        form.getFieldValue(FormFields.householdSizeAdults),
        form.getFieldValue(FormFields.householdSizeChildren)
      )
    );
    setLocalFpl(calculatedFPL);
  };

  const breakdownModalVerification = () => {
    const worksheetTotal = sumAllWorkSheetValues(
      _.pick(
        form.getFieldsValue(),
        _.union(
          workSheetRows.map((field) => field.fieldName + "Monthly"),
          workSheetRows.map((field) => field.fieldName + "Annual")
        )
      )
    );

    const { totalIncome, totalMonthlyIncome } = form.getFieldsValue(["totalIncome", "totalMonthlyIncome"]);

    const isWorksheetValueNotEqualToTotalMonthly = worksheetTotal.monthly != totalMonthlyIncome;
    const isWorksheetValueNotEqualToTotalAnnual = worksheetTotal.annual != totalIncome;

    return isWorksheetValueNotEqualToTotalMonthly || isWorksheetValueNotEqualToTotalAnnual;
  };

  const updateTotalsOnBreakdown = (checkedValue) => {
    setWorksheetEditingStatus(checkedValue);
    setTotalIncome(
      sumAllWorkSheetValues(
        _.pick(
          form.getFieldsValue(),
          _.union(
            workSheetRows.map((field) => field.fieldName + "Monthly"),
            workSheetRows.map((field) => field.fieldName + "Annual")
          )
        )
      )
    );
  };

  const handleWorksheetToggle = (checkedValue) => {
    if (checkedValue && breakdownModalVerification()) {
      setShowBreakdownModal(true);
    } else {
      updateTotalsOnBreakdown(checkedValue);
    }
  };

  const handleOkButton = () => {
    setShowBreakdownModal(false);
    updateTotalsOnBreakdown(true);
  };

  const calculateTotalHouseholdSize = (householdSizeAdults, householdSizeChildren) =>
    Number(householdSizeAdults ?? 0) + Number(householdSizeChildren ?? 0);

  const getAnnuallIncome = () => form.getFieldValue(FormFields.totalIncome);

  useEffect(() => {
    const { monthly, annual } = totalIncome;
    form.setFieldsValue({
      [FormFields.totalIncome]: _.isNil(annual) ? "" : annual,
      [FormFields.totalMonthlyIncome]: _.isNil(monthly) ? "" : monthly
    });
    calculateLocalFPL();
  }, [totalIncome]);

  const updateIncome = (newValue, isAnnualChange) => {
    const { monthly, annual } = getTotalInputValue(newValue, isAnnualChange);

    form.setFieldsValue({
      [FormFields.totalIncome]: _.isNil(annual) ? "" : annual,
      [FormFields.totalMonthlyIncome]: _.isNil(monthly) ? "" : monthly
    });
    setTotalIncome({ monthly, annual });
  };

  const handleKeyDown = (e, label) => {
    if (e.key === "Enter") {
      form.setFieldsValue({ [FormFields.filesTaxes]: label });
    }
    if (label === NO) return;
    handleRadioGroupKeyDown(e, label, radioRefs);
  };

  return (
    <>
      <PopupStyledModal
        title={<IncomeBreakdownWarningIcon />}
        open={showBreakdownModal}
        onCancel={() => setShowBreakdownModal(false)}
        footer={[
          <OutlinedButton key="back" onClick={() => setShowBreakdownModal(false)} id="discard_changes_cancel">
            {t("managePatientDetails.cancel_discard")}
          </OutlinedButton>,
          <PrimaryButton key="ok" onClick={handleOkButton} id="discard_changes_ok">
            {t("managePatientDetails.income_breakdown_modal_approve")}
          </PrimaryButton>
        ]}
      >
        <DialogTitle>{t("managePatientDetails.income_breakdown_modal_title")}</DialogTitle>
        {t("managePatientDetails.income_breakdown_modal_body")}
      </PopupStyledModal>
      <Divider />
      <Card title="General">
        <Row justify="space-between">
          <Col span={12} id="manage-patient-details-financial-general">
            <FormItem name={FormFields.incomeVerifiedDate} label={t("managePatientDetails.income_verification_date")}>
              <DatePicker placeholder={DATE_FORMAT} format={[DATE_FORMAT]} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name={FormFields.filesTaxes} label={t("managePatientDetails.files_taxes")}>
              <Radio.Group style={{ display: "flex", flex: 1, color: BLACK }}>
                <RadioButtonStyled
                  style={{ borderRadius: "4px 0px 0px 4px", justifyContent: "center" }}
                  value={UNKNOWN}
                  ref={(el) => (radioRefs.current[UNKNOWN] = el)}
                  onKeyDown={(e) => handleKeyDown(e, UNKNOWN)}
                >
                  {t("unknown")}
                </RadioButtonStyled>
                <RadioButtonStyled
                  style={{ justifyContent: "center" }}
                  value={YES}
                  ref={(el) => (radioRefs.current[YES] = el)}
                  onKeyDown={(e) => handleKeyDown(e, YES)}
                >
                  {t("yes")}
                </RadioButtonStyled>
                <RadioButtonStyled
                  style={{ borderRadius: "0px 4px 4px 0px", justifyContent: "center" }}
                  value={NO}
                  ref={(el) => (radioRefs.current[NO] = el)}
                  onKeyDown={(e) => handleKeyDown(e, NO)}
                >
                  {t("no")}
                </RadioButtonStyled>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={12}>
            <FormItem name={FormFields.proofOfIncome} label={t("managePatientDetails.proof_of_income_information")}>
              <CustomInput placeholder={t("managePatientDetails.proof_of_income_information_placeholder")} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name={FormFields.agreementSignatureDate} label={t("managePatientDetails.patient_consent_date")}>
              <DatePicker placeholder={DATE_FORMAT} format={[DATE_FORMAT]} />
            </FormItem>
          </Col>
        </Row>
      </Card>
      <Card title="Income">
        <Row
          justify="space-between"
          style={{ display: "flex", flex: "1" }}
          id="manage-patient-details-financial-income"
        >
          <CustomIncomeText
            label={t("managePatientDetails.monthly_income")}
            data={form.getFieldValue(FormFields.totalMonthlyIncome)}
          />
          <CustomIncomeText
            label={t("managePatientDetails.annual_income")}
            data={form.getFieldValue(FormFields.totalIncome)}
          />
          <CustomFplText
            label={t("managePatientDetails.fpl")}
            popoverLabel={t("managePatientDetails.fpl_is_calculated")}
            data={
              localFpl !== null &&
              localFpl !== undefined &&
              form.getFieldValue(FormFields.totalIncome) &&
              (parseInt(form.getFieldValue(FormFields.householdSizeAdults)) > 0 ||
                parseInt(form.getFieldValue(FormFields.householdSizeChildren)) > 0)
                ? `${parseInt(localFpl)}%`
                : "--"
            }
          />
        </Row>
        <Divider />
        <Row justify="space-between">
          <Col span={12}>
            <DemographicConflictFormItem
              label={t("managePatientDetails.marital_status")}
              name={FormFields.maritalStatus}
            >
              <SingleSelect width="316" placeholder={t("managePatientDetails.marital_status_placeholder")}>
                <Option value={SINGLE}>{t("managePatientDetails.single")}</Option>
                <Option value={MARRIED}>{t("managePatientDetails.married")}</Option>
                <Option value={SEPARATED}>{t("managePatientDetails.separated")}</Option>
                <Option value={DIVORCED}>{t("managePatientDetails.divorced")}</Option>
                <Option value={WIDOWED}>{t("managePatientDetails.widowed")}</Option>
              </SingleSelect>
            </DemographicConflictFormItem>
          </Col>
          <Col span={12} style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <Space>
              <FormItem label={t("managePatientDetails.household_Size")} name={FormFields.householdSizeAdults}>
                <CustomInput min={0} onChange={calculateLocalFPL} addonBefore={t("managePatientDetails.adults")} />
              </FormItem>
              <FormItem label=" " name={FormFields.householdSizeChildren}>
                <CustomInput
                  min={0}
                  onChange={calculateLocalFPL}
                  addonBefore={t("managePatientDetails.children")}
                  style={{ display: "flex", flex: 1 }}
                />
              </FormItem>
            </Space>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={12}>
            <FormItem label={t("managePatientDetails.monthly_household_income")} name={FormFields.totalMonthlyIncome}>
              <CustomInputNumber
                formatter={(value) => addCommasToNumer(value)}
                parser={(value) => cleanNumberFromCommas(value)}
                onChange={(value) => updateIncome(value, false)}
                min={0}
                disabled={worksheetEditingStatus}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={t("managePatientDetails.annual_household_income")} name={FormFields.totalIncome}>
              <CustomInputNumber
                formatter={(value) => addCommasToNumer(value)}
                parser={(value) => cleanNumberFromCommas(value)}
                onChange={(value) => updateIncome(value, true)}
                min={0}
                disabled={worksheetEditingStatus}
              />
            </FormItem>
          </Col>
        </Row>
        <SytledCollapse ghost activeKey={worksheetEditingStatus ? "1" : null}>
          <StyledPanel
            forceRender
            key="1"
            showArrow={false}
            extra={
              <>
                <StyledSwitch
                  checked={worksheetEditingStatus}
                  onChange={(checkedValue) => handleWorksheetToggle(checkedValue)}
                />
                <Text onClick={() => handleWorksheetToggle(!worksheetEditingStatus)}>Income Breakdown</Text>
              </>
            }
          >
            {workSheetRows.map((sheetRow) => (
              <WorkSheetRow
                key={sheetRow.rowLabel}
                rowLabel={sheetRow.rowLabel}
                fieldNameAnnual={sheetRow.fieldName + "Annual"}
                fieldNameMonthly={sheetRow.fieldName + "Monthly"}
                setTotalIncome={setTotalIncome}
              />
            ))}
          </StyledPanel>
        </SytledCollapse>
      </Card>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

const ManagePatientDetailsFinancial = connect(mapStateToProps)(ManagePatientDetailsFinancialComponent);

export default ManagePatientDetailsFinancial;
