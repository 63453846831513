import { Button, Space, Table, Typography, Image, Skeleton, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams, withRouter } from "react-router";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useTranslation, withTranslation } from "react-i18next";

import { getNotificationDataAPI, getTopOpportunityPatientsAPI } from "./../api/api";
import { Routes } from "../constant/routes";
import { dateFormatter } from "../utils/date";
import { PAGE_SIZE, selectedViews } from "../constant/table";
import {
  AssigneeColumn,
  PatientDOBColumn,
  EditColumn,
  PatientNameColumn,
  PatientSSNColumn,
  StarColumn,
  PatientPhoneNumberColumn
} from "./TableSection/CommonColumns";
import { GRAY_1600, GRAY_400, GRAY_500, GRAY_700, WHITE } from "../constant/colors";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";

import { selectTableColumns, selectRelevantPatientData, selectedViewTableData } from "../store/selector";
import styled from "styled-components";
import { patientInsuranceCell, PatientStatusCell } from "./TableCells";
import { ColumnSelector } from "../component/ColumnSelector";
import ACTIONS from "../store/action";
import { SUPPORTED_COVERAGES } from "../constant/patient";
import PatientPageCount from "../component/TableSection/PatientPageCount";
import { ColumnSelectorContainer, commonTableStyle } from "../component/TableSection/TableStyle";
import moment from "moment-timezone";
import { DeceasedLable } from "./DeceasedLable";
import { useGetSetting } from "../hooks/getSetting";
import { fontWeights, sizes } from "../constant/styles";
import { ASCEND, DECEND, desiredOrderOfStatuses, SORT_DIRECTIONS_DEFAULT } from "../constant/sort";
import { getSortingChanges, sortListByOrder } from "../utils/sort";
import { TitledTooltip } from "./TitledTooltip";
import TableFilters from "./TableFilters";
import {
  getRelevantPatientsTableConfigFromLocalStorage,
  OOP_MET_STATUS,
  saveRelevantPatientsTableConfigToLocalStorage
} from "../constant/relevantPatients";
import ArrowBack from "../assets/svg/ArrowBack";
import EmptyTableMessage from "./TableSection/EmptyTableMessage";
import { scrollTableToTop } from "../utils/fetchTableData";
import LinkableCell from "./UI/LinkableCell";

const { Text } = Typography;

const StyledTable = styled(Table)`
  ${commonTableStyle()}
  margin-top: 0px;

  .ant-table {
    max-height: ${(props) => {
      if (props.filtersVisible) {
        return "calc(100vh - 290px)";
      }
      return "calc(100vh - 310px)";
    }};
  }

  .ant-table-empty {
    .ant-table-tbody {
      height: calc(100vh - 350px);
    }
  }
`;

const ContainerStyle = styled.div`
  margin: 0px 15px 0px 15px;
  position: relative;
  background: ${WHITE};
  border-radius: 10px;
  padding: 10px 15px 0px 15px;
`;

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
`;

const BackButtonStyle = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0px;
`;

const AssistanceProgramNameStyle = styled(Text)`
  display: block;
  font-size: ${sizes.medium_xlarge};
  font-weight: ${fontWeights.semibold};
  color: ${GRAY_1600};
`;

const AssistanceProgramStatusStyle = styled(Text)`
  display: block;
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.regular};
  color: ${GRAY_500};
`;

const ImageStyle = styled(Image)`
  margin: 12px 20px 12px 20px;
  width: 142px;
`;

const ContentStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: all 0.3s ease;
  margin-top: ${(props) => {
    if (props.filtersVisible) {
      return "0px";
    }
    return "10px";
  }};
`;

const TopOpportunitiesComponent = ({
  t,
  history,
  setSelectedView,
  tableColumns,
  setTableDataAct,
  tableData,
  clearSelectedView
}) => {
  const dispatch = useDispatch();

  const relevantPatientsTableConfig = getRelevantPatientsTableConfigFromLocalStorage();
  const relevantPatientsSorter = relevantPatientsTableConfig?.sorter || {};
  const relevantPatientsFilters = relevantPatientsTableConfig?.filters || {};
  const relevantPatientsCurrentPage = relevantPatientsTableConfig?.currentPage || {};
  const relevantPatientsId = relevantPatientsTableConfig?.id && parseInt(relevantPatientsTableConfig.id);

  const [isFetching, setIsFetching] = useState([]);
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const { notificationId } = useParams();
  const [notification, setNotification] = useState(null);
  const [filteredStatuses, setFilteredStatuses] = useState([]);

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const [statuses] = useGetSetting(["statuses"]);
  const filteredTableDate = tableData?.patients?.filter(({ status }) => status != OOP_MET_STATUS) || [];

  const shouldReturnToRelevantPatientAct = (shouldReturn, notificationId) =>
    dispatch(ACTIONS.setShouldReturnToRelevantPatient(shouldReturn, notificationId));

  useEffect(() => {
    if (!statuses.length) {
      setFilteredStatuses([]);
      return;
    }
    const orderedStatuses = sortListByOrder(statuses, desiredOrderOfStatuses, "value");
    if (JSON.stringify(filteredStatuses) !== JSON.stringify(orderedStatuses)) {
      setFilteredStatuses(orderedStatuses);
    }
  }, [filteredStatuses]);

  useEffect(() => {
    setSelectedView(selectedViews.topOpportunity);
  }, []);

  useEffect(() => {
    if (
      tableData?.patients?.length === 0 &&
      tableData?.totalPatients % PAGE_SIZE === 0 &&
      relevantPatientsCurrentPage > 1
    ) {
      fetchTopOpportunityPatients(null, null, relevantPatientsSorter, { current: relevantPatientsCurrentPage - 1 });
    }
  }, [tableData]);

  useEffect(() => {
    const fetchData = async () => {
      const notificationDate = await fetchNotificationData();

      if (notificationDate) {
        const assistanceProgramId = notificationDate.assistanceProgram.id;
        //Note: Reset the page number to 1 when navigating to a different relevant patient
        const shouldResetPage = relevantPatientsId !== 0 && relevantPatientsId !== parseInt(notificationId);

        const newFilters = {
          ...relevantPatientsFilters,
          assistancePrograms: [{ value: assistanceProgramId }]
        };

        const currentPage = shouldResetPage ? 1 : relevantPatientsCurrentPage;

        saveRelevantPatientsTableConfigToLocalStorage({
          ...relevantPatientsTableConfig,
          currentPage,
          filters: newFilters
        });

        await fetchTopOpportunityPatients(assistanceProgramId, null, relevantPatientsSorter, {
          current: currentPage
        });
      }
    };

    fetchData();
  }, [notificationId]);

  const fetchNotificationData = async () => {
    let notificationData = null;
    const { state } = history.location || {};
    const { notification, id } = state || {};

    if (notification && id) {
      notificationData = history.location.state.notification;
    } else {
      try {
        setIsFetching(true);
        const notificationDataRes = await getNotificationDataAPI(notificationId);
        if (notificationDataRes && notificationDataRes.data) {
          notificationData = notificationDataRes.data;
        } else {
          ErrorMessage(t("top_opportunities.failed_to_get_notification_data"));
        }
      } catch (error) {
        ErrorMessage(t("top_opportunities.failed_to_get_notification_data"));
      } finally {
        setIsFetching(false);
      }
    }

    if (notificationData) {
      setNotification(notificationData);
    }

    return notificationData;
  };

  const fetchData = (pagination, filters, sorter) => {
    const { current: nextPage } = pagination;

    if (nextPage === relevantPatientsCurrentPage) {
      const { columnIsChanged, sortDirectionIsChanged, nextColumnName, nextSortBy } = getSortingChanges(
        relevantPatientsSorter,
        sorter
      );

      if (columnIsChanged || sortDirectionIsChanged) {
        saveRelevantPatientsTableConfigToLocalStorage({
          ...relevantPatientsTableConfig,
          sorter: { ...sorter, columnKey: nextColumnName, order: nextSortBy },
          currentPage: relevantPatientsCurrentPage === 1 ? pagination.current : 1
        });
        fetchTopOpportunityPatients(
          null,
          null,
          sorter,
          relevantPatientsCurrentPage === 1 ? pagination : { current: 1 }
        );
      }
    } else {
      fetchTopOpportunityPatients(null, null, sorter, pagination);
    }
    scrollTableToTop();
  };

  const fetchTopOpportunityPatients = async (
    assistanceProgramId,
    filters,
    sorter = relevantPatientsSorter,
    pagination
  ) => {
    const finalSorter = sorter.column ? sorter : {};
    const parsedFilters = filters && _.omitBy(filters, _.isNull);
    const filtersForAPIRequest = parsedFilters
      ? parsedFilters
      : {
          ..._.omitBy(relevantPatientsFilters, _.isNull),
          assistancePrograms: [
            { value: assistanceProgramId ? assistanceProgramId : notification?.assistanceProgram.id }
          ]
        };
    setIsFetching(true);
    try {
      const parsedFilters = {
        multiples: filtersForAPIRequest,
        isUnassigned: filtersForAPIRequest.isUnassigned
      };

      const topOpportunityPatientsRes = await getTopOpportunityPatientsAPI(
        parsedFilters,
        finalSorter,
        pagination?.current
      );
      if (topOpportunityPatientsRes && topOpportunityPatientsRes.data && topOpportunityPatientsRes.data.patients) {
        setTableDataAct(topOpportunityPatientsRes.data, finalSorter);
      } else {
        ErrorMessage(t("errorMessages.top_opportunity_patients"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.top_opportunity_patients"));
    } finally {
      setIsFetching(false);
    }
  };

  const renderWithLink = ({ patientId, journeyId }, renderFn) => (
    <LinkableCell
      to={Routes.ROOT + `patient/${patientId}/journey/${journeyId}`}
      onClick={() => shouldReturnToRelevantPatientAct(true, notificationId)}
    >
      {renderFn()}
    </LinkableCell>
  );

  const handleFetchFilter = (filters) => {
    fetchTopOpportunityPatients(null, filters);
  };

  const withSorting = (sortingEnabled, tableColumn, { sorterTooltipText, ...rest }, sortDirection) => {
    const [threshold] = useGetSetting(["dashboard.sorting-threshold"]);
    const { t } = useTranslation();
    const selectedViewData = useSelector(selectedViewTableData);
    const { sorter } = selectedViewData;
    const sortingTooltipTitle = threshold && t("sortInfo", { threshold: threshold / 1000 });

    const sortingActionTooltip =
      sorterTooltipText ||
      (() => {
        if (sorter?.column?.key === tableColumn.key) {
          if (sorter?.order === DECEND) {
            return t("columns.click_to_cancel_sorting");
          }
          if (sorter?.order === ASCEND) {
            return t("columns.click_to_sort_descending");
          }
        }
        return t("columns.click_to_sort_ascending");
      })();

    return {
      sorter: sortingEnabled,
      sortDirections: sortDirection ? sortDirection : SORT_DIRECTIONS_DEFAULT,
      ...tableColumn,
      title: () => (
        <div style={{ display: "flex", gap: "5px" }} id={`columnـ${tableColumn.dataIndex}`}>
          <Tooltip title={sortingActionTooltip}>
            <Text style={{ color: GRAY_700 }}>{tableColumn.title}</Text>
          </Tooltip>
          <div>
            {!sortingEnabled && (
              <TitledTooltip text={sortingTooltipTitle}>
                <ExclamationCircleOutlined style={{ fontSize: "12px", alignSelf: "center", color: GRAY_400 }} />
              </TitledTooltip>
            )}
          </div>
        </div>
      ),
      showSorterTooltip: false,
      ...rest,
      sortOrder: sorter?.column && sorter?.column?.key === tableColumn.key && sorter?.order
    };
  };

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.topOpportunity]?.star?.dataIndex,
      key: tableColumns[selectedViews.topOpportunity]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity].name_id, {
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => renderWithLink(record, () => <PatientNameColumn record={record} />)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.isDeceased, {
      render: (_, record) => renderWithLink(record, () => (record.isDeceased ? <DeceasedLable /> : ""))
    }),
    {
      ...tableColumns[selectedViews.topOpportunity]?.phone_number,
      render: (text, record) => renderWithLink(record, () => <PatientPhoneNumberColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.dob,
      render: (text, record) => renderWithLink(record, () => <PatientDOBColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.ssn,
      render: (text, record) => renderWithLink(record, () => <PatientSSNColumn record={record} />)
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.primaryDiagnosis,
      dataIndex: "diagnosises",
      render: (text, record) => renderWithLink(record, () => (record.diagnosises[0] ? record.diagnosises[0] : null))
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.assignee,
      render: (text, record) => renderWithLink(record, () => <AssigneeColumn record={record} />)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.facility, {
      render: (text, record) => renderWithLink(record, () => text)
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.physician, {
      render: (text, record) => renderWithLink(record, () => text)
    }),

    {
      ...tableColumns[selectedViews.topOpportunity]?.primaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.primary)(text, record))
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.secondaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.secondary)(text, record))
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.tertiaryInsurancePlan,
      render: (text, record) =>
        renderWithLink(record, () => patientInsuranceCell(SUPPORTED_COVERAGES.tertiary)(text, record))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.applicationStatus, {
      render: (text, record) => renderWithLink(record, () => text)
    }),
    {
      ...tableColumns[selectedViews.topOpportunity]?.agreementSignatureDate,
      render: (text, record) =>
        renderWithLink(record, () => record.agreement_signature_date && dateFormatter(record.agreement_signature_date))
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.patientStatus, {
      render: (_, record) => renderWithLink(record, () => <PatientStatusCell value={record.status} />)
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.followupDate, {
      render: (text, record) => renderWithLink(record, () => record.followupDate && dateFormatter(record.followupDate))
    }),
    {
      dataIndex: tableColumns[selectedViews.topOpportunity]?.edit?.dataIndex,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.creationDate, {
      render: (text, record) => renderWithLink(record, () => moment(record.creationDate).format("MMM D, YYYY"))
    })
  ];

  return (
    <ContainerStyle>
      <HeaderStyle>
        <Space direction="vertical" size={0}>
          <Tooltip title={t("back")}>
            <BackButtonStyle
              onClick={() => {
                clearSelectedView();
                history.push(Routes.ROOT);
              }}
              type="text"
            >
              <ArrowBack />
            </BackButtonStyle>
          </Tooltip>

          <AssistanceProgramNameStyle>
            {notification &&
              t("top_opportunities.relevant_patients_for_assistance_program_name", {
                assistanceProgramName: notification.assistanceProgram.name
              })}
          </AssistanceProgramNameStyle>

          <AssistanceProgramStatusStyle>
            {notification &&
              t("top_opportunities.program_status_time", {
                status: notification.assistanceProgram.status,
                time: dateFormatter(notification.createdAt)
              })}
          </AssistanceProgramStatusStyle>
        </Space>

        <Space
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <ImageStyle
            preview={false}
            src={
              notification && notification.assistanceProgram && notification.assistanceProgram.logo ? (
                notification.assistanceProgram.logo
              ) : (
                <Skeleton.Image />
              )
            }
          />
        </Space>
      </HeaderStyle>

      <ContentStyle filtersVisible={filtersVisible}>
        <div
          style={{
            flex: "0 0 288px",
            maxWidth: filtersVisible ? "288px" : "0px",
            transition: "max-width 0.3s ease"
          }}
        >
          <TableFilters
            filtersVisible={filtersVisible}
            setFiltersVisible={setFiltersVisible}
            handleFetchRelevantPatientFilter={handleFetchFilter}
            showSaveNewListBtn={false}
          />
        </div>

        <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
          {!!filteredTableDate.length && (
            <ColumnSelectorContainer isRelevantPatientView={true}>
              <ColumnSelector />
            </ColumnSelectorContainer>
          )}

          <StyledTable
            size="small"
            loading={isFetching}
            rowClassName="cursor-pointer"
            style={{ width: "100%" }}
            filtersVisible={filtersVisible}
            onRow={(_, rowIndex) => {
              return {
                onMouseEnter: () => setHoveringLineIndex(rowIndex),
                onMouseLeave: () => setHoveringLineIndex(null)
              };
            }}
            columns={columns.filter((col) => tableColumns[selectedViews.topOpportunity]?.[col.key]?.display)}
            locale={{
              emptyText: <EmptyTableMessage />
            }}
            onChange={fetchData}
            pagination={{
              size: "small",
              showSizeChanger: false,
              current: relevantPatientsCurrentPage,
              defaultPageSize: PAGE_SIZE,
              pageSize: PAGE_SIZE,
              position: ["bottom"],
              total: totalRecordsCount,
              onChange: (pagination) =>
                saveRelevantPatientsTableConfigToLocalStorage({
                  ...relevantPatientsTableConfig,
                  currentPage: pagination,
                  id: notificationId
                }),
              showTotal: (total, range) => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <PatientPageCount total={total} range={range} isAccurateCount={tableData.isAccurateCount} />
                </div>
              )
            }}
            dataSource={
              tableData && tableData.patients
                ? filteredTableDate
                    .map((patient, index) => {
                      return {
                        ...patient,
                        key: index
                      };
                    })
                    .sort((a, b) => b.isActive - a.isActive)
                : null
            }
          />
        </div>
      </ContentStyle>
    </ContainerStyle>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectRelevantPatientData
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedView: (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView)),
  setTableDataAct: (tableData, sorter, selectedPage) =>
    dispatch(ACTIONS.setTablesData(tableData, selectedPage, selectedViews.topOpportunity, sorter)),
  clearSelectedView: () => dispatch(ACTIONS.clearSelectedView())
});

const TopOpportunities = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TopOpportunitiesComponent);

export { TopOpportunities };
