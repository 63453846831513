import React from "react";

import SBItemsHelper from "../utils/sidebarItemsHelper";
import { SubmittedFormResultModal } from "../../PatientApplications/enrollTab/submission/SubmittedFormResultModal";
import { SubTitleStyled } from "../../PatientApplications/enrollTab/submission/ApprovedResult";
import { EEsignatureStatuses } from "../../../../constant/EEsignatureStatuses";

const getViewDetailsModal = (onCancel, visible) => <SubmittedFormResultModal onCancel={onCancel} visible={visible} />;

const getApprovedResultDescription = (t) => (
  <SubTitleStyled style={{ margin: 10 }}>
    {t("patientApplications.enroll.enroll_result.application_approved_subtitle")}
  </SubTitleStyled>
);

const getAdditionalResultCardInformation = (t) => t("patientApplications.enroll.enroll_result.call_support");

const shouldSaveAppear = () => true;

const getShowEnrollResult = (applicationForm) =>
  [EEsignatureStatuses.COMPLETED, EEsignatureStatuses.SUBMIT_FAILED].includes(applicationForm?.status);

const submissionStep = {
  description: (t) => t("patientApplications.enroll.application_form_submission.description"),
  getSubmitButtonText: (t) => t("patientApplications.enroll.application_form_submission.submit_button"),
  submitModal: {
    showNotice: true,
    attachments: {
      showAllowedFileTypesNotice: true
    }
  },
  getSubmitSuccessMessage: (t) => t("patientApplications.enroll.application_form_submission.submit_success_message")
};

const useBaseEEConfiguration = ({
  vendorName,
  eePlanType,
  rootPath,
  sidebarItems,
  validationFieldsBySidebarItem,
  formJson
}) => {
  const formId = `${vendorName}_${eePlanType}_smart_editor`;
  const resultId = `${vendorName}_${eePlanType}_enroll_result`;
  const prepareButtonId = `EE_${vendorName}_${eePlanType}_prepare_application_form`;
  const viewDetailsButtonId = `EE_${vendorName}_${eePlanType}_view_submission_details`;
  const applicationSupportsEEId = `application_supports_${vendorName}_${eePlanType}_EE`;
  const approvalDetailsButtonId = `EE_${vendorName}_${eePlanType}_view_approval_details`;

  const getSidebarItems = (t, expressEnrollmentConfiguration) => {
    return SBItemsHelper.generateSidebarConfig(t, rootPath, {
      itemNamesArr: sidebarItems,
      hasAdminOption: expressEnrollmentConfiguration?.hasAdminOption,
      formSections: formJson?.formSections,
      validationFieldsBySidebarItem
    });
  };

  return {
    formId,
    resultId,
    prepareButtonId,
    viewDetailsButtonId,
    applicationSupportsEEId,
    approvalDetailsButtonId,
    getSidebarItems,
    getViewDetailsModal,
    getApprovedResultDescription,
    getAdditionalResultCardInformation,
    shouldSaveAppear,
    getShowEnrollResult,
    submissionStep
  };
};

export default useBaseEEConfiguration;
