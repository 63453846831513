import React, { useEffect, useState } from "react";
import { Col, PageHeader, Row } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { OnlinePreview } from "./OnlinePreview";
import { CsvToJson } from "./CsvToJson";
import { SidebarIcon } from "../Patient/ApplicationFormEditor/components";

import "./OnlineWizard.css";
import { FIELD_DATA_STRUCTURE } from "./sharedData";

export const OnlineWizard = () => {
  const [sections, setSections] = useState({});
  const [json, setJson] = useState({});
  const [isJsonFromUrl, setIsJsonFromUrl] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jsonParam = searchParams.get("json");

    if (jsonParam) {
      try {
        const parsedJson = JSON.parse(decodeURIComponent(jsonParam));
        setJson(parsedJson);
        setIsJsonFromUrl(true);
      } catch (error) {
        console.error("Invalid JSON in URL parameters", error);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (!_.isEmpty(json)) {
      const sectionSidebar = parseSections(json);
      setSections(sectionSidebar);
    }
  }, [json]);

  const parseSections = (json) => {
    const sectionsMap = {};
    json.formSections.map((sectionData) => {
      const sectionName = sectionData[FIELD_DATA_STRUCTURE.SECTION_NAME];
      sectionsMap[sectionName] = {
        href: `#${sectionName}`,
        title: sectionData[FIELD_DATA_STRUCTURE.SECTION_HEADER],
        sidebarName: sectionData[FIELD_DATA_STRUCTURE.SECTION_SIDEBAR],
        key: sectionName,
        icon: SidebarIcon,
        sectionName,
        rows: []
      };

      sectionsMap[sectionName].rows = sectionData.rows;
    });

    return sectionsMap;
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={t("online_wizard.title")}
        subTitle={t("online_wizard.subtitle")}
      />

      <Row style={{ padding: "0px 5px" }}>
        {!isJsonFromUrl && (
          <Col span={8}>
            <CsvToJson setJson={setJson} json={json} />
          </Col>
        )}
        <Col span={16} id="exportableView">
          <OnlinePreview sections={sections} setJson={setJson} />
        </Col>
      </Row>
    </div>
  );
};
