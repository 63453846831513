import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { differenceInYears, isBefore, subYears } from "date-fns";
import { phoneNumberLengthRegEx } from "../../../../utils/formValidation";

const zipInputRules = [
  {
    pattern: /^\d{5}$/,
    message: ""
  }
];

const onlyWhitespacesRule = {
  whitespace: true,
  message: ""
};

const emailInputRules = [
  onlyWhitespacesRule,
  {
    type: "email",
    message: ""
  }
];

const npiInputRule = {
  pattern: /^\d{10}$/,
  message: ""
};

const isUnderEighteen = (dob) => {
  const dobDate = new Date(dob);
  const today = new Date();

  const age = differenceInYears(today, dobDate);
  return age < 18 || (age === 18 && isBefore(today, subYears(dobDate, 18)));
};

const patientCantBeUnderEighteenRule = ({ message }) => ({
  validator: (rule, value) => {
    if (isUnderEighteen(value)) {
      return Promise.reject(
        <span style={{ color: "red" }}>
          <ExclamationCircleOutlined style={{ color: "red", marginRight: 5 }} />
          {message}
        </span>
      );
    }
    return Promise.resolve();
  }
});

const mustMatchAllowedValues = ({ allowedValues, message }) => ({
  validator: (rule, value) => {
    if (!allowedValues.includes(value)) {
      return Promise.reject(
        <span style={{ color: "red" }}>
          <ExclamationCircleOutlined style={{ color: "red", marginRight: 5 }} />
          {message}
        </span>
      );
    }
    return Promise.resolve();
  }
});

const phoneNumberLengthRule = {
  pattern: phoneNumberLengthRegEx,
  message: ""
};

export {
  zipInputRules,
  npiInputRule,
  emailInputRules,
  onlyWhitespacesRule,
  phoneNumberLengthRule,
  patientCantBeUnderEighteenRule,
  mustMatchAllowedValues
};
