import Moment from "moment-timezone";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

import {
  LAST_3_DAYS,
  LAST_WEEK,
  LAST_MONTH,
  TODAY,
  NEXT_2_DAYS,
  NEXT_3_DAYS,
  THIS_WEEK,
  FOLLOWING_WEEK,
  ALL_TIMES,
  NEXT_MONTH,
  INDEFINITELY,
  LAST_2_MONTHS,
  NEXT_7_DAYS,
  NEXT_30_DAYS,
  LAST_90_DAYS,
  LAST_3_MONTHS
} from "../constant/filters";

export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_DASH = "MM-DD-YYYY";
export const DATE_FORMAT_1 = "MMM D, YYYY";
export const DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";
export const DATE_TIME_FORMAT_COMMA = "MM/DD/YYYY, hh:mm A";
export const DATE_DATA_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FILTERS_FORMAT = "YYYY-MM-DD HH:mm";

const getNLastDaysRange = (n) => {
  return [
    moment().startOf("day").subtract(n, "d").format(DATE_TIME_FILTERS_FORMAT),
    moment().endOf("day").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

const getNNextDaysRange = (n) => {
  return [
    moment().startOf("day").format(DATE_TIME_FILTERS_FORMAT),
    moment()
      .endOf("day")
      .add(n - 1, "d")
      .format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const timeOfDay = () => {
  const currentHour = new Date().getHours();
  if (currentHour >= 5 && currentHour < 11) {
    return "morning";
  } else if (currentHour >= 11 && currentHour < 16) {
    return "day";
  } else if (currentHour >= 16 && currentHour < 19) {
    return "afternoon";
  } else if (currentHour >= 19 && currentHour < 22) {
    return "evening";
  } else if (currentHour >= 22 || currentHour < 5) {
    return "night";
  }
};

export const thisWeek = () => {
  return [
    moment().startOf("isoweek").format(DATE_TIME_FILTERS_FORMAT),
    moment().endOf("isoweek").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const lastMonth = () => {
  return [
    moment().startOf("day").subtract(1, "M").format(DATE_TIME_FILTERS_FORMAT),
    moment().endOf("day").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const nextMonth = () => {
  return [
    moment().startOf("day").format(DATE_TIME_FILTERS_FORMAT),
    moment().endOf("day").add(1, "M").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const last3Days = () => getNLastDaysRange(3);

export const next2Days = () => getNNextDaysRange(2);

export const next3Days = () => getNNextDaysRange(3);

export const next7Days = () => getNNextDaysRange(7);

export const next30Days = () => getNNextDaysRange(30);

export const last90Days = () => getNLastDaysRange(90);

export const followingWeek = () => {
  return [
    moment().add(1, "weeks").startOf("isoWeek").format(DATE_TIME_FILTERS_FORMAT),
    moment().add(1, "weeks").endOf("isoWeek").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const today = () => {
  return [
    moment().startOf("day").format(DATE_TIME_FILTERS_FORMAT),
    moment().endOf("day").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const lastWeek = () => {
  return [
    moment().startOf("day").subtract(1, "W").format(DATE_TIME_FILTERS_FORMAT),
    moment().endOf("day").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const dateRange = (fromDate, toDate) => {
  return [fromDate.format(DATE_FORMAT), toDate.format(DATE_FORMAT)];
};

export const lastMonthN = (months) => {
  return [
    moment().startOf("day").subtract(months, "M").format(DATE_TIME_FILTERS_FORMAT),
    moment().endOf("day").format(DATE_TIME_FILTERS_FORMAT)
  ];
};

export const setDateRange = (fromDate, toDate) => {
  switch (fromDate) {
    case LAST_MONTH:
      return lastMonth();
    case LAST_WEEK:
      return lastWeek();
    case LAST_3_DAYS:
      return last3Days();
    case TODAY:
      return today();
    case NEXT_2_DAYS:
      return next2Days();
    case NEXT_3_DAYS:
      return next3Days();
    case NEXT_7_DAYS:
      return next7Days();
    case NEXT_30_DAYS:
      return next30Days();
    case LAST_90_DAYS:
      return last90Days();
    case THIS_WEEK:
      return thisWeek();
    case FOLLOWING_WEEK:
      return followingWeek();
    case NEXT_MONTH:
      return nextMonth();
    case ALL_TIMES:
      return [];
    case INDEFINITELY:
      return [INDEFINITELY];
    case LAST_2_MONTHS:
      return lastMonthN(2);
    case LAST_3_MONTHS:
      return lastMonthN(3);
    default:
      return dateRange(fromDate, toDate);
  }
};

// Note: Use this when you want to display a date type only - since it will be with this format "YYYY-MM-DDT00:00:00.000Z" it will display wrong date if you use local time zone, the trick is to display UTC same as its.
export const dateFormatter = (date, toFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment(date).format(toFormat);
};

export const utcDateFormatter = (date, toFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment.utc(date).format(toFormat);
};

// Note: Use this when you want to display a datetime type - it will use local timezone.
export const dateTimeFormatter = (date, toFormat = DATE_TIME_FORMAT) => {
  const localTimeZoneDate = moment(date).local();
  return localTimeZoneDate.format(toFormat);
};

export const getCurrentDateForDatePicker = () => {
  // change moment's current default (Etc/UTC) to  local tz - so dateTime will shown in the local tz
  moment.tz.setDefault();

  const localTimeZoneDate = moment(moment().format("YYYY-MM-DD") + " " + "12:00");

  // restore moment's to it's previous default tz (Etc/UTC) - in order to present dates unanimous for different timezones
  moment.tz.setDefault("Etc/UTC");
  return localTimeZoneDate;
};

export const formatClaimServiceDates = (arrayOfDates) => {
  if (!arrayOfDates || arrayOfDates.length === 0) return "";

  const maxDate = new Date(
    Math.max(
      ...(arrayOfDates || []).map((item) => {
        return new Date(item["serviceDate"]);
      })
    )
  );

  const minDate = new Date(
    Math.min(
      ...(arrayOfDates || []).map((item) => {
        return new Date(item["serviceDate"]);
      })
    )
  );

  if (JSON.stringify(moment(maxDate).format(DATE_FORMAT)) === JSON.stringify(moment(minDate).format(DATE_FORMAT))) {
    return dateFormatter(maxDate);
  } else {
    return dateFormatter(minDate) + " - " + dateFormatter(maxDate);
  }
};

export const getSplitDates = (serviceDates) => {
  const splitDates = serviceDates.includes("-") ? serviceDates.split("-") : [serviceDates, serviceDates];
  return splitDates.map((date) => (date ? new Date(date) : null));
};

//NOTE: when formatting WITHOUT utc it will use the local timezone and comparing dates will break
export const isDateEqual = (dateA, dateB) => {
  const formattedDateA = utcDateFormatter(dateA);
  const formattedDateB = utcDateFormatter(dateB);
  return formattedDateA === formattedDateB;
};
