// EE TODO: remove eslint-disable comment
/* eslint-disable */
import React from "react";

import { getRegeneronCopayFormInitialValues } from "./form/initialValues";
import { SaveButton } from "../../header";
import useBaseEEConfiguration from "../../hooks/useBaseEEConfiguration";
import { applyTrimFields } from "../../utils/updateFormData";
import renderReactFromJson from "../../utils/renderReactFromJson";
import formJson from "./form/RegeneronCopayFormJson.json";
import { CompleteFormButton } from "../../CompleteFormButton";
import SBItemsHelper from "../../utils/sidebarItemsHelper";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../../constants";
import { SignerTypes } from "../../../../../constant/ESign";

export const useRegeneronCopayConfiguration = () => {
  const isJsonBase = true;
  const vendorName = "regeneron";
  const eePlanType = "copay";
  const rootPath = "application_form_editor.regeneron.copay"; //EE TODO: add data to src/locales/translations/en/ApplicationFormEditor.js
  const sidebarItems = formJson.formSections.map((section) => section.sectionSidebar);

  const baseConfig = useBaseEEConfiguration(vendorName, eePlanType, rootPath, sidebarItems);

  // EE TODO remove configuration that is not specific to the vendor and use the base configuration
  const regeneronSpecificConfig = {
    activeFormSection: "",
    getFormInitialValues: getRegeneronCopayFormInitialValues,
    getFormFromJson: (form) => renderReactFromJson(form, formJson),
    saveButton: (props) => (
      <SaveButton onSave={(form) => applyTrimFields(form)} id={"EE_regeneron_copay_save_form"} {...props} />
    ),
    afterSubmissionPayload: () => ({}),
    applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_complete_button"} {...props} />,
    headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.COMPLETE_FORM,
        getSignatureWidgetSettingsBySignerType: (signerType) => {
          const signatureSettings = {
            [SignerTypes.PHYSICIAN]: {
              editable: true,
              titleTranslationKey: "patientApplications.enroll.electronic_signatures.signature_step.signers.physician",
              shouldShowCaregiverIndication: false,
              shouldUseRemoteRequestCheckboxes: false
            }
          };
          return signatureSettings[signerType];
        }
  };

  return {
    ...baseConfig,
    ...regeneronSpecificConfig
  };
};

export default useRegeneronCopayConfiguration;
