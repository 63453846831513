import React, { useEffect, useState } from "react";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { PreviewField } from "./PreviewField";
import { FIELD_DATA_STRUCTURE } from "./sharedData";

const calculateColSize = (newColSize) => Math.min(Math.max(newColSize, 1), 24);

export const SectionFields = ({ section }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [rows, setRows] = useState(section.rows);
  const [isChanged, setIsChanged] = useState(section.rows);

  // Sync rows state when section changes
  useEffect(() => {
    setRows(section.rows);
  }, [section]);

  useEffect(() => {
    if (isChanged) {
      section.rows = rows;
      setIsChanged(false);
    }
  }, [isChanged]);

  const fullLineToggle = (rowIndexToUpdate, fieldIndexToUpdate) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row, rowIndex) =>
        rowIndex === rowIndexToUpdate
          ? {
              ...row,
              fields: row.fields.map((field, fieldIndex) =>
                fieldIndex === fieldIndexToUpdate ? { ...field, fullLine: !field.fullLine } : field
              )
            }
          : row
      );
      return updatedRows;
    });
    setIsChanged(true);
  };

  const handleSpanChange = (rowIndexToUpdate, fieldIndexToUpdate, increment) => {
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row, rowIndex) =>
        rowIndex === rowIndexToUpdate
          ? {
              ...row,
              fields: row.fields.map((field, fieldIndex) =>
                fieldIndex === fieldIndexToUpdate
                  ? { ...field, colSize: calculateColSize(field.colSize + increment) }
                  : field
              )
            }
          : row
      );
      return updatedRows;
    });
    setIsChanged(true);
  };

  const handleFieldChange = (fieldName, value) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value
    }));
  };
  return (
    <>
      {rows.length ? (
        rows.map((row) => {
          return (
            <Row key={row.rowIndex} gutter={[16, 16]}>
              {row.fields.length ? (
                row.fields.map((field, fieldIndex) => {
                  const isFullLine = field.fullLine;
                  const tooltipText = isFullLine
                    ? t("online_wizard.unset_full_line_field_tooltip")
                    : t("online_wizard.set_full_line_field_tooltip");
                  return (
                    <Col key={fieldIndex} span={isFullLine ? 24 : field.colSize}>
                      <div className={"fieldParentDiv"}>
                        <PreviewField
                          value={formData[field.label]}
                          field={field}
                          handleFieldChange={handleFieldChange}
                          span={isFullLine ? field.colSize : 24}
                          sectionName={section[FIELD_DATA_STRUCTURE.SECTION_NAME]}
                        />
                        <div className="hoverElement">
                          <Space direction="vertical" align="center" size="small">
                            <Button size="small" onClick={() => handleSpanChange(row.rowIndex, fieldIndex, 1)}>
                              +
                            </Button>
                            <Tooltip placement="right" title={tooltipText}>
                              <Button
                                size="small"
                                onClick={() => fullLineToggle(row.rowIndex, fieldIndex)}
                                style={{ cursor: "default" }}
                              >
                                {field.colSize}
                                {isFullLine && "*"}
                              </Button>
                            </Tooltip>
                            <Button size="small" onClick={() => handleSpanChange(row.rowIndex, fieldIndex, -1)}>
                              -
                            </Button>
                          </Space>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <p>No fields available</p>
              )}
            </Row>
          );
        })
      ) : (
        <p>No fields available</p>
      )}
    </>
  );
};
