import React from "react";
import moment from "moment-timezone";
import { Trans } from "react-i18next";

import * as genentechFreeDrugFieldNames from "./form/fieldNames";
import { getGenentechFreeDrugFormInitialValues } from "./form/initialValues";
import GenentechFreeDrugPatient from "./form/formSections/Patient";
import GenentechFreeDrugPatientConsent from "./form/formSections/PatientConsent";
import GenentechFreeDrugInsurance from "./form/formSections/Insurance";
import GenentechFreeDrugMedical from "./form/formSections/Medical";
import GenentechFreeDrugPractice from "./form/formSections/Practice";
import GenentechFreeDrugPatientSubmitAttestation from "./form/formSections/SubmitAttestation";
import { SupportOptions } from "../../components/SupportOptions";
import { DynamicQuestionnaire } from "../../components/DynamicQuestionnaire";
import { SaveButton } from "../../header";
import useBaseEEConfiguration from "../../hooks/useBaseEEConfiguration";
import { SignatureUploader } from "../../../PatientApplications/enrollTab/utils/uploadFunctionality";
import { fileTypes } from "../../../../Uploader";
import GenentechFreeDrugPrescription from "./form/formSections/Prescription";
import { CompleteFormButton } from "../../CompleteFormButton";
import { DATE_FORMAT } from "../../../../../utils/date";
import { APPLICATIONS } from "../../../../../constant/applications";
import { SubmittedFormModal } from "../../../PatientApplications/enrollTab/submission/SubmittedFormModal";
import { EEApproveApplicationMockAPI } from "../../../../../api/api";
import { ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, HEADER_SUBMIT_BUTTON_TYPES } from "../../constants";
import { applyTrimFields } from "../../utils/updateFormData";

const vendorName = "genentech";
const eePlanType = "free_drug";
const rootPath = "application_form_editor.genentech.free_drug";
const eligibilityQuestionnaire = "eligibilityQuestionnaire";

const sidebarItems = [
  eligibilityQuestionnaire,
  "patient",
  "consent",
  "insurance",
  "prescription",
  "medical",
  "practice",
  "submitAttestation"
];

const validationFieldsBySidebarItem = {
  [eligibilityQuestionnaire]: [eligibilityQuestionnaire, "questionnaireOption"],
  patient: ["patient"],
  consent: ["consent"],
  insurance: ["insurance"],
  prescription: ["prescription"],
  medical: ["medical"],
  practice: ["practice"],
  submitAttestation: ["submitAttestation"]
};

const genentechSpecificConfig = {
  activeFormSection: "#enrollment-form-eligibility-questionnaire",
  fieldNames: genentechFreeDrugFieldNames,
  getFormInitialValues: getGenentechFreeDrugFormInitialValues,
  getFormSectionComponents: () => (
    <>
      <SupportOptions />
      <DynamicQuestionnaire
        DividerText={"application_form_editor.genentech.free_drug.eligibilityQuestionnaire.header"}
        ineligibleAnswerError={
          "application_form_editor.genentech.free_drug.eligibilityQuestionnaire.ineligible_answer_error"
        }
        dynamicQuestionnaireSectionId={`enrollment-form-${eligibilityQuestionnaire}`}
      />
      <GenentechFreeDrugPatient />
      <GenentechFreeDrugPatientConsent />
      <GenentechFreeDrugInsurance />
      <GenentechFreeDrugPrescription />
      <GenentechFreeDrugMedical />
      <GenentechFreeDrugPractice />
      <GenentechFreeDrugPatientSubmitAttestation />
    </>
  ),
  applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_complete_button"} {...props} />,
  headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.COMPLETE_FORM,
  successMessage: (t, submittedDate) => {
    return (
      <Trans
        i18nKey="patientApplications.enroll.application_form_submission.genentech_free_drug.submit_completed"
        components={{
          br: <br />,
          submitDate: moment(submittedDate).format(DATE_FORMAT)
        }}
      />
    );
  },
  getViewDetailsModal: (onCancel, visible) => <SubmittedFormModal onCancel={onCancel} visible={visible} />,
  getShowEnrollResult: () => false,
  saveButton: (props) => <SaveButton onSave={onSaveGenentechForm} id={"EE_genentech_free_drug_save_form"} {...props} />,
  signatureStep: {
    uploadButtonText: (t) => t("patientApplications.enroll.electronic_signatures.upload_signature_button"),
    ESignButtonText: (t) => t("patientApplications.enroll.electronic_signatures.get_signature_button"),
    getDocusignRecipients: (t) => [
      { type: "provider", role: t("patientApplications.enroll.electronic_signatures.prescriber") },
      { type: "sender", role: t("patientApplications.enroll.electronic_signatures.sender") }
    ],
    fileTypesAccepted: [fileTypes.JPG, fileTypes.PNG].join(),
    uploadAttachmentButton: SignatureUploader,
    pendoUploadSignAttachment: "Upload Signature",
    pendoUploadSignAttachmentActionTaken: "Signature has been attached to application"
  },
  isModalSubmissionEnabled: false,
  afterSubmissionPayload: () => ({ status: APPLICATIONS.statuses.applied, appliedDate: moment() }),
  shouldShowPdfInSecondStep: true,
  shouldHideSendBySMSInSignature: true,
  submissionStep: {
    description: () => {
      return (
        <Trans
          i18nKey="patientApplications.enroll.application_form_submission.genentech_free_drug.description"
          components={{
            br: <br />
          }}
        />
      );
    },
    getSubmitButtonText: (t) => t("patientApplications.enroll.application_form_submission.submit_button"),
    submitModal: {
      showNotice: true,
      attachments: {
        showAllowedFileTypesNotice: true
      }
    },
    getSubmitSuccessMessage: (t) => t("patientApplications.enroll.application_form_submission.submit_success_message"),
    afterSubmitCompleted: async (applicationForm) => {
      if (applicationForm?.isDemo) {
        await EEApproveApplicationMockAPI(applicationForm.id);
      }
    }
  }
};

export const useGenentechFreeDrugConfiguration = () => {
  const baseConfig = useBaseEEConfiguration({
    vendorName,
    eePlanType,
    rootPath,
    sidebarItems,
    validationFieldsBySidebarItem
  });

  return {
    ...baseConfig,
    ...genentechSpecificConfig
  };
};

export const onSaveGenentechForm = (form) => applyTrimFields(form, [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME]);

export default useGenentechFreeDrugConfiguration;
