import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { selectUser } from "../../store/selector";
import { createAIChatBotConversation } from "../../api/api";
import { ChatHeader } from "./ChatHeader";
import { CustomLayout } from "./Styles";
import { ChatSider } from "./ChatSider";
import { ChatWidgetLayout } from "./ChatWidgetLayout";
import { useFetch } from "../../hooks/fetch";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";

const AIChatViewComponent = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);
  const [shouldRefreshSider, setShouldRefreshSider] = useState(false);
  const t = useTranslation();
  const user = useSelector(selectUser);
  const history = useHistory();
  const location = useLocation();
  const { conversationId } = useParams();

  const [createConversation, createConversationResult, , createConversationError] =
    useFetch(createAIChatBotConversation);

  const navigateToConversation = (newConversationId) => {
    history.push({
      pathname: `/ai-chat-bot/conversations/${newConversationId}/messages`,
      state: { from: location.pathname }
    });
  };

  useEffect(() => {
    const initializeConversation = async () => {
      if (!user) return;

      if (conversationId) {
        setShouldLoadHistory(true);
      } else {
        createConversation(user);
      }
    };

    initializeConversation();
  }, [user, conversationId]);

  useEffect(() => {
    if (createConversationError) {
      ErrorMessage(t("ai_chat_bot.errors.failed_creating_conversation"));
    }

    if (createConversationResult) {
      setShouldRefreshSider(true);
      setShouldLoadHistory(false);
      navigateToConversation(createConversationResult.data);
    }
  }, [createConversationResult]);

  const handleNewChat = () => {
    createConversation(user);
  };

  const handleConversationChange = (newConversationId) => {
    setShouldLoadHistory(true);
    navigateToConversation(newConversationId);
  };

  return (
    <div style={{ height: "83vh", width: "100%" }}>
      <CustomLayout style={{ padding: "16px", boxSizing: "border-box" }}>
        <ChatHeader onNewChat={handleNewChat} collapsed={collapsed} onCollapsed={() => setCollapsed(!collapsed)} />
        <CustomLayout>
          <ChatSider
            collapsed={collapsed}
            onCollapse={setCollapsed}
            userId={user?.id}
            activeConversationId={conversationId}
            handleConversationChange={handleConversationChange}
            shouldRefreshTrigger={shouldRefreshSider}
          />
          <ChatWidgetLayout
            activeConversationId={conversationId}
            collapsed={collapsed}
            shouldLoadHistory={shouldLoadHistory}
          />
        </CustomLayout>
      </CustomLayout>
    </div>
  );
};

export { AIChatViewComponent as AIChatView };
