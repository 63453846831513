import { ASSIGNEE_TYPES } from "../constant/assigneeTypes";
import { APPLICATIONS } from "../constant/applications";
import { capitalizeEachFirstLetter, numberWithCommas } from "./string";
import moment from "moment-timezone";

export const formatAssignee = (assignee) => {
  if (!assignee) return "";
  switch (assignee.type) {
    case ASSIGNEE_TYPES.user:
      return `${assignee.lastName}, ${assignee.firstName}`;
    case ASSIGNEE_TYPES.group:
      return assignee.name;
    default:
      return `${assignee.lastName}, ${assignee.firstName}`;
  }
};

export const formatAssigneesToDisplay = (assignees = []) => {
  return assignees.length ? assignees.map((assignee) => assignee.userName).join(" | ") : "";
};

export const formatPhoneNumberWhileTyping = (value) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};

export const formatPhoneNumberToDashed = (value) => {
  if (!value) return value;

  const phoneNumber = value.toString().replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};

export const formatSSN = (value) => {
  if (!value) return value;

  const ssn = value.replace(/[^\d]/g, "");
  const ssnLength = ssn.length;

  if (ssnLength < 4) return ssn;

  if (ssnLength < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }

  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return cleaned;
};

export const formatPartialSSNumber = (input) => {
  input = input.replace(/\D/g, "");
  var size = input.length;
  if (size > 0) {
    input = "" + input;
  }
  if (size > 3) {
    input = input.slice(0, 3) + "-" + input.slice(3, 9);
  }
  if (size > 6) {
    input = input.slice(0, 6) + "-" + input.slice(6);
  }
  return input;
};

export const formatPhoneNumberToString = (input) => {
  return input.split("-").join().replaceAll(",", "").replaceAll("(", "").replaceAll(")", "");
};

export const getClickableLink = (link) => {
  return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
};

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);
  const clonedData = [...formData.entries()];
  const urlEncodedData = clonedData
    .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
    .join("&");
  return urlEncodedData;
}

export const formatApplicationStatus = (status, t) =>
  status === APPLICATIONS.statuses.rejected ? t("patientApplications.denied") : status;

export function formatDrugDisplayName(drug) {
  if (!drug) return null;
  return capitalizeEachFirstLetter(drug.text || drug.name);
}

export const formatRxnormDrugNameForSearch = (pinName, bnName) => {
  return bnName ? `${pinName} (${bnName})` : pinName;
};

export const formatDrugBenefit = ({ copay, coinsurance }) => {
  if (coinsurance && coinsurance <= 1) {
    coinsurance = coinsurance * 100;
  }

  if (copay && coinsurance) {
    return `$${copay} Copay and ${coinsurance}% Coinsurance`;
  } else if (copay) {
    return `$${copay} Copay`;
  } else if (coinsurance) {
    return coinsurance === 100 ? "Not covered" : `${coinsurance}% Coinsurance`;
  } else {
    return "Fully covered";
  }
};
// TODO:: remove this, and use dateFormatter instead
export const decodeDate = (date) => {
  if (!date) return date;

  return moment(date).format("MM/DD/YYYY");
};

export const decodeNumbersForSearch = (input) => {
  if (!input) return input;
  return input.replace(/[^a-zA-Z0-9]/g, "");
};

export const getTrimmedFields = (formFieldValues) => {
  const trimSection = (section) => {
    if (typeof section === "string") {
      return section.trim();
    }
    const trimmedSection = {};

    Object.keys(section).forEach((key) => {
      const value = section[key];
      if (Array.isArray(value)) {
        trimmedSection[key] = value.map(trimSection);
      } else if (value && typeof value === "object") {
        trimmedSection[key] = trimSection(value);
      } else if (typeof value === "string") {
        trimmedSection[key] = value.trim();
      }
    });

    return Object.keys(trimmedSection).length ? trimmedSection : {};
  };

  const trimmedFields = trimSection(formFieldValues);
  return trimmedFields;
};

export const formatToDecimal = (value) => {
  // Handle null, undefined, or invalid numbers explicitly, return empty string
  if (value == null || isNaN(value)) return "";

  const numberValue = Number(value);

  // Explicitly handle 0
  if (numberValue === 0) return "0";

  if (numberValue % 1 === 0) {
    return numberWithCommas(numberValue);
  }

  //Note: Should return “0” when the  input is big number (out of bound)
  if (numberValue.toFixed(2) == 0) return "0";

  return numberWithCommas(numberValue.toFixed(2));
};

export const formatToTwoDecimal = (value) => {
  if (!value) return "";

  const numberValue = Number(value);
  return numberWithCommas(numberValue.toFixed(2));
};
export const filterDuplicates = (item, index, self) => self.findIndex((d) => d.value === item.value) === index;
