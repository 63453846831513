import React from "react";
import { Space } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { BLUE_2 } from "../../constant/colors";
import { ChatHeaderStyle, HeaderTextStyle, CollapsibleButton, StartNewConversationButton } from "./Styles";

const ChatHeader = ({ onNewChat, onCollapsed, collapsed }) => {
  const { t } = useTranslation();
  const iconStyle = { color: BLUE_2, fontSize: "20px" };

  return (
    <ChatHeaderStyle collapsed={collapsed}>
      <Space size={30}>
        <>
          <Space>
            <CollapsibleButton
              type="text"
              icon={!collapsed ? <DoubleLeftOutlined style={iconStyle} /> : <DoubleRightOutlined style={iconStyle} />}
              onClick={onCollapsed}
            />
            <StartNewConversationButton type="text" icon={<PlusOutlined style={iconStyle} />} onClick={onNewChat} />
          </Space>
          <HeaderTextStyle>{t("ai_chat_bot.chat_bot_name")}</HeaderTextStyle>
        </>
      </Space>
    </ChatHeaderStyle>
  );
};
export { ChatHeader };
