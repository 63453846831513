import { RightOutlined } from "@ant-design/icons";
import { Affix, Form, Typography } from "antd";
import React, { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";

import { ManagePatientDetailsContext } from "./context";
import { selectPatientDetails, selectUsers } from "../../store/selector";
import { OutlinedButton, PrimaryButton } from "../customComponent/Button";
import { font14pxR, font14pxSB } from "../Typography";
import { useGetSetting } from "../../hooks/getSetting";
import { RouterPrompt } from "../../router/RouterPrompt";
import DiscardChangesPopup from "../Patient/PatientCoverageDetails/DiscardChangesPopup";
import { Routes } from "../../constant/routes";
import { dateTimeFormatter } from "../../utils/date";
import { GRAY_500, PRIMARY_600 } from "../../constant/colors";

const { Text } = Typography;

const DATE_TIME_FORMAT = "MM/DD/YYYY h:mm a";

const SubHeaderWrapper = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 16px 30px 0px 30px;
`;

const SubHeaderContent = styled.div`
  width: ${(props) => props.theme.dimensions.width};
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const ManagePatientDetailsText = styled(Text)`
  ${font14pxSB}
  background-color: #F2F4F7;
  padding: 4px 8px;
  border-radius: 8px;
`;

const PatientDetailsName = styled.div`
  ${font14pxSB}
  color: ${PRIMARY_600};
  padding: 0px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const PatientDetailsIds = styled(Text)`
  ${font14pxR}
`;

const DetailText = styled(Text)`
  color: ${GRAY_500};
  font-size: 12px;
  margin-right: 30px;
  margin-left: 10px;
`;

const LastUpdatedText = ({ timestamp, userId, ...props }) => {
  const users = useSelector(selectUsers);

  const userName = useMemo(() => {
    const user = userId && users?.[userId];

    if (!userId || user?.isPatientExperienceUser) {
      return "System";
    }

    if (!user) {
      return "Unknown User";
    }

    return `${user?.lastName}, ${user?.firstName}`;
  }, [users, userId]);

  if (!timestamp) {
    return null;
  }

  return (
    <DetailText {...props}>
      Last saved {dateTimeFormatter(timestamp, DATE_TIME_FORMAT)} by {userName}
    </DetailText>
  );
};

const SubHeader = ({ submitButtonText, isFormModified, isFetching, formWithoutErrors = true, handleSubmit }) => {
  const { t } = useTranslation();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [blockDiscardPopup, setBlockDiscardPopup] = useState(false);
  const { patientId, journeyId } = useParams();
  const patientDetails = useSelector(selectPatientDetails);
  const history = useHistory();

  const { lastUpdate } = useContext(ManagePatientDetailsContext);

  const isCurrentConflictPage = _.last(window.location.pathname.split("/")) == "conflicts";
  const isPreviousSearchPage = history.location?.state?.path === Routes.SEARCH_RESULTS;
  const previousPage = history.location?.state?.path;
  const goBackToPatient = () =>
    isCurrentConflictPage || isPreviousSearchPage
      ? history.goBack()
      : previousPage
      ? history.push(previousPage)
      : history.push(`/patient/${patientId}/journey/${journeyId}`);

  const form = Form.useFormInstance();
  Form.useWatch([], form);
  const [prefix] = useGetSetting(["id_prefix"]);

  const { lastUpdateTimestamp, lastUpdateUserId } = lastUpdate || {};

  return (
    <>
      <RouterPrompt shouldTriggerPopup={isFormModified & !blockDiscardPopup} />
      <Affix offsetTop={64}>
        <SubHeaderWrapper>
          <SubHeaderContent>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8, paddingBottom: 16 }}>
              <PatientDetailsName onClick={goBackToPatient} type="link" id="patient_name_manage_patient_details">
                {patientDetails?.name || t("patient")} ({(prefix || "TM").toUpperCase()}-{patientId})
              </PatientDetailsName>

              <RightOutlined />
              {isCurrentConflictPage ? (
                <>
                  <PatientDetailsIds>{t("managePatientDetails.manage_patient_details")}</PatientDetailsIds>
                  <RightOutlined />
                  <ManagePatientDetailsText>{t("conflicts")}</ManagePatientDetailsText>
                </>
              ) : (
                <ManagePatientDetailsText>{t("managePatientDetails.manage_patient_details")}</ManagePatientDetailsText>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 6, paddingBottom: 16 }}>
              <LastUpdatedText id="last_saved" timestamp={lastUpdateTimestamp} userId={lastUpdateUserId} />
              <Form.Item style={{ marginBottom: 0 }}>
                <OutlinedButton htmlType="button" onClick={goBackToPatient} disabled={isFetching} id="edit_cancel">
                  {t("cancel")}
                </OutlinedButton>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <PrimaryButton
                  disabled={!isFormModified || isFetching || !formWithoutErrors}
                  loading={isFetching}
                  id="edit_save_changes"
                  onClick={() => {
                    handleSubmit();
                    setBlockDiscardPopup(true);
                  }}
                >
                  {submitButtonText || t("save_changes")}
                </PrimaryButton>
              </Form.Item>
            </div>
          </SubHeaderContent>
        </SubHeaderWrapper>
      </Affix>
      <DiscardChangesPopup
        visible={cancelModalVisible}
        onClickDiscard={goBackToPatient}
        onClickCancel={() => setCancelModalVisible(false)}
        popupTitle="foobar"
      />
    </>
  );
};

export { SubHeader };
