import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Button, Col, Input, Row, Space, Typography, Select, Tooltip } from "antd";
import { debounce } from "lodash";
import { useLocation } from "react-router";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { compose } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { displayedMRN } from "@tailormed/common-client/util/mrn";

import NoSearchResultIcon from "../assets/svg/NoSearchResultIcon";
import { ShorterTextWithTooltip } from "./ShorterText";
import {
  formatAssigneesToDisplay,
  formatPhoneNumberWhileTyping,
  formatSSN,
  decodeDate,
  formatPhoneNumber,
  decodeNumbersForSearch
} from "../utils/formaters";
import { selectSearchedPatients, selectIsFetching } from "../store/selector";
import ACTIONS from "../store/action";
import { ApiNames } from "../api/api";
import { Routes } from "../constant/routes";
import {
  DARK_LIVER,
  GRAY_100,
  GRAY_1500,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_50,
  GRAY_900,
  PRIMARY_600,
  WHITE
} from "../constant/colors";
import { fontWeights, sizes } from "../constant/styles";
import "./HeaderSearchBar.css";
import { useGetSetting } from "../hooks/getSetting";
import { customerTypes } from "../constant/customerType";

const searchTypes = Object.freeze({
  name_id: {
    key: "name_id",
    maxLength: null,
    maskInput: ""
  },
  dob: {
    key: "dob",
    maxLength: 10,
    maskInput: "99/99/9999"
  },
  phone_number: {
    key: "phone_number",
    maxLength: 14,
    maskInput: "(999) 999-9999"
  },
  social_security_number: {
    key: "social_security_number",
    maxLength: 11,
    maskInput: "999-99-9999"
  }
});

const { Text } = Typography;
const InputGroup = Input.Group;
const { Option } = Select;
const HEADER_WIDTH = 563;
const DROP_DOWN_LIST_WIDTH = 112;

const NAME_ID = searchTypes.name_id.key;
const DOB = searchTypes.dob.key;
const PHONE = searchTypes.phone_number.key;
const SSN = searchTypes.social_security_number.key;
const HOME_NUMBER = "home_number";
const ONE_YEAR = 31536000; // in seconds: 60*60*24*365

//const SearchIconBase64 = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(searchIconSvg)))}`;

const StyledInputGroup = styled(InputGroup)`
  border: 1px solid ${GRAY_300};
  border-radius: 5px;
  height: 54px;

  @media (max-width: 1600px) {
    width: 632px !important;
  }

  @media (min-width: 1600px) {
    width: 800px !important;
  }

  &:hover {
    border: 1px solid ${GRAY_400};
  }

  &:focus {
    border: 1px solid ${PRIMARY_600};
  }

  &:focus-within {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid ${PRIMARY_600};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 51px;
    background: ${GRAY_50};
    align-items: center;
    color: ${GRAY_900};
    border: 0px;
    border-right: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    font-size: ${sizes.medium};

    &:hover {
      background: ${GRAY_100};
    }
    &:focus-within {
      border-color: transparent;
      box-shadow: none;
      border-right: 1px solid #d0d5dd;
    }
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: ${GRAY_900};
    background: ${GRAY_100};
    box-shadow: 0px;
    border: 0px;
    outline-color: transparent;
    outline-offset: unset;
    outline-width: 0px;
  }

  .ant-select-arrow {
    color: ${GRAY_900};
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    height: 51px;
    padding-left: 12px;
    background: ${WHITE};
    border: 0px;
    border-left: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0px 4px 4px 0px;
    font-size: ${sizes.medium};
    color: ${GRAY_900};
    &:focus {
      outline-color: transparent;
      outline-offset: unset;
      outline-width: 0px;
    }
    &::placeholder {
      color: ${GRAY_400};
    }
  }
`;

const SearchResultsTitle = styled(Text)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.xsmall};
  line-height: 14px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${GRAY_400};
`;

const StyledInputMask = styled(InputMask)`
  &::placeholder {
    font-weight: ${fontWeights.regular};
    font-size: ${sizes.medium};
    color: ${GRAY_1500} !important;
  }
`;

const textOptionsListStyle = {
  fontSize: sizes.medium,
  fontWeight: fontWeights.regular,
  color: GRAY_900,
  lineHeight: "20px"
};

const HeaderSearchbarComponent = ({
  t,
  history,
  isFetching,
  searchedPatients,
  searchPatientsAct,
  restSearchResult
}) => {
  const [cookies, setCookie] = useCookies(["searchBy"]);
  const [searchText, setSearchText] = useState("");

  let [customerType] = useGetSetting(["fe-customer-type"]);
  customerType = customerType.toLowerCase();
  const searchByDefaultValue = cookies["searchBy"] ?? (customerType === customerTypes.RETAIL ? PHONE : NAME_ID);
  const [searchBy, setSearchBy] = useState(searchByDefaultValue);
  const [startSearching, setStartSearching] = useState(isFetching);
  const location = useLocation();

  const { patients = [], totalPatients } = searchedPatients || {};
  const NOT_PATIENT_PAGE = !(location.pathname.split("/")[1] !== "patient");
  const SEARCH_RESULTS_PAGE = location.pathname.includes(Routes.SEARCH_RESULTS);
  const enablePhoneMasking = searchText?.length < 4 && searchBy === PHONE;
  const searchParams = new URLSearchParams(location.search);
  const searchByParams = searchParams.get("searchBy");
  const searchTextParams = location?.state?.searchText;

  useEffect(() => {
    if (!SEARCH_RESULTS_PAGE && (patients.length || searchText)) {
      resetAutoComplete();
    }
    if (SEARCH_RESULTS_PAGE) {
      setSearchText(searchTextParams);
      setSearchBy(searchByParams);
    }
  }, [location]);

  useEffect(() => {
    setSearchBy(searchByDefaultValue);
  }, [customerType]);

  useEffect(() => {
    if ([DOB, PHONE, SSN].includes(searchBy)) {
      searchText?.length != searchTypes[searchBy]?.maxLength && restSearchResult();
    }
    if (searchText === "(" && searchBy === PHONE) setSearchText("");
  }, [searchText, searchBy]);

  // Note: we need to sync isFetching state to control the no content found section
  useEffect(() => {
    setStartSearching(isFetching);
  }, [isFetching]);

  const handleSearch = (text) => {
    let formatedQuery = text;
    let shouldFetchQuery = false;

    switch (searchBy) {
      case SSN:
      case PHONE: {
        formatedQuery?.length === searchTypes[searchBy]?.maxLength &&
          (searchPatientsAct(1, decodeNumbersForSearch(formatedQuery), searchBy), (shouldFetchQuery = true));

        break;
      }
      case DOB: {
        formatedQuery?.length === searchTypes[searchBy]?.maxLength &&
          (searchPatientsAct(1, formatedQuery, searchBy), (shouldFetchQuery = true));
        break;
      }
      case NAME_ID: {
        setStartSearching(true);
        debouncedSearch(formatedQuery, searchBy);
        shouldFetchQuery = true;
        break;
      }
    }

    setSearchText(formatedQuery);

    if (SEARCH_RESULTS_PAGE && shouldFetchQuery) {
      if (formatedQuery)
        history.replace(`${Routes.SEARCH_RESULTS}?searchBy=${searchBy}`, { searchText: formatedQuery });
      else history.replace(Routes.ROOT);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (patients.length > 0) {
        setSearchText(searchText);
        if (SEARCH_RESULTS_PAGE) {
          history.replace(`${Routes.SEARCH_RESULTS}?searchBy=${searchBy}`, { searchText });
        } else {
          history.push(`${Routes.SEARCH_RESULTS}?searchBy=${searchBy}`, { searchText });
        }
      }
    }
  };

  const debouncedSearch = useCallback(
    debounce((nextValue, searchBy) => searchPatientsAct(1, nextValue, searchBy), 500),
    []
  );

  const handleChangeSelect = (value) => {
    setSearchBy(value);
    setSearchText("");
    debouncedSearch("", searchBy);
    setCookie("searchBy", value, { path: "/", maxAge: ONE_YEAR });
  };

  const resetAutoComplete = () => {
    setSearchText("");
    restSearchResult();
  };

  const handlePaste = (e) => {
    let formatedQuery = e.clipboardData.getData("text");

    if (formatedQuery.length === 10 && !formatedQuery.includes("(") && searchBy === PHONE) {
      searchPatientsAct(1, decodeNumbersForSearch(formatedQuery), searchBy);
      setSearchText(formatPhoneNumberWhileTyping(formatedQuery));
    }
  };

  return (
    <StyledInputGroup
      compact
      style={{
        width: HEADER_WIDTH,
        display: "flex",
        marginRight: NOT_PATIENT_PAGE ? 42 : 0
      }}
    >
      <Select
        defaultValue={searchByDefaultValue}
        value={searchBy}
        onChange={handleChangeSelect}
        placement="bottomRight"
        dropdownMatchSelectWidth={DROP_DOWN_LIST_WIDTH}
        dropdownStyle={{ position: "fixed" }}
      >
        <Option value={NAME_ID} id="searchbar_name">
          {t("header_searchbar.option_type_list.name_id")}
        </Option>
        <Option value={DOB} id="searchbar_dob">
          {t("header_searchbar.option_type_list.dob")}
        </Option>
        <Option value={PHONE} id="searchbar_phone">
          {t("header_searchbar.option_type_list.phone")}
        </Option>
        <Option value={SSN} id="searchbar_ssn">
          {t("header_searchbar.option_type_list.ssn")}
        </Option>
      </Select>
      <AutoComplete
        id="main_header_search_patient"
        autoFocus={searchText && true}
        loading={isFetching}
        onPaste={handlePaste}
        notFoundContent={
          searchText &&
          !startSearching && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Text style={{ color: DARK_LIVER }}>
                {totalPatients === undefined
                  ? t(`header_searchbar.autocomplete_search_results_list.${searchBy}`)
                  : t(`header_searchbar.autocomplete_search_results_list.name_id`)}
              </Text>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <NoSearchResultIcon />
              </div>
            </Space>
          )
        }
        style={{ flexBasis: "100%" }}
        dropdownStyle={{
          borderRadius: "8px",
          boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
          background: WHITE,
          padding: "12px 0px",
          position: "fixed"
        }}
        allowClear={!isFetching}
        onSearch={handleSearch}
        value={searchText}
        onKeyDown={handleKeyDown}
        onClear={resetAutoComplete}
        dropdownRender={(menu) => (
          <>
            {menu}
            {patients.length > 3 && (
              <Row justify="center" style={{ borderTop: `1px solid ${GRAY_200}` }}>
                <Col>
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => history.push(`${Routes.SEARCH_RESULTS}?searchBy=${searchBy}`, { searchText })}
                  >
                    <Text
                      style={{
                        color: PRIMARY_600,
                        cursor: "pointer",
                        fontSize: sizes.medium,
                        fontWeight: fontWeights.semibold,
                        lineHeight: "20px",
                        fontStyle: "normal",
                        marginTop: "5px"
                      }}
                    >
                      {t("header_searchbar.show_all_results", {
                        totalPatients
                      })}
                    </Text>
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}
        options={
          patients.length === 0
            ? []
            : [
                {
                  label: (
                    <>
                      <Row>
                        {searchBy != NAME_ID && (
                          <Col span={6}>
                            <SearchResultsTitle>{t(`header_searchbar.results_title.${searchBy}`)}</SearchResultsTitle>
                          </Col>
                        )}
                        <Col span={12}>
                          <SearchResultsTitle>{t("header_searchbar.searched_patients")}</SearchResultsTitle>
                        </Col>
                        <Col span={6}>
                          <SearchResultsTitle>{t("header_searchbar.searched_patients_assignees")}</SearchResultsTitle>
                        </Col>
                      </Row>
                    </>
                  ),
                  options: patients.map((patient) => {
                    const assigneeNames = formatAssigneesToDisplay(patient.assignees);
                    const name = patient.full_name || patient.name;
                    const mrn = displayedMRN(patient.mrn, "");
                    const patientId = patient.patientId;
                    const journeyId = patient.journeyId;

                    return {
                      value: patientId.toString(),
                      label: (
                        <Row
                          onClick={() => {
                            restSearchResult();
                            history.push(`/patient/${patientId}/journey/${journeyId}`);
                          }}
                        >
                          {searchBy != NAME_ID && (
                            <Col span={6}>
                              {patient[searchBy] && (
                                <ShorterTextWithTooltip
                                  text={
                                    searchBy === PHONE
                                      ? formatPhoneNumberWhileTyping(patient[searchBy])
                                      : searchBy === DOB
                                      ? decodeDate(patient[searchBy])
                                      : searchBy === SSN
                                      ? formatSSN(patient[searchBy])
                                      : patient[searchBy]
                                  }
                                  id={`header_search_patient${
                                    searchBy === PHONE
                                      ? "_phone_number"
                                      : searchBy === DOB
                                      ? "_dob"
                                      : searchBy === SSN
                                      ? "_snn"
                                      : ""
                                  }`}
                                  searchInput={searchText}
                                  maxLength={15}
                                  textStyle={textOptionsListStyle}
                                />
                              )}
                              {patient[HOME_NUMBER] && searchBy === PHONE && (
                                <ShorterTextWithTooltip
                                  text={formatPhoneNumberWhileTyping(patient[HOME_NUMBER])}
                                  searchInput={searchText}
                                  maxLength={15}
                                  textStyle={textOptionsListStyle}
                                  id="header_search_patient_home_number"
                                />
                              )}
                            </Col>
                          )}
                          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
                            <ShorterTextWithTooltip
                              text={`${name} (${patientId})`}
                              searchInput={searchText}
                              maxLength={25}
                              textStyle={textOptionsListStyle}
                              id="header_search_patient_name"
                            />
                            <ShorterTextWithTooltip
                              text={
                                customerType === customerTypes.RETAIL
                                  ? searchBy === PHONE
                                    ? ""
                                    : t("columns.record_phone", { mrnRecord: formatPhoneNumber(mrn) })
                                  : t("columns.record_mrn", { mrnRecord: mrn })
                              }
                              id={`header_search_patient_${customerType === customerTypes.RETAIL ? "phone" : "mrn"}`}
                              searchInput={searchText}
                              maxLength={25}
                              textStyle={textOptionsListStyle}
                            />
                          </Col>
                          <Col span={6}>
                            <Tooltip title={<Text>{assigneeNames}</Text>} color={WHITE} style={{ cursor: "pointer" }}>
                              <Text ellipsis={true}>{assigneeNames}</Text>
                            </Tooltip>
                          </Col>
                        </Row>
                      ),
                      patient_id: patient.patientId,
                      pharmacy_patient: patient.patientId,
                      journey_id: journeyId
                    };
                  })
                }
              ]
        }
      >
        <StyledInputMask
          mask={!enablePhoneMasking && searchTypes[searchBy]?.maskInput}
          maskPlaceholder=""
          placeholder={t(`header_searchbar.placeholder.${searchBy}`)}
          alwaysShowMask={false}
        />
      </AutoComplete>
    </StyledInputGroup>
  );
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching([ApiNames.PhiSearchFreeText, ApiNames.SearchFreeText]),
  searchedPatients: selectSearchedPatients
});

const mapDispatchToProps = (dispatch) => ({
  selectSearchedPatient: (pharmacyPatient, patientId) => dispatch(ACTIONS.setPatientAction(pharmacyPatient, patientId)),
  searchPatientsAct: (page, text, searchBy) => dispatch(ACTIONS.searchPatientsAction(page, text, searchBy)),
  restSearchResult: () => dispatch(ACTIONS.clearSearchedPatients())
});

const HeaderSearchBar = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderSearchbarComponent);

export { HeaderSearchBar };
