import { UNSPECIFIED_MEMBER_ID, UNSPECIFIED_PAYER_NAME } from "../../../../../../constant/coverages";

const cleanUnspecifiedValue = (str) => {
  if (!str || str?.startsWith(UNSPECIFIED_PAYER_NAME) || str?.startsWith(UNSPECIFIED_MEMBER_ID)) {
    return null;
  } else {
    return str;
  }
};

export { cleanUnspecifiedValue };
